import { mainApi, TAGS } from '../../services/mainApi';

export const inboxApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    conversations: build.query({
      query: ({params}) => ({url: `conversations`, params}),
      providesTags: [TAGS.CONVERSATIONS]
    }),
    createConversation: build.mutation({
      query: (body) => {
        return {
          url: 'conversations',
          method: 'POST',
          body: body
        }
      },
      invalidatesTags: [TAGS.CONVERSATIONS],
    }),
    conversation: build.query({
      query: (id) => ({url: `conversations/${id}`}),
      providesTags: [TAGS.CONVERSATION]
    }),
    deleteConversation: build.mutation({
      query: (id) => ({
        url: `conversations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAGS.CONVERSATIONS, TAGS.CONVERSATION]
    }),
    sendMessage: build.mutation({
      query: ({id, body}) => {
        return {
          url: `conversations/send-message/${id}`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: [TAGS.CONVERSATIONS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useConversationsQuery,
  useCreateConversationMutation,
  useConversationQuery,
  useDeleteConversationMutation,
  useSendMessageMutation,
} = inboxApi;
