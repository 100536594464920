import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MultiSectionDigitalClock, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, useMediaQuery } from '@mui/material';
import { theme } from '../services/Theme';
import ModalTitle from './UI/DialogTitle';

const CustomTimePicker = ({sx, readOnly = false, ...props}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpen = () => {
    if (!readOnly && !isMobile) {
      setIsOpen(true);
    }
    if (isMobile && !readOnly) {
      setIsOpenModal(true)
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        open={isOpen}
        onClose={() => setIsOpen(false)}
        slotProps={{
          inputAdornment: {
            sx: {display: 'none'}
          },
          textField: {
            variant: 'outlined',
            readOnly: false,
            onClick: handleOpen,
            InputProps: {
              startAdornment: false,
            },
            sx: {
              width: '100%',
              '&.Mui-error': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'red',
                  borderWidth: '1px !important'
                }
              },
              '& .MuiInputBase-input.MuiOutlinedInput-input': {
                color: '#000',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: "rgba(14, 29, 49, 0.5)",
                borderWidth: '1px !important'
              },
              '& .MuiInputBase-root.MuiOutlinedInput-root:hover, &.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused': {
                boxShadow: 'none',
                borderColor: '#0E1D31',
                '&.Mui-error,': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '1px !important'
                  }
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0E1D31',
                  borderWidth: '1px !important',
                }
              },
              ...props.sx
            }
          },
          popper: {
            sx: {
              '& .MuiMultiSectionDigitalClock-root': {
                '& .MuiMultiSectionDigitalClock-root': {
                  '& > .MuiButtonBase-root': {
                    '&.Mui-selected': {
                      color: "white !important",
                      backgroundColor: "#0E1D31 !important",
                    }
                  }
                }
              },
              "& .MuiPickersDay-root": {
                color: "#373632 !important",
                "&.Mui-selected": {
                  color: "white !important",
                  backgroundColor: "#0E1D31 !important",
                },
                "&.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.38) !important",
                },
              },
              "& .MuiPickersYear-root .MuiPickersYear-yearButton": {
                color: "#373632 !important",
                "&.Mui-selected": {
                  color: "white !important",
                  backgroundColor: "#0E1D31 !important",
                },
              },
              "& .PrivatePickersMonth-root": {
                color: "#373632 !important",
                "&.Mui-selected": {
                  color: "white !important",
                  backgroundColor: "#0E1D31 !important",
                },
              },
              '.MuiPickersCalendarHeader-switchViewIcon': {
                color: "#0E1D31",
              },
              '.MuiPickersArrowSwitcher-root': {
                '& .MuiSvgIcon-root': {
                  color: "#0E1D31",
                }
              }
            },
          },
        }}
        ampm={false}
        {...props}
      />
      <Dialog
        fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: {xs: '100%', sm: 600},
            width: '100%',
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "#b0b0b04d",
          },
        }}
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      >
        <ModalTitle>Select time</ModalTitle>
        <MultiSectionDigitalClock
          ampm={false}
          {...props}
          sx={{
            p: 3,
            maxHeight: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            '& .MuiMultiSectionDigitalClock-root': {
              maxHeight: '100%',
              width: '30%',
              overflowY: 'visible',
              '& .MuiMenuItem-root': {
                '&.Mui-selected': {
                  color: '#fff',
                }
              }
            }
          }}
        />
        <DialogActions>
          <Button
            onClick={() => setIsOpenModal(false)}
            variant={'contained'}
            sx={{color: '#fff', minWidth: 100, fontSize: 16}}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
