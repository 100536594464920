import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  Icon,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import PageWrapper from "../../../components/PageWrapper";
import {
  useUpdateInvitationMutation,
  useDeleteInvitationMutation,
} from "../api";
import { useHistory, useParams } from "react-router-dom";
import GoogleAutocomplete from "../../../components/GoogleAutocomplete";
import Label from "../../../components/UI/Label";
import DatePicker from "../../../components/UI/DatePicker";
import dayjs from "dayjs";
import AccommodationTypeSelect from "../../../components/AccommodationTypeSelect";
import TravelPackage from "../../../components/TravelPackage";
import TravelPackagesModal from "../TravelPackagesModal";
import Input from "../../../components/UI/Input";
import Select from "../../../components/UI/Select";
import CustomTooltip from "../../../components/UI/CustomTooltip";
import CustomSwitch from "../../../components/UI/CustomSwitch";
import { Button } from "../../../components/UI/Button";
import DeleteConfirmModal from "../DeleteConfirmModal";
import previewIconFile from "../../../assets/create_inv/preview.svg";
import PreviewInvitationModal from "../PreviewInvitationModal";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import NoItemsBox from "../../../components/NoItemsBox";

const EditTripInvitation = ({ invitation, error, isLoading }) => {
  const history = useHistory();
  const { id } = useParams();
  /*  const [addHostImages] = useAddHostImagesMutation();
  const [addCoHostImages] = useAddCoHostImagesMutation();
  const [deleteImage] = useDeleteImageMutation();*/
  const [
    updateInvitation,
    { isSuccess: isUpdateSuccess, error: updatingError },
  ] = useUpdateInvitationMutation();
  const [
    deleteInvitation,
    { isSuccess: isDeleteSuccess, error: deletingError },
  ] = useDeleteInvitationMutation();
  const [form, setForm] = useState(null);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);

  useEffect(() => {
    if (invitation && !form) {
      setForm(invitation);
    } else if (invitation && form) {
      setForm((state) => ({
        ...state,
        coHostsGallery: invitation?.coHostsGallery,
        hostGallery: invitation?.hostGallery,
      }));
    }
  }, [invitation]); //eslint-disable-line

  useEffect(() => {
    if (isUpdateSuccess || isDeleteSuccess) {
      history.push("/my-events");
    }
  }, [isUpdateSuccess, isDeleteSuccess, history]);

  const handleDateChange = (e) => (key) => {
    setForm((state) => ({ ...state, [key]: dayjs(e?.$d || null).format() }));
  };

  const handleChange = ({ target: { name, value, type } }) => {
    setErrors((state) => ({ ...state, [name]: null }));
    if (type === "number") {
      setForm((state) => ({
        ...state,
        [name]: value < 0 ? Math.abs(value) : value,
      }));
      if (name === "numberOfCoHosts" && +value > 0 && !form?.genderOfCoHosts) {
        setErrors((state) => ({
          ...state,
          genderOfCoHosts: "Please add coHosts gender",
        }));
      } else {
        setErrors((state) => ({ ...state, genderOfCoHosts: null }));
      }
      return;
    }
    setForm((state) => ({ ...state, [name]: value }));
  };

  const handleLocation = (key) => (value) => {
    setErrors({});
    setForm((state) => ({ ...state, [key]: value }));
    if (!value?.city || !value?.country) {
      setErrors((state) => ({
        ...state,
        [key]: "Please provide city and country",
      }));
    }
  };

  const handleTravelPackage = (value) => {
    setForm((state) => ({ ...state, ...value }));
  };

  const handleSwitch = ({ target: { name, checked } }) => {
    setForm((state) => ({
      ...state,
      requiredFields: { ...state?.requiredFields, [name]: checked },
    }));
  };

  /*const handleImage = (name) => (e) => {
    if (!e?.target?.files[0]) {
      return;
    }
    if (e?.target?.files[0].size > 4000000) {
      setErrors(state => ({state, [name]: 'Image size should be less than 4mb.'}));
      return;
    }
    setErrors(state => ({state, [name]: null}));
    if (name === 'hostGallery') {
      addHostImages({id: invitation?.id, images: [e?.target?.files[0]]});
    }
    if (name === 'coHostsGallery') {
      addCoHostImages({id: invitation?.id, images: [e?.target?.files[0]]});
    }
    e.target.value = '';
  };

  const handleDeleteImage = () => (item) => {
    deleteImage({id: invitation?.id, body: {filename: item}});
  };*/

  const handleUpdate = () => {
    const {
      name,
      acceptedApplications,
      alias,
      coHostsGallery,
      deleted,
      hostGallery,
      status,
      totalApplications,
      updatedAt,
      ...rest
    } = form;
    updateInvitation({
      id: rest?.id,
      body: {
        ...rest,
        host: rest?.host?.id,
        travelPackage: rest?.travelPackage?.id,
      },
    }).then((res) => {
      if (res?.data) {
        enqueueSnackbar(`${res?.data?.name} was updated`, {
          variant: "info",
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: "not_found",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          ),
        });
      }
    });
  };

  const handleDelete = () => {
    const name = invitation?.name;
    deleteInvitation({ id }).then((res) => {
      if (res.data) {
        enqueueSnackbar(`${name} was deleted`, {
          variant: "error",
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: "not_found",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          ),
        });
      }
    });
  };

  return (
    <PageWrapper>
      {error && !isLoading && <NoItemsBox />}
      {invitation?.deleted && !isLoading && (
        <Typography>Event was deleted</Typography>
      )}
      {!error && !isLoading && !invitation?.deleted && (
        <>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Typography sx={{ fontWeight: 600, fontSize: 20, mb: 2 }}>
                Edit Invitation
              </Typography>
              <Label>Starting Location</Label>
              <GoogleAutocomplete
                value={form?.locationFrom || null}
                handleChange={handleLocation("locationFrom")}
                error={errors.locationFrom}
              />
              <Label>Ending Location</Label>
              <GoogleAutocomplete
                value={form?.locationTo || null}
                handleChange={handleLocation("locationTo")}
                error={errors.locationTo}
              />
              <Label>From</Label>
              <DatePicker
                name={"startDate"}
                value={form?.startDate || null}
                onChange={(e) => handleDateChange(e)("startDate")}
                minDate={dayjs().add(1, "day")}
                maxDate={
                  form?.endDate
                    ? dayjs(form?.endDate).add(-1, "day")
                    : dayjs().add(1, "year")
                }
                sx={{ mb: 4 }}
              />
              <Label>To</Label>
              <DatePicker
                name={"endDate"}
                value={form?.endDate || null}
                onChange={(e) => handleDateChange(e)("endDate")}
                minDate={
                  form?.startDate
                    ? dayjs(form?.startDate).add(1, "day")
                    : dayjs().add(2, "day")
                }
                sx={{ mb: 4 }}
              />
              <Label>Accommodation</Label>
              <AccommodationTypeSelect
                name="typeOfAccommodation"
                value={form?.typeOfAccommodation || ""}
                onChange={handleChange}
                sx={{ mb: 4 }}
              />
              <Label>Trip information</Label>
              <Input
                fullWidth
                name="additionalInfo"
                placeholder="Fill in"
                multiline
                maxRows={5}
                value={form?.additionalInfo}
                onChange={handleChange}
                sx={{ mb: 4 }}
              />
              <Label>
                Associated Event •<em>Optional</em>
              </Label>
              <Input
                fullWidth
                name="associatedEvent"
                placeholder="Fill in"
                value={form?.associatedEvent || ""}
                onChange={handleChange}
                sx={{ mb: 4 }}
              />
              {invitation?.type === "coHost" && (
                <>
                  <Label>
                    Link of Accommodation <em>• Optional</em>
                  </Label>
                  <Input
                    fullWidth
                    name={"linkOfAccommodation"}
                    value={form?.linkOfAccommodation || ""}
                    onChange={handleChange}
                    sx={{ mb: 4 }}
                  />
                  <Label>Accommodates up to:</Label>
                  <Input
                    fullWidth
                    name={"totalRooms"}
                    type={"number"}
                    value={form?.totalRooms || ""}
                    inputProps={{ inputMode: "numeric", min: 1 }}
                    onChange={handleChange}
                    sx={{ mb: 4 }}
                  />
                  <Label>People available</Label>
                  <Input
                    fullWidth
                    name={"roomsTaken"}
                    type={"number"}
                    inputProps={{
                      inputMode: "numeric",
                      min: 0,
                      max: form?.totalRooms,
                    }}
                    value={form?.roomsTaken || ""}
                    onChange={handleChange}
                    disabled={!form?.totalRooms}
                    sx={{ mb: 4 }}
                  />
                  <Label>Type of Trip</Label>
                  <Select
                    name={"tripType"}
                    value={form?.tripType || ""}
                    onChange={handleChange}
                    sx={{ mb: 4 }}
                  >
                    <MenuItem value={"singles"}>Singles</MenuItem>
                    <MenuItem value={"couples"}>Couples</MenuItem>
                    <MenuItem value={"family"}>Family</MenuItem>
                  </Select>
                  <Label>Accommodation Cost</Label>
                  <Input
                    name={"totalCost"}
                    value={form?.totalCost || ""}
                    type={"number"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    sx={{ mb: 4 }}
                  />
                </>
              )}
              {invitation?.type === "guest" && (
                <>
                  <TravelPackage
                    title={form?.travelPackage?.title}
                    color={form?.travelPackage?.color}
                    hintItems={form?.travelPackage?.hintItems}
                    checkboxItems={form?.travelPackage?.checkboxItems}
                  />
                  <Typography
                    sx={{
                      color: "#0E1D31",
                      fontSize: 14,
                      mt: 1,
                      mb: 3,
                      textDecoration: "underline",
                    }}
                    onClick={() => setOpen(true)}
                  >
                    Select another Travel Category
                  </Typography>
                  <TravelPackagesModal
                    open={open}
                    onClose={() => setOpen(false)}
                    onChange={handleTravelPackage}
                    travelPackage={form?.travelPackage}
                  />
                </>
              )}
              <Label>
                {invitation?.type === "coHost"
                  ? "Number of CoHosts"
                  : "Expected Number of CoHosts"}
              </Label>
              <Input
                sx={{ mb: 4 }}
                name="numberOfCoHosts"
                type="number"
                value={form?.numberOfCoHosts || ""}
                onChange={handleChange}
                inputProps={{
                  inputMode: "numeric",
                  min: invitation?.type === "coHost" ? 1 : 0,
                }}
                fullWidth
              />
              <Label>Gender of CoHosts</Label>
              <Select
                name="genderOfCoHosts"
                value={form?.genderOfCoHosts || ""}
                onChange={handleChange}
                sx={{ mb: errors?.genderOfCoHosts ? 1 : 4 }}
                disabled={form?.numberOfCoHosts === "0"}
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem
                  value={"both"}
                  disabled={form?.numberOfCoHosts === "1"}
                >
                  Both
                </MenuItem>
              </Select>
              {errors?.genderOfCoHosts && (
                <Typography sx={{ fontSize: 14, color: "red", mb: 4 }}>
                  {errors?.genderOfCoHosts}
                </Typography>
              )}
              {invitation?.type === "guest" && (
                <>
                  <Label>Total Guest Number</Label>
                  <Input
                    name="numberOfGuest"
                    placeholder="Enter the expected number of guests"
                    type="number"
                    value={form?.numberOfGuest || ""}
                    onChange={handleChange}
                    sx={{ mb: 4 }}
                    inputProps={{ inputMode: "numeric", min: 1 }}
                    helperText={errors?.numberOfGuest}
                    error={!!errors?.numberOfGuest}
                    fullWidth
                  />
                  <Label>Gender Of Guests</Label>
                  <Select
                    name="genderOfGuests"
                    value={form?.genderOfGuests || ""}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    sx={{ mb: 4 }}
                    disabled={form?.numberOfGuest === "0"}
                  >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                    <MenuItem
                      value={"both"}
                      disabled={form?.numberOfGuest === "1"}
                    >
                      Both
                    </MenuItem>
                  </Select>
                </>
              )}
              {invitation?.type === "guest" && (
                <>
                  <Label>
                    Type of Bedroom
                    <CustomTooltip
                      tooltipText={
                        "Double room is shared with a person of the same gender"
                      }
                    />
                  </Label>
                  <Select
                    name="bedroomType"
                    value={form?.bedroomType || ""}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    sx={{ mb: 4 }}
                  >
                    <MenuItem value={"shared"}>Shared</MenuItem>
                    <MenuItem value={"private"}>Private</MenuItem>
                    <MenuItem value={"both"}>Both</MenuItem>
                  </Select>
                </>
              )}
              <Label>Deadline For Application</Label>
              <DatePicker
                sx={{ mb: 3 }}
                value={form?.deadlineOfApplications || null}
                maxDate={dayjs(form?.startDate).add(-1, "day")}
                minDate={dayjs().add(1, "day")}
                onChange={(e) => handleDateChange(e)("deadlineOfApplications")}
              />
              {invitation?.type === "guest" && (
                <>
                  <FormControlLabel
                    sx={{ mb: 3 }}
                    control={
                      <CustomSwitch
                        name={"pictures"}
                        onChange={handleSwitch}
                        checked={form?.requiredFields?.pictures || false}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          display: "flex",
                          color: "#373632",
                          fontSize: 13,
                          fontWeight: 400,
                        }}
                      >
                        Guest must provide
                        <Typography
                          sx={{ fontSize: 13, fontWeight: 700, ml: "3px" }}
                          component={"span"}
                        >
                          5 Pictures of themselves
                        </Typography>
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    sx={{ mb: 3 }}
                    control={
                      <CustomSwitch
                        name={"video"}
                        checked={form?.requiredFields?.video || false}
                        onChange={handleSwitch}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          display: "flex",
                          color: "#373632",
                          fontSize: 13,
                          fontWeight: 400,
                        }}
                      >
                        Guest must submit
                        <Typography
                          sx={{ fontSize: 13, fontWeight: 700, ml: "3px" }}
                          component={"span"}
                        >
                          a Short Video introducing themselves
                        </Typography>
                      </Typography>
                    }
                  />
                </>
              )}
              {/* <Label>Tagline •<em>Optional</em></Label>
              <Input
                fullWidth
                name="tagline"
                placeholder="Fill in"
                value={form?.tagline || ''}
                onChange={handleChange}
                sx={{mb: 4}}
              />*/}
              {/*<Label>Itinerary •<em>Optional</em></Label>
              <Input
                fullWidth
                name="itinerary"
                placeholder="Fill in"
                value={form?.itinerary || ''}
                onChange={handleChange}
                sx={{mb: 4}}
              />*/}
              {/*<Label>Activities •<em>Optional</em></Label>
              <Input
                fullWidth
                name="activities"
                placeholder="Fill in"
                multiline
                maxRows={5}
                value={form?.activities || ''}
                onChange={handleChange}
                sx={{mb: 4}}
              />*/}
              {/*{invitation?.type === 'guest' && (
                <>
                  <Label>Pictures of Host •<em>Optional</em></Label>
                  <ImageRow
                    images={form?.hostGallery}
                    error={error?.hostGallery}
                    onChange={handleImage('hostGallery')}
                    onDelete={handleDeleteImage('hostGallery')}
                  />
                  <Label>Pictures of CoHost •<em>Optional</em></Label>
                  <ImageRow
                    images={form?.coHostsGallery}
                    error={error?.coHostsGallery}
                    onChange={handleImage('coHostsGallery')}
                    onDelete={handleDeleteImage('coHostsGallery')}
                  />
                </>
              )}*/}
              <Button
                sx={{ maxWidth: { xs: 300, md: 400 } }}
                variant={"outlined"}
                color={"error"}
                fullWidth
                onClick={() => setOpenDeleteModal(true)}
              >
                Delete Invitation
              </Button>
            </Box>
            <Box
              sx={{
                position: { xs: "static", sm: "fixed" },
                pb: { xs: 0, sm: 2 },
                pr: { xs: 0, sm: 2.5 },
                top: { xs: 50, sm: 95, md: 30 },
                right: 0,
                width: { xs: "100%", sm: "45%", md: "25%" },
                height: {
                  xs: "auto",
                  sm: "calc(100vh - 232px)",
                  md: "calc(100vh - 78px)",
                },
                display: "flex",
                flexDirection: "column",
                justifyContent: { xs: "center", sm: "space-between" },
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "24px",
                    color: "#373632",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  {form?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "24px",
                    color: "#373632",
                    mb: 1.2,
                  }}
                >
                  {dayjs(form?.startDate).format("DD MMM YYYY")} -{" "}
                  {dayjs(form?.endDate).format("DD MMM YYYY")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    textTransform: "uppercase",
                    mb: 3,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  STATUS:{" "}
                  <Typography
                    component="span"
                    sx={{ fontWeight: 600, ml: 0.25 }}
                  >
                    {" "}
                    {invitation.status}
                  </Typography>
                </Typography>
                {invitation?.imgUrl && (
                  <Box
                    sx={{
                      width: "100%",
                      "& > img": { width: "100%", height: "auto" },
                    }}
                  >
                    <img
                      src={invitation?.imgUrl}
                      alt={"invitation-wallpaper"}
                    />
                  </Box>
                )}
                {/*            {invitation?.acceptedApplications !== 0 && (
                <Typography sx={{fontSize: 15, color: "#0E1D31", fontWeight: 600, mb: 2,}}>
                  You have Guests waiting for your acceptance
                </Typography>
              )}*/}
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setOpenPreview(true)}
                  sx={{ py: 0.5 }}
                  startIcon={
                    <Icon sx={{ width: "auto", height: "auto" }}>
                      <img src={previewIconFile} alt={"preview"} />
                    </Icon>
                  }
                >
                  PREVIEW INVITATION
                </Button>
              </Box>
              <Box>
                {(updatingError || deletingError) && (
                  <Typography color={"error"} sx={{ mt: 2 }}>
                    {updatingError?.data?.error || deletingError?.data?.error}
                  </Typography>
                )}
                <Button
                  variant={"contained"}
                  color={"primary"}
                  sx={{ maxWidth: { xs: 300, md: 400 }, mt: 2 }}
                  fullWidth
                  disabled={
                    JSON.stringify(invitation) === JSON.stringify(form) ||
                    (form?.numberOfCoHosts !== "0" && !form?.genderOfCoHosts)
                  }
                  onClick={handleUpdate}
                >
                  Update invitation
                </Button>
              </Box>
            </Box>
          </Stack>
          <DeleteConfirmModal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onConfirm={handleDelete}
          />
          <PreviewInvitationModal
            open={openPreview}
            onClose={() => setOpenPreview(false)}
            invitation={form}
          />
        </>
      )}
    </PageWrapper>
  );
};

export default EditTripInvitation;
