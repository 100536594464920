import ModalTitle from '../../components/UI/DialogTitle';
import { Dialog, DialogContent, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import { useTravelCategoriesQuery } from '../../services/mainApi';
import TravelPackage from '../../components/TravelPackage';

const TravelPackagesModal = ({open = false, onClose, onChange, travelPackage}) => {
  const {data, isLoading} = useTravelCategoriesQuery({params: {active: true}}, {skip: !open});

  const handleChange = (e, value) => {
    onChange({travelPackage: value});
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 700,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        <Typography sx={{fontSize: 15, fontWeight: 600}}>SELECT TRAVEL CATEGORY</Typography>
      </ModalTitle>
      <DialogContent sx={{p: 3, mt: 3}}>
        {data?.data?.length > 0 && !isLoading && (
          <ToggleButtonGroup
            value={travelPackage}
            onChange={handleChange}
            sx={{width: "100%", display: "flex", flexDirection: "column"}}
            exclusive
          >
            {data?.data.map(item => (
              <ToggleButton
                value={item}
                key={item.id}
                sx={{
                  p: 0,
                  width: "100%",
                  marginBottom: "30px",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  backgroundColor: "transparent",
                  border: 'none',
                  '&:hover': {
                    backgroundColor: "transparent",
                  }
                }}
              >
                <TravelPackage
                  title={item?.title}
                  color={item?.color}
                  hintItems={item?.hintItems}
                  checkboxItems={item?.checkboxItems}
                  selected={item?.id === travelPackage?.id}
                  selectable
                />
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TravelPackagesModal;
