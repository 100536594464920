import React from "react";

const MyPartiesIcon = ({
  strokeColor = "#343A40",
  width = 22,
  height = 22,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 16V3.58885C12.6667 2.73163 12.6667 2.30302 12.8473 2.04489C13.0049 1.81949 13.2485 1.66897 13.5206 1.62877C13.8322 1.58273 14.2156 1.77441 14.9823 2.15777L18.6667 4M12.6667 16C12.6667 17.6569 11.3236 19 9.66675 19C8.00989 19 6.66675 17.6569 6.66675 16C6.66675 14.3431 8.00989 13 9.66675 13C11.3236 13 12.6667 14.3431 12.6667 16ZM4.66675 8V2M1.66675 5H7.66675"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MyPartiesIcon;
