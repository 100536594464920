import { Icon } from '@mui/material';
import previewIconFile from '../../assets/create_inv/preview.svg';
import { Button } from '../../components/UI/Button';
import React from 'react';

const PreviewButton = ({icon = previewIconFile, text = 'PREVIEW INVITATION', ...props}) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      sx={{
        py: 1,
        '&.MuiButton-outlinedPrimary': {
          borderRadius: '12px',
        },
      }}
      startIcon={
        <Icon sx={{width: 'auto', height: 'auto'}}>
          <img src={icon} alt={'preview'} />
        </Icon>
      }
      {...props}
    >
      {text}
    </Button>
  );
};

export default PreviewButton;
