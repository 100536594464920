import { mainApi, TAGS } from "../../services/mainApi";

export const AuthApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    checkValue: build.mutation({
      query: ({ key, body }) => {
        return {
          url: `/auth/check-value/${key}`,
          method: "POST",
          body,
        };
      },
    }),
    applyWaitingList: build.mutation({
      query: (body) => {
        return {
          url: `/waiting-list`,
          method: "POST",
          body,
        };
      },
    }),
    checkWaitingToken: build.mutation({
      query: (body) => ({
        url: "/waiting-list/check-waiting-token",
        method: "POST",
        body,
      }),
    }),
    register: build.mutation({
      query: (body) => ({
        url: "/auth/register",
        method: "POST",
        body,
      }),
    }),
    authByGoogle: build.mutation({
      query: (body) => ({
        url: "/auth/auth-by-google",
        method: "POST",
        body,
      }),
    }),
    login: build.mutation({
      query: (body) => ({
        url: "/auth/login",
        method: "POST",
        body,
      }),
    }),
    logout: build.mutation({
      query: (body) => ({
        url: "/auth/logout",
        method: "POST",
        body,
      }),
    }),
    getUser: build.query({
      query: () => "auth/me",
      providesTags: [TAGS.USER],
    }),
    forgotPassword: build.mutation({
      query: (body) => {
        return {
          url: `/auth/forgot-password/`,
          method: "POST",
          body,
        };
      },
    }),
    resetPassword: build.mutation({
      query: (body) => {
        return {
          url: `/auth/reset-password/`,
          method: "POST",
          body,
        };
      },
    }),
    validateResetToken: build.mutation({
      query: (body) => ({
        url: "/auth/validate-reset-token",
        method: "POST",
        body,
      }),
    }),
    changeEmailSubscription: build.mutation({
      query: (body) => ({
        url: "/auth/change-email-subscription",
        method: "PATCH",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCheckValueMutation,
  useApplyWaitingListMutation,
  useCheckWaitingTokenMutation,
  useRegisterMutation,
  useAuthByGoogleMutation,
  useLoginMutation,
  useLogoutMutation,
  useLazyGetUserQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  // useValidateResetTokenMutation,
  useChangeEmailSubscriptionMutation,
} = AuthApi;
