import { Link, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import NotificationDescription from "../../components/NotificationDescription";

const NotificationInner = ({
  title,
  description,
  invitationId,
  applicationId,
  userName,
  invitationName,
  activeConversation,
}) => {
  switch (title) {
    case "New Application":
      return (
        <Link
          underline={"none"}
          sx={{ color: "#373632" }}
          component={RouterLink}
          to={`applications/${invitationId}/${applicationId}`}
        >
          {!userName || !invitationName ? (
            description
          ) : (
            <NotificationDescription
              title={title}
              invitationName={invitationName}
              userName={userName}
              description={description}
            />
          )}
        </Link>
      );
    case "Application status changed":
      return (
        <Link
          underline={"none"}
          sx={{ color: "#373632" }}
          component={RouterLink}
          to={`invitation/${invitationId}`}
        >
          {!userName || !invitationName ? (
            description
          ) : (
            <NotificationDescription
              title={title}
              invitationName={invitationName}
              userName={userName}
              description={description}
            />
          )}
        </Link>
      );
    case "New Event Chat is Now Active!":
      return (
        <Link
          underline={"none"}
          sx={{ color: "#373632" }}
          component={RouterLink}
          to={{
            pathname: `/inbox/${activeConversation}`,
            state: { activeConversation: activeConversation },
          }}
        >
          {!userName || !invitationName ? (
            description
          ) : (
            <NotificationDescription
              title={title}
              invitationName={invitationName}
              userName={userName}
              description={description}
            />
          )}
        </Link>
      );
    default:
      return <Typography>{description}</Typography>;
  }
};

export default NotificationInner;
