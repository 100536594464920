import PageWrapper from '../../components/PageWrapper';
import { useParams } from 'react-router-dom';
import { Details } from './InvitationApplications';
import useFullPageHeight from '../../util/useFullPageHeight';

const SingleInvitationApplication = () => {
  const {applicationId} = useParams();
  const height = useFullPageHeight();

  return (
    <PageWrapper sx={{minHeight: height}}>
      <Details selectedAppId={applicationId} />
    </PageWrapper>
  );
};

export default SingleInvitationApplication;
