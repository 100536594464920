import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import Footer from "../components/Footer";
//COMPONENTS
import Navbar from "../components/Navbar";
// MUI Stuff

//Redux Stuff
import { connect } from "react-redux";

const styles = (theme) => ({
  ...theme.spreadIt,
  notificationsWrapper: {
    minHeight: "100vh",
  },

  notificationsInner: {
    maxWidth: "600px",
    margin: "0px auto",
    width: "100%",
    "& h1": {
      fontWeight: "500 !important",
    },
  },
  notificationsList: {
    marginTop: "15px",
  },
  notificationCard: {
    backgroundColor: "#F9F5EF !important",
    boxShadow: "unset !important",
    borderRadius: "0px !important",
    "& .MuiCardHeader-root": {
      padding: "20px 20px 0px 20px !important",
    },
    "& .MuiCardHeader-title": {
      fontSize: "16px",
      fontFamily: "Roboto !important",
      fontWeight: "bold",
    },
    "& .MuiCardContent-root ": {
      padding: "10px 20px 20px 20px",
    },
    "& .MuiCardContent-root p": {
      fontSize: "16px",
      fontFamily: "Roboto !important",
      fontWeight: "normal",
    },
    "& svg": {
      fill: "#000",
    },
  },
  notificationAction: {
    cursor: "pointer",
    "& .MuiPopover-paper": {
      boxShadow: "0px 3px 6px #00000029 !important",
      padding: "10px 15px",
      fontSize: "12px",
      fontWeight: "600",
    },
  },
});

class settingsNotifications extends Component {
  state = {};

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Navbar />
        <div className={"page-container " + classes.notificationsWrapper}>
          <div className={classes.notificationsInner}>
            <div className="page-header">
              <h1 style={{ display: "none" }}>Notifications</h1>
            </div>
            <div className={"page-results " + classes.notificationsList}></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

// export default userManagement;
settingsNotifications.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapActionsToProps = {};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
  authenticated: state.user.authenticated,
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(settingsNotifications));
