import { Box, Grid, Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginIcon from "@mui/icons-material/Login";
import React from "react";
import ProfileIcon from "./icons/ProfileIcon";
import NavigationButtons from "./NavigationButtons";
import { theme } from "../services/Theme";

const MobileNav = () => {
  const { user } = useSelector((store) => store);

  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.grey[100],
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 99,
      }}
    >
      <Grid
        container
        spacing={user?.authenticated ? 1 : 0}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: 82 }}
      >
        {user?.authenticated && <NavigationButtons />}
        {!user?.authenticated && (
          <>
            <Grid item xs={3}>
              <Link component={NavLink} to={"/login"} sx={LinkSx}>
                <ProfileIcon />
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Link component={NavLink} to={"/join"} sx={LinkSx}>
                <LoginIcon />
              </Link>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default MobileNav;

const LinkSx = (theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "&.active": {
    "& > .MuiSvgIcon-root, & > .MuiBadge-root > .MuiSvgIcon-root": {
      color: theme.palette.secondary.main,
    },
    "& > .MuiTypography-root": {
      color: theme.palette.secondary.main,
    },
  },
  "& > .MuiSvgIcon-root, & > .MuiBadge-root > .MuiSvgIcon-root": {
    fontSize: 24,
    color: "#717171",
    height: 32,
    // [theme.breakpoints.down('xsm')]: {
    //   fontSize: 25,
    // }
    "& > path": {
      strokeWidth: 10,
    },
  },
  "& > .MuiTypography-root": {
    fontSize: 14,
    lineHeight: "14px",
    fontWeight: 400,
    color: "#717171",
    textTransform: "capitalize",
    [theme.breakpoints.down("xsm")]: {
      fontSize: 12,
    },
  },
});
