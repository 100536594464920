import ModalTitle from '../../components/UI/DialogTitle';
import { CircularProgress, Dialog, DialogContent, Link, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import Input from '../../components/UI/Input';
import Label from '../../components/UI/Label';
import { Button } from '../../components/UI/Button';
import { useForgotPasswordMutation } from './api';
import useFullPageHeight from '../../util/useFullPageHeight';

const ForgotPasswordDialog = ({open, onClose}) => {
  const [errors, setErrors] = useState({});
  const [forgotPassword, {isSuccess, isLoading, error}] = useForgotPasswordMutation();
  const [email, setEmail] = useState('');
  const height = useFullPageHeight();

  const handleChange = ({target: {value}}) => {
    setEmail(value);
  }

  const handleSubmit = () => {
    //eslint-disable-next-line
    if (email !== "" && email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      forgotPassword({email});
      return;
    }
    if (email === "") {
      setErrors({email: "Email cannot be empty"});
      //eslint-disable-next-line
    } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
      setErrors({email: "Enter a valid email address"});
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: {xs: '100%', sm: 600},
          width: '100%',
          height: {xs: height, sm: 'auto'},
          maxHeight: {xs: '100%', sm: 'auto'},
          m: {xs: 0, sm: 1}
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        <Typography sx={{fontSize: 15, fontWeight: 600}}>RECOVER YOUR PASSWORD</Typography>
      </ModalTitle>
      <DialogContent sx={{px: 2, mt: 3}}>
        <Box sx={{maxWidth: 340, width: '100%', mx: 'auto'}}>
          {!isSuccess ? (
            <>
              <Label htmlFor="email">
                Email
              </Label>
              <Input
                id="email"
                name="email"
                type="text"
                placeholder="Enter Email"
                value={email}
                onChange={handleChange}
                helperText={errors.email || error?.data?.error}
                error={!!(errors.email || error?.data?.error)}
                fullWidth
                style={{marginBottom: '30px'}}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{mb: 3}}
                disabled={isLoading}
                onClick={handleSubmit}
                fullWidth
              >
                EMAIL ME A NEW PASSWORD
                {isLoading && (
                  <CircularProgress size={30} />
                )}
              </Button>
              <span >
                Problem with your account?{" "}
                  <Link href="mailto:contactus@invite.club">
                  Contact us
                </Link>
              </span>
            </>
          ) : (
            <>
              <Typography align={'center'} sx={{}}>
                We have received your request.
                <br /> Please, check your inbox to find your new password.
              </Typography>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onClose}
                sx={{mt: 3}}
              >
                OK
              </Button>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
