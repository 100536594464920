import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper';
import Input from '../../components/UI/Input';
import { Button } from '../../components/UI/Button';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateProfileMutation } from '../../services/mainApi';
import { useCheckValueMutation } from '../auth/api';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import Label from '../../components/UI/Label';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { useDeleteUserMutation } from '../user-management/api';
import { logout } from '../auth/slice';
import { useHistory } from 'react-router-dom';

const Settings = () => {
  const {user} = useSelector(store => store.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [update] = useUpdateProfileMutation();
  const [checkValue] = useCheckValueMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    username: user?.username || '',
    email: user?.email || '',
    password: '',
    confirmPassword: '',
    deleteUsername: ''
  });
  const [passwordFields, setPasswordFields] = useState({password: false, confirmPassword: false});

  useEffect(() => {
    if (user?.username || user?.email) {
      setForm({
        username: user?.username || '',
        email: user?.email || '',
        password: '',
        confirmPassword: '',
        deleteUsername: ''
      })
    }
  }, [user?.username, user?.email])
  const onChange = ({target: {name, value}}) => {
    setForm(state => ({...state, [name]: value}));
    setErrors(state => ({...state, [name]: null}));
  };

  const handleSubmit = (key) => () => {
    if (form?.[key] === user?.[key] || form?.[key] === '') {
      setErrors(state => ({...state, [key]: 'Value can not be empty'}))
      return;
    }
    if (key === 'email' && !String(form?.[key]).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      setErrors(state => ({...state, email: 'Enter a valid email address'}))
      return;
    }
    checkValue({key: key, body: {[key]: form?.[key]}}).then(res => {
      if (res?.data?.result) {
        setErrors({[key]: `${key} already exist please try another one`})
      } else {
        update({id: user?.id, body: {[key]: form?.[key]}}).then(res => {
          if (res) {
            enqueueSnackbar(`${key} successfully changed`, {
              variant: 'success',
              autoHideDuration: 10000,
              preventDuplicate: true,
              action: key => <IconButton
                onClick={() => closeSnackbar(key)}
                color={'primary'}
              >
                <CloseIcon sx={{color: '#0E1D31', fontSize: '20px'}}/>
              </IconButton>
            });
          }
        });
      }
    })
  };

  const handlePasswordSubmit = () => {
    if (form.password === "") {
      setErrors((state) => ({...state, password: "Password cannot be empty"}));
    }
    if (form.confirmPassword === "") {
      setErrors((state) => ({...state, confirmPassword: "Confirm Password cannot be empty"}));
    }
    if (form.password !== form.confirmPassword) {
      setErrors((state) => ({...state, confirmPassword: "Passwords do not match."}));
    }
    update({id: user?.id, body: {password: form?.password, confirmPassword: form?.confirmPassword}}).then(res => {
      if (res) {
        enqueueSnackbar(`Password successfully changed`, {
          variant: 'success',
          autoHideDuration: 10000,
          preventDuplicate: true,
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon sx={{fontSize: '20px'}}/>
          </IconButton>
        });
      }
    });
  };

  const toggleField = (name) => () => {
    setPasswordFields(state => ({...state, [name]: !state[name]}));
  };

  const handleDelete = () => {
    if (user?.username !== form?.deleteUsername) {
      setErrors(state => ({...state, deleteUsername: 'Enter username to close account'}));
      return;
    }
    deleteUser({id: user?.id}).then(res => {
      if (res.data) {
        dispatch(logout());
        history.push('/');
      }
    });
  };

  return (
    <PageWrapper>
      <Stack direction={'column'} spacing={4} sx={{maxWidth: 600, width: '100%', mx: 'auto'}}>
        <ExpandBox value={user?.username} label={'username'} onSubmit={handleSubmit('username')}>
          <Input
            error={Boolean(errors?.username)}
            helperText={errors?.username}
            fullWidth
            value={form?.username}
            onChange={onChange}
            name={'username'}
          />
        </ExpandBox>
        <ExpandBox value={user?.email} label={'email'} onSubmit={handleSubmit('email')}>
          <Input
            error={Boolean(errors?.email)}
            helperText={errors?.email}
            fullWidth
            value={form?.email}
            onChange={onChange}
            name={'email'}
          />
        </ExpandBox>
        <ExpandBox value={'••••••••••'} label={'Password'} onSubmit={handlePasswordSubmit}>
          <Stack direction={'column'} spacing={2} sx={{width: '100%'}}>
            <Label htmlFor="password">
              Password
            </Label>
            <Input
              id="password"
              name="password"
              type={passwordFields.password ? "text" : "password"}
              placeholder="Enter Password"
              value={form.password}
              onChange={onChange}
              helperText={errors?.password}
              error={!!errors?.password}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                  >
                    <IconButton
                      onClick={toggleField('password')}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {passwordFields.password ? (
                        <VisibilityOff sx={{color: "#6B6963"}} />
                      ) : (
                        <Visibility sx={{color: "#6B6963"}} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
            <Label sx={{mt: 2}} htmlFor="password">
              Confirm Password
            </Label>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              type={passwordFields.confirmPassword ? "text" : "password"}
              placeholder="Re Enter Password"
              value={form.confirmPassword}
              onChange={onChange}
              helperText={errors?.confirmPassword}
              error={!!errors?.confirmPassword}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                  >
                    <IconButton
                      onClick={toggleField('confirmPassword')}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {passwordFields.confirmPassword ? (
                        <VisibilityOff style={{ color: "#6B6963" }} />
                      ) : (
                        <Visibility style={{ color: "#6B6963" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
          </Stack>
        </ExpandBox>
        <ExpandBox label={'Close account'} onSubmit={handleDelete}>
          <Input
            placeholder={'Please enter username to close account'}
            error={Boolean(errors?.deleteUsername)}
            helperText={errors?.deleteUsername}
            fullWidth
            value={form?.deleteUsername}
            onChange={onChange}
            name={'deleteUsername'}
          />
        </ExpandBox>
      </Stack>
    </PageWrapper>
  );
};

export default Settings;

const ExpandBox = ({children, label, value, onSubmit}) => {
  const [visible, setVisible] = useState(false);
  const onChangeVisibility = () => {
    setVisible(state => !state);
  };

  return (
    <Box sx={{pb: 2, borderBottom: '1px solid #ccc'}}>
      <Stack direction={'row'} justifyContent={'space-between'}>
      <Typography sx={{fontSize: 15, fontWeight: 600, textTransform: 'uppercase'}}>{label}</Typography>
        <Typography
          sx={{color: '#0E1D31', fontSize: 14, fontWeight: 600, cursor: 'pointer'}}
          onClick={onChangeVisibility}
        >
          {!visible ? 'Change' : 'Cancel'}
        </Typography>
      </Stack>
      {!visible && (
        <Typography sx={{color: '#696969', fontSize: 14, fontWeight: 600, mt: 2}}>
          {value}
        </Typography>
      )}
      {visible && (
        <Stack direction={'row'} spacing={3} sx={{mt: 2}}>
          {children}
          <Button variant={'contained'} onClick={onSubmit} sx={{width: 150}}>Submit</Button>
        </Stack>
      )}
    </Box>
  );
};
