import ModalTitle from '../../components/UI/DialogTitle';
import { Dialog, DialogContent, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import InvitationTimeline from './invitation/InvitationTimeline';
import InvitationInfo from './invitation/InvitationInfo';
import EventInvitationInfo from './invitation/EventInvitationInfo';
import InvitationMediaList from './invitation/InvitationMediaList';
import { theme } from '../../services/Theme';
import MobileSlider from './invitation/MobileSlider';

const PreviewInvitationModal = ({showTitle = true, open, onClose, invitation}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 1300,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        {showTitle && <Typography sx={{fontSize: 15, fontWeight: 600}}>PREVIEW INVITATION</Typography>}
      </ModalTitle>
      <DialogContent sx={{p: 3}}>
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          alignItems={{xs: 'flex-start', sm: 'center'}}
          justifyContent={'space-between'}
          spacing={{xs: 1, sm: 2}}
          sx={{my: 3}}
        >
          <Typography sx={{fontSize: 28}}>
            {invitation?.name ? invitation.name : 'Event name'}
          </Typography>
        </Stack>
        {isMobile && invitation?.eventPhotos?.length > 0 && (
          <MobileSlider images={invitation?.eventPhotos} />
        )}
        {!isMobile && invitation?.eventPhotos?.length > 0 && (
          <InvitationMediaList images={invitation?.eventPhotos} />
        )}
        {invitation?.additionalInfo && (
          <>
            <Typography sx={{pt: 2, fontSize: 14, fontWeight: 400, mb: 1}}>{invitation?.additionalInfo}</Typography>
            <Divider />
          </>
        )}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={1}
          sx={{mt: 1}}
        >
          <Grid item xs={12} sm={6}>
            <InvitationTimeline invitation={invitation} />
          </Grid>
          <Grid item xs={12} sm={6}>
            {invitation?.experienceType === 'event' ? <EventInvitationInfo invitation={invitation} /> : <InvitationInfo invitation={invitation} />}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewInvitationModal;
