import React, { useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../components/PageWrapper';
import { Stack, Tabs, Typography, InputAdornment } from '@mui/material';
import { Button } from '../../components/UI/Button';
import Box from '@mui/material/Box';
import TabItem from '../../components/UI/TabItem';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SearchIcon from '@mui/icons-material/Search';
import { useUsersQuery, useWaitingListQuery } from './api';
import WaitingListTable from './WaitingListTable';
import VerifiedUsersTable from './VerifiedUsersTable';
import Input from '../../components/UI/Input';
import ReferralUsersTable from './ReferralUsersTable';
import { Link } from 'react-router-dom';

const UserManagement = () => {
  const [tab, setTab] = useState(0);
  const [params, setParams] = useState({});
  const [usersQuantity, setUsersQuantity] = useState(0);
  const {data: users = [], isLoading} = useUsersQuery(params, {skip: tab === 0});
  const {data: waitlistedUsers = [], isLoading: isWaitlistedUsersLoading} = useWaitingListQuery(params, {skip: tab !== 0});
  const referralUsers = useMemo(() => users?.filter(item => item?.signUpReferralUser?.username), [users]);

  useEffect(() => {
    if (waitlistedUsers?.length > 0) {
      setUsersQuantity(waitlistedUsers?.length)
    }
  }, [waitlistedUsers]);

  useEffect(() => {
    if (users?.length > 0) {
      setUsersQuantity(users?.length);
    }
  }, [users]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
    setParams({});
    if (newValue === 0) {
      setUsersQuantity(waitlistedUsers?.length);
    }
    if (newValue === 1) {
      setUsersQuantity(users?.length);
    }
    if (newValue === 2) {
      setUsersQuantity(referralUsers?.length);
    }
  };

  const onSearch = ({target: {value}}) => {
    const name = tab === 0 ? 'email' : 'username';
    setParams(state => ({...state, [name]: value}))
  };

  const handleFilter = (value, name = 'role') => {
    if (!value.length) {
      const {[name]: unUsed, ...newParams} = params; //eslint-disable-line
      setParams(newParams);
      return;
    }
    setParams(state => ({...state, [name]: value}))
  }

  return (
    <PageWrapper>
      <Stack direction={{xs: 'column', sm: 'row'}} alignItems={'center'} justifyContent={'space-between'} spacing={1} sx={{mb: 3}}>
        <Typography sx={{fontSize: 18, fontWeight: 400}}>User Management</Typography>
        <Button component={Link} variant={'contained'} to={'/add-user'} startIcon={<PersonAddIcon />} sx={{height: 40}}>ADD USERS MANUALLY</Button>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Tabs variant={'scrollable'} value={tab} onChange={handleChange} sx={{minHeight: 'auto', '& .MuiTabs-indicator': {backgroundColor: '#0E1D31'}}}>
          <TabItem label="Waiting list" icon={<HourglassBottomIcon />} />
          <TabItem label="Approved users" icon={<VerifiedUserIcon />} />
          <TabItem label="Referrals record" icon={<EqualizerIcon />} />
        </Tabs>
        <Stack direction={{xs: 'column', sm: 'row'}} alignItems={{xs: 'flex-start', sm: 'center'}} justifyContent={'space-between'} spacing={1} sx={{mt: 3}}>
          <Typography sx={{fontSize: 15, fontWeight: 400}}>Users ({usersQuantity})</Typography>
          <Input
            value={tab === 0 ? params?.email || '' : params?.username || ''}
            autoComplete='off'
            InputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
              endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
            }}
            sx={{height: 48, width: {xs: '100%', sm: 'auto'}}}
            onChange={onSearch}
            placeholder={tab === 0 ? 'Search by email' : 'Search by username'}
          />
        </Stack>
        <TabPanel value={tab} index={0}>
          <WaitingListTable users={waitlistedUsers} isLoading={isWaitlistedUsersLoading} onFilter={handleFilter} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <VerifiedUsersTable users={users} isLoading={isLoading} onFilter={handleFilter} />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <ReferralUsersTable users={referralUsers} isLoading={isLoading} onFilter={handleFilter} />
        </TabPanel>
      </Box>
    </PageWrapper>
  );
};

export default UserManagement;

const TabPanel = ({ children, value, index, ...props }) => {
  return (
    <Box
      sx={{mt: 2}}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && children}
    </Box>
  );
};
