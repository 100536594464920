import { createSlice } from '@reduxjs/toolkit';
import { notificationsApi } from './api';

const initialState = {
  newNotificationsCount: 0,
  notifications: [],
};

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationsCount: (state, {payload}) => {
      state.newNotificationsCount = payload;
    },
    setNotifications: (state, {payload}) => {
      state.notifications = payload;
    },
    addNotification: (state, {payload}) => {
      state.newNotificationsCount = state.newNotificationsCount + 1;
      state.notifications = [payload, ...state.notifications]
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      notificationsApi.endpoints.updateNotificationStatus.matchFulfilled,
      (state, {payload}) => {
        state.newNotificationsCount = 0;
        state.notifications = payload.notifications;
      }
    )
  }
});

export const { setNotifications, addNotification, setNotificationsCount } = notifications.actions;
export default notifications.reducer;
