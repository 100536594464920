import PageWrapper from "../../components/PageWrapper";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Grid, MenuItem, Stack, Typography } from "@mui/material";
import { Button } from "../../components/UI/Button";
import { useSelector } from "react-redux";
import { useUpdateProfileMutation } from "../../services/mainApi";
import dayjs from "dayjs";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import FormInput from "../../components/UI/FormInput";
import Label from "../../components/UI/Label";
import Select from "../../components/UI/Select";
import SectorsSelect from "../../components/SectorsSelect";
import ActivitiesGroup from "../../components/ActivitiesGroup";
import Input from "../../components/UI/Input";
import CustomDateField from "../../components/CustomDateField";
import ConnectInstagramButton from "./ConnectInstagramButton";
import ProfilePhotoTiles from "./ProfilePhotoTiles";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CompleteProfile = () => {
  const history = useHistory();
  const { user } = useSelector((store) => store.user);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const isDisabled =
    form?.gender === "" ||
    form?.occupationSector === "" ||
    form?.shortBio === "" ||
    form?.birthDate === "" ||
    form?.instagram === "" ||
    !form?.interests?.length ||
    !user?.imgUrl?.length;
  const isChanged =
    JSON.stringify(form) ===
    JSON.stringify({
      shortBio: user?.shortBio || "",
      gender: user?.gender || "",
      birthDate: user?.birthDate || null,
      interests: user?.interests || [],
      instagram: user?.instagram || "",
      occupationSector: user?.occupationSector || "",
    });
  const [update] = useUpdateProfileMutation();
  const path = localStorage.getItem("path");
  const allowSkip = useQuery().get("allowSkip");
  const localUserData = localStorage.getItem("form");

  useEffect(() => {
    if (user?.role) {
      setForm({
        shortBio: user?.shortBio || "",
        gender: user?.gender || "",
        birthDate: user?.birthDate || null,
        interests: user?.interests || [],
        instagram: user?.instagram || "",
        occupationSector: user?.occupationSector || "",
      });
    }
    if (localUserData) {
      setForm(JSON.parse(localUserData));
    }
  }, [user, localUserData]);

  useEffect(() => {
    if (
      user?.gender !== "" &&
      user?.occupationSector !== "" &&
      user?.shortBio !== "" &&
      user?.birthDate !== "" &&
      user?.instagram !== "" &&
      user?.interests?.length > 0 &&
      user?.imgUrl.length > 0
    ) {
      history.push(path ? path : "/events-gallery");
      localStorage.removeItem("path");
      localStorage.removeItem("form");
    }
  }, [user, history, path]);

  const onChangeInput = ({ target: { name, value } }) => {
    setErrors((state) => ({ ...state, [name]: false }));
    if (name === "shortBio" && value.length > 300) {
      setErrors((state) => ({
        ...state,
        [name]: "Field should contain less than 300 characters",
      }));
      setForm((state) => ({ ...state, [name]: value.slice(0, 300) }));
      return;
    }
    setForm((state) => ({ ...state, [name]: value }));
  };

  const handleDateChange = (e) => {
    setForm((state) => ({
      ...state,
      birthDate: dayjs(e?.$d || null).format(),
    }));
  };

  const onChangeActivity = (name) => (value) => {
    setForm((state) => ({
      ...state,
      [name]: state[name].includes(value)
        ? state[name].filter((item) => item !== value)
        : [...state[name], value],
    }));
  };

  const onConnectInstagram = (username) => {
    setForm((state) => ({ ...state, instagram: username }));
  };

  const onUpdate = () => {
    update({ id: user?.id, body: form }).then((res) => {
      if (res.data) {
        enqueueSnackbar(`Your profile was updated`, {
          variant: "info",
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: "not_found",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          ),
        });
      }
    });
  };

  const handleLocalStateSave = () => {
    localStorage.setItem("form", JSON.stringify(form));
  };

  const onSkip = () => {
    history.push("/events-gallery");
    localStorage.removeItem("path");
    localStorage.removeItem("form");
  };

  return (
    <PageWrapper>
      <Stack
        direction={"column"}
        sx={{ maxWidth: 475, width: "100%", mx: "auto" }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: 600 }} align={"center"}>
          Complete Your Profile
        </Typography>
        {path && (
          <Stack direction={"row"} spacing={1} sx={{ my: 2 }}>
            <InfoIcon
              sx={{
                ml: 0.5,
                fontSize: 20,
                width: 20,
                height: 20,
                fill: "#0E1D31",
              }}
            />

            <Typography sx={{ fontSize: 14 }}>
              Fill out your details to start{" "}
              {path === "/create-invitation"
                ? "posting event invitations"
                : "applying to event invitations"}
              . Let others know who you are!
            </Typography>
          </Stack>
        )}
        <Stack direction={"column"}>
          {!user?.imgUrl?.length && (
            <ProfilePhotoTiles showActions sx={{ maxWidth: "100%" }} isUser />
          )}
          {!user?.shortBio && (
            <FormInput
              label={"Short bio"}
              required
              fullWidth
              multiline
              rows={4}
              name={"shortBio"}
              value={form?.shortBio || ""}
              error={errors?.shortBio}
              onChange={onChangeInput}
            />
          )}
          {!user?.gender && (
            <>
              <Label sx={{ mb: 1 }} required={!allowSkip}>
                Gender
              </Label>
              <Select
                name={"gender"}
                value={form?.gender || ""}
                onChange={onChangeInput}
                error={errors?.gender}
                sx={{ mb: 2 }}
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>
            </>
          )}
          {!user?.occupationSector && (
            <>
              <Label sx={{ mb: 1 }} required={!allowSkip}>
                Industry
              </Label>
              <SectorsSelect
                name={"occupationSector"}
                value={form?.occupationSector || ""}
                onChange={onChangeInput}
                error={errors?.occupationSector}
                sx={{ mb: 2 }}
              />
            </>
          )}
          {!user?.birthDate && (
            <>
              <Label sx={{ mb: 1 }} required={!allowSkip}>
                Date of Birth
              </Label>
              <CustomDateField
                name={"birthDate"}
                value={dayjs(form?.birthDate) || null}
                onChange={handleDateChange}
                sx={{ mb: 2 }}
              />
            </>
          )}
          {!user?.instagram && (
            <>
              <Label sx={{ mb: 1 }} required={!allowSkip}>
                Instagram
              </Label>
              <Input
                name={"instagram"}
                value={form?.instagram || ""}
                onChange={onChangeInput}
                placeholder={"@username"}
                sx={{ mb: 2 }}
                disabled
              />
              <Grid item xs={12} sm={12}>
                <ConnectInstagramButton
                  isConnected={form?.instagram}
                  onSuccess={onConnectInstagram}
                  onClick={handleLocalStateSave}
                />
              </Grid>
            </>
          )}
          {!user?.interests?.length && (
            <ActivitiesGroup
              selectedActivities={form?.interests}
              label={"Interests"}
              required={user?.role === "host"}
              tooltipText={"Things you enjoy"}
              error={errors?.interests}
              onChange={onChangeActivity("interests")}
            />
          )}
          <Button
            variant={"contained"}
            sx={{
              "&.MuiButton-contained": {
                maxWidth: 330,
                width: "100%",
                mx: "auto",
              },
            }}
            onClick={onUpdate}
            disabled={isDisabled || isChanged}
          >
            Update
          </Button>
        </Stack>
        {allowSkip && (
          <Button
            variant={"outlined"}
            sx={{
              "&.MuiButton-outlined": {
                maxWidth: 330,
                width: "100%",
                mx: "auto",
                mt: 3,
              },
            }}
            onClick={onSkip}
          >
            Skip
          </Button>
        )}
      </Stack>
    </PageWrapper>
  );
};

export default CompleteProfile;
