import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetUserQuery } from "../../features/auth/api";
import { isEmptyObject } from "../../util/utils";

const PrivateRoute = ({ component: Component, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((store) => store.user);
  const { pathname } = useLocation();

  const [getUser] = useLazyGetUserQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (isEmptyObject(user)) {
      getUser();
    }
  }, [dispatch, getUser, history, user]);

  return <Route {...props} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;
