import StaticPageWrapper from '../../components/StaticPageWrapper';
import { Box, Container, Typography } from '@mui/material';
import Label from '../../components/UI/Label';
import Input from '../../components/UI/Input';
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/UI/Button';
import { useCheckValueMutation, useCheckWaitingTokenMutation, useLoginMutation, useRegisterMutation } from './api';
import { useHistory, useLocation } from 'react-router-dom';

const FinishApplyMembership = () => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [checkValue] = useCheckValueMutation();
  const [checkToken] = useCheckWaitingTokenMutation();
  const [register, {isLoading}] = useRegisterMutation();
  const [login] = useLoginMutation();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get("token");
  const history = useHistory();

  useEffect(() => {
    if (token) {
      checkToken({token}).then(res => {
        if (res?.data) {
          setForm(state => ({
            ...state,
            email: res.data.email,
            name: res.data.firstName,
            surname: res.data.lastName,
            role: res.data.role,
            occupationSector: res.data.occupationSector,
            isFoundingMember: res.data.isConfirmedFoundingMember,
            ...(res.data.referralCode && {referralCode: res.data.referralCode}),
          }));
        }
      })
    }
  }, [token, checkToken]);


  const handleChange = ({target: {value, name}}) => {
    setForm(state => ({...state, [name]: value}));
    setErrors(state => ({...state, [name]: null}));
    if (name === 'password') {
      if (value.length < 8) {
        setErrors(state => ({...state, password: "Too short"}));
      } else if (value.length > 25) {
        setErrors(state => ({...state, password: "Too long"}));
      } else {
        setErrors(state => ({...state, password: null}));
      }
    }
    if (name === 'confirmPassword') {
      if (form?.password !== value) {
        setErrors(state => ({...state, confirmPassword: "Passwords don't match"}));
      } else {
        setErrors(state => ({...state, confirmPassword: null}));
      }
    }
  };

  const handleSave = () => {
    checkValue({key: 'email', body: {email: form?.email}}).then((res) => {
      if (res?.data?.result) {
        setErrors((prevState) => ({...prevState, general: "Email already exists"}));
      } else {
        checkValue({key: 'username', body: {username: form?.username}}).then(res => {
          if (res?.data?.result) {
            setErrors((prevState) => ({...prevState, username: "Username already exists"}));
          } else {
            register({...form}).then(res => {
              if (res?.data) {
                login({username: form?.username, password: form?.password}).then((res) => {
                  if (res.data) {
                    history.push('/complete-your-profile');
                  }
                });
              }
            })
          }
        });
      }
    });
  };

  return (
    <StaticPageWrapper>
      <Container>
        <Box sx={{maxWidth: 475, width: '100%', mx: 'auto'}}>
          <Typography sx={{fontSize: 20, fontWeight: 600, mb: 1}}>Welcome to Invite.Club</Typography>
          <Typography sx={{fontSize: 15, fontWeight: 600, mb: 2}}>Let's setup your account.</Typography>
          <Label>Username</Label>
          <Input
            name={'username'}
            value={form?.username || ''}
            onChange={handleChange}
            error={Boolean(errors?.username)}
            helperText={errors?.username}
            sx={{mb: 3}}
            fullWidth
          />
          <Label>Password</Label>
          <Input
            name={'password'}
            value={form?.password || ''}
            onChange={handleChange}
            error={Boolean(errors?.password)}
            helperText={errors?.password}
            sx={{mb: 3}}
            fullWidth
          />
          <Label>Confirm password</Label>
          <Input
            name={'confirmPassword'}
            value={form?.confirmPassword || ''}
            onChange={handleChange}
            error={Boolean(errors?.confirmPassword)}
            helperText={errors?.confirmPassword}
            sx={{mb: 3}}
            fullWidth
          />
          {errors?.general && <Typography sx={{color: 'red', mb: 3}}>{errors?.general}</Typography>}
          <Button
            variant={'contained'}
            color={'action'}
            onClick={handleSave}
            fullWidth
            sx={{maxWidth: 300, width: '100%', display: 'block', mx: 'auto'}}
            disabled={!form?.username || !form?.password || !form?.confirmPassword || isLoading}
          >
            Save
          </Button>
        </Box>
      </Container>
    </StaticPageWrapper>
  );
};

export default FinishApplyMembership;
