import { Dialog, DialogContent, Typography } from '@mui/material';
import { Button } from '../../../components/UI/Button';
import React from 'react';

const InfoModal = ({open, onClose}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 500,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <DialogContent sx={{p: 3}}>
        <Typography sx={{fontSize: 16}}>
          The following information will be retrieved from your profile and be included in your application:
          Short Bio, Gender, Nationality, Date of Birth, Educational Level, Languages, Interests.
        </Typography>
        <Button
          sx={{mt: 2}}
          fullWidth
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default InfoModal;
