import ModalTitle from '../../../components/UI/DialogTitle';
import { Dialog, DialogContent, Typography } from '@mui/material';
import React from 'react';
import { Button } from '../../../components/UI/Button';
import { useHistory } from 'react-router-dom';

const SuccessApplyDialog = ({open, onClose, firstName, lastName}) => {
  const history = useHistory();

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 500,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        <Typography align={'center'}>Application Submitted</Typography>
      </ModalTitle>
      <DialogContent sx={{p: 3, mt: 3}}>
        <Typography align={'center'}>
          Thank you for your interest in Invite.Club. We will contact you with further updates.
        </Typography>
        <Button
          sx={{mt: 2, mx: 'auto', display: 'block', width: '100%', maxWidth: 200}}
          variant="contained"
          color="primary"
          onClick={() => history.push('/')}
        >
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessApplyDialog;
