import { Route } from 'react-router-dom';
import { useLazyGetUserQuery } from '../../features/auth/api';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmptyObject } from '../../util/utils';

const PublicRoute = ({component: Component, ...props}) => {
  const {user} = useSelector(store => store.user);

  const [getUser] = useLazyGetUserQuery();

  useEffect(() => {
    if (isEmptyObject(user)) {
      getUser();
    }
  }, [user, getUser]);

  return <Route{...props} render={(props) => <Component {...props} />}/>
};

export default PublicRoute;
