import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { mainApi } from "../services/mainApi";
import newInvitation from "../features/invitations/slice";
import application from "../features/applications/slice";
import inbox from "../features/inbox/slice";
import notifications from "../features/notifications/slice";
import user from "../features/auth/slice";
import { errorMiddleware } from "../services/errors.middleware";
import { notificationsMiddleware } from "../services/notifications.middleware";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const middlewares = [
  mainApi.middleware,
  errorMiddleware,
  notificationsMiddleware,
];

const apiReducers = {
  [mainApi.reducerPath]: mainApi.reducer,
};

const rootReducer = combineReducers({
  ...apiReducers,
  user,
  newInvitation,
  application,
  inbox,
  notifications,
});

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

export default store;
export const persistor = persistStore(store);
