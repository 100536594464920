import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, IconButton } from '@mui/material';

const ModalTitle = ({children, onClose, sx, ...props}) => {
  return (
    <DialogTitle
      sx={{
        pl: 1,
        pr: {xs: 5, sm: 1},
        py: 2,
        borderBottom: children ? '1px solid #8F8F8F' : 'none',
        backgroundColor: '#fff',
        position: 'relative',
        textAlign: 'center',
        ...sx
      }}
      {...props}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            '& > svg': {
              fill: '#8F8F8F'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
};

export default ModalTitle;
