import PageWrapper from '../../components/PageWrapper';
import { useInvitationApplicationsQuery } from '../applications/api';
import { Link as NavLink, useHistory, useParams } from 'react-router-dom';
import { Stack, Typography, Box } from '@mui/material';
import React from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import { Button } from '../../components/UI/Button';

const InvitationAttendees = () => {
  const {user} = useSelector(store => store);
  const {id} = useParams();
  const {data: applications, isLoading} = useInvitationApplicationsQuery({id, params: {status: 'accepted'}});
  const history = useHistory();

  return (
    <PageWrapper>
      <Typography sx={{fontSize: 18, fontWeight: 400}}>Attendees</Typography>
      {applications?.length > 0 && !isLoading && (
        <Stack direction={'column'} gap={2} sx={{mt: 3}}>
          {applications?.map(item => (
            <Box
              key={item?.user?.id}
              sx={{
                '&:hover':{
                  boxShadow: '0 2px 4px rgba(0,0,0,0.18)'
                },
                transition: 'box-shadow 200ms cubic-bezier(0.2,0,0,1)',
                border: '1px solid rgb(221, 221, 221)',
                borderRadius: '10px',
                px: 2,
                py: 0.5,
                display: 'flex',
                alignItems: 'center',
                maxWidth: {xs: '100%', sm: 300}
              }}
            >
              <Box sx={{width: 46, height: 46, mb: 1, '& > img, & > svg': {width: '100%', height: '100%', objectFit: 'cover'}, borderRadius: '50%', overflow: 'hidden'}}>
                {item?.user?.imgUrl?.length > 0 ? (
                  <img src={item?.user?.imgUrl[item?.user?.imgUrl.length - 1]} alt={item?.user?.username} />
                ) :  <AccountCircle color={'secondary'}/>}
              </Box>
              <Typography component={NavLink} to={`/user/${item?.user?.id}`} sx={{fontSize: 12, color: '#0E1D31', ml: 1}}>
                @{item?.user?.username}
              </Typography>
              {applications[0]?.invitation?.host === user?.user?.id && (
                <Button
                  disableRipple
                  variant={'text'}
                  sx={{fontSize: 12, textTransform: 'inherit'}}
                  onClick={() => history.push({pathname: `/inbox/`, state: {activeUser: {username: item?.user?.username, id: item?.user?.id}}})}
                >
                  Send a message
                </Button>
              )}
            </Box>
          ))}
        </Stack>
      )}
      {(!applications || applications?.length === 0) && !isLoading && (
        <Typography>No attendees found</Typography>
      )}
    </PageWrapper>
  )
};

export default InvitationAttendees;
