import { Stack, Typography, Box } from '@mui/material';
import { Button } from './UI/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PartyImage from '../assets/party-image.jpg';

const NoItemsBox = ({hideButton = false, title, description, buttonText, buttonLink = '/create-invitation'}) => {

  return (
    <Stack direction={'row'} alignItems={'center'} sx={{borderRadius: '20px', overflow: 'hidden', border: '1px solid #ccc', width: '100%'}}>
      <Box sx={{py: 2, pl: {xs: 2, sm: 3}, pr: {xs: 2, sm: 5}, display: 'flex', flexDirection: 'column', minWidth: {xs: '100%', sm: 360}}}>
        <CelebrationIcon sx={{fontSize: 40, color: '#0E1D31'}} />
        {title && <Typography sx={{fontSize: 18, fontWeight: 600, mt: 2}}>{title}</Typography>}
        {description && <Typography sx={{maxWidth: {xs: '100%', sm: 300}, width: '100%', fontSize: 14, mt: 1}}>{description}</Typography>}
        {!hideButton && (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={buttonLink}
            sx={{maxWidth: '280px', mt: 2, width: '100%'}}
          >
            {buttonText}
          </Button>)
        }
      </Box>
      <Box sx={{flexGrow: 1, height: 300, display: {xs: 'none', sm: 'flex'}, '& img': {width: '100%', height: '100%', objectFit: 'cover'}}}>
        <img src={PartyImage} alt="party"/>
      </Box>
    </Stack>
  )
};

export default NoItemsBox;
