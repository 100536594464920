import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import AppHeader from "./AppHeader";
import MobileNav from "./MobileNav";
import Sidebar from "./Sidebar";

const PageWrapper = ({ hideMobileNav = false, children }) => {
  const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <div>
      <AppHeader smUp={smUp} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "calc(100dvh - 70px)",
          paddingBottom: !smUp && !hideMobileNav ? "70px" : 0,
          px: 0,
        }}
      >
        {smUp && <Sidebar />}
        <Box
          sx={{
            height: "100%",
            pb: { xs: "0", md: "65px" },
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            width: smUp ? "calc(100vw - 93px)" : "100vw",
          }}
        >
          {children}
        </Box>
      </Box>
      {!smUp && !hideMobileNav && <MobileNav />}
    </div>
  );
};

export default PageWrapper;
