import { mainApi, TAGS } from '../../services/mainApi';

export const notificationsApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    notifications: build.query({
      query: () => ({url: `notifications`}),
      providesTags: [TAGS.NOTIFICATIONS]
    }),
    updateNotificationStatus: build.mutation({
      query: ({body}) => {
        return {
          url: 'notifications/update-status',
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: [TAGS.NOTIFICATIONS],
    }),
    deleteNotification: build.mutation({
      query: (id) => ({
        url: `notifications/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAGS.NOTIFICATIONS]
    }),
  }),
  overrideExisting: false,
});

export const {
  useNotificationsQuery,
  useUpdateNotificationStatusMutation,
  useDeleteNotificationMutation
} = notificationsApi;
