import React from "react";
import "./App.css";
import { Switch } from "react-router-dom";
import Home from "./pages/Home";
import Onboarding from "./pages/static/Onboarding";
import settingsNotifications from "./pages/settingsNotifications";
import TermsConditions from "./pages/static/TermsConditions";
import PrivacyPolicy from "./pages/static/PrivacyPolicy";
import FAQ from "./pages/static/FAQ";
import { LicenseInfo } from "@mui/x-license-pro";
import PageNotFound from "./components/StaticPages/PageNotFound";
import PrivateRoute from "./components/Routes/PrivateRoute";
import AuthRoute from "./components/Routes/AuthRoute";
import Invitations from "./features/invitations";
import CreateInvitation from "./features/invitations/create-invitation";
import Invitation from "./features/invitations/invitation/Invitation";
import InvitationsGallery from "./features/invitations/invitations-gallery";
import ApplyInvitation from "./features/applications/apply-invitation";
import InvitationApplications from "./features/applications/InvitationApplications";
import Profile from "./features/profile";
import CompleteProfile from "./features/profile/CompleteProfile";
import ProfileReferrals from "./features/profile/ProfileReferrals";
import Notifications from "./features/notifications";
import Inbox from "./features/inbox";
import UserManagement from "./features/user-management";
import AddUser from "./features/user-management/AddUser";
import UserProfile from "./features/user-management/UserProfile";
import SignUp from "./features/auth/sign-up";
import FoundingMembersProgram from "./pages/static/FoundingMembersProgram";
import FinishApplyMembership from "./features/auth/FinishApplyMembership";
import EditInvitation from "./features/invitations/edit-invitation";
import Settings from "./features/settings";
import PublicRoute from "./components/Routes/PublicRoute";
import EditProfile from "./features/profile/EditProfile";
import InvitationAttendees from "./features/invitations/InvitationAttendees";
import UnSubscribe from "./components/StaticPages/UnSubscribe";
import Conversation from "./features/inbox/Conversation";
import SingleInvitationApplication from "./features/applications/SingleInvitationApplication";

LicenseInfo.setLicenseKey(
  "b1aa006cc295fe2181198a6a5c5549a1Tz00NDYxNixFPTE2ODUyODU3NTgwMjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

const App = () => {
  return (
    <Switch>
      <AuthRoute exact path="/" component={Home} />
      <AuthRoute exact path="/login" component={Home} />
      <AuthRoute exact path="/auth/reset-password" component={Home} />
      <AuthRoute exact path="/join" component={SignUp} />
      <AuthRoute
        exact
        path="/finish-applying"
        component={FinishApplyMembership}
      />
      <PublicRoute exact path="/unsubscribe" component={UnSubscribe} />
      <PublicRoute
        exact
        path="/terms-and-conditions"
        component={TermsConditions}
      />
      <PublicRoute exact path="/privacy-policy" component={PrivacyPolicy} />
      <PublicRoute exact path="/faq" component={FAQ} />
      <PublicRoute
        exact
        path="/founding/members/info/"
        component={FoundingMembersProgram}
      />
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/edit-profile" component={EditProfile} />
      <PrivateRoute
        exact
        path="/complete-your-profile"
        component={CompleteProfile}
      />
      <PrivateRoute
        exact
        path="/profile-referrals"
        component={ProfileReferrals}
      />
      <PrivateRoute exact path="/onboarding" component={Onboarding} />
      <PrivateRoute exact path="/notifications" component={Notifications} />
      <PrivateRoute exact path="/user-management" component={UserManagement} />
      <PrivateRoute exact path="/add-user" component={AddUser} />
      <PrivateRoute exact path="/user/:id" component={UserProfile} />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute
        exact
        path="/settings/notifications/"
        component={settingsNotifications}
      />
      <PrivateRoute
        exact
        path="/events-gallery"
        component={InvitationsGallery}
      />
      <PrivateRoute exact path="/my-events" component={Invitations} />
      <PrivateRoute
        exact
        path="/create-invitation"
        component={CreateInvitation}
      />
      <PrivateRoute
        exact
        path="/create-invitation/:id"
        component={CreateInvitation}
      />
      <PrivateRoute exact path="/invitation/:id" component={Invitation} />
      <PrivateRoute
        exact
        path="/invitation/:id/apply"
        component={ApplyInvitation}
      />
      <PrivateRoute
        exact
        path="/invitation/:id/edit"
        component={EditInvitation}
      />
      <PrivateRoute
        exact
        path="/invitation/:id/attendees"
        component={InvitationAttendees}
      />
      {/*<PrivateRoute exact path="/my-applications" component={Applications} />*/}
      {/*<PrivateRoute exact path="/my-applications/:id/edit" component={EditApplication}/>*/}
      <PrivateRoute
        exact
        path="/applications/:id"
        component={InvitationApplications}
      />
      <PrivateRoute
        exact
        path="/applications/:id/:applicationId"
        component={SingleInvitationApplication}
      />
      <PrivateRoute exact path="/inbox" component={Inbox} />
      <PrivateRoute exact path="/inbox/:id" component={Conversation} />
      <PublicRoute path={"*"} component={PageNotFound} />
    </Switch>
  );
};

export default App;
