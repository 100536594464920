import Label from './UI/Label';
import uploadIconFile from '../assets/create_inv/upload_picture.svg';

const ImageUploader = ({id, onChange, sx}) => {

  return (
    <Label htmlFor={id} sx={{...boxSx, ...sx}}>
      <input type="file" accept="image/*" id={id} onChange={onChange}/>
      <img src={uploadIconFile} alt={`uploaded-${id}`}/>
    </Label>
  )
};

export default ImageUploader;

const boxSx = {
  cursor: 'pointer',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  aspectRatio: '1',
  justifyContent: 'center',
  backgroundColor: '#AFAFAF',
  width: '25%',
  borderRadius: '50%',
  overflow: 'hidden',
  '& > input': {
    display: 'none'
  }
};
