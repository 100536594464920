import React from 'react';
import ProfileForm from './ProfileForm';
import PageWrapper from '../../components/PageWrapper';
import { Typography, Box, Stack, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const EditProfile = () => {
  const history = useHistory();

  return (
    <PageWrapper>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography sx={{fontSize: 18, fontWeight: 400}}>Edit profile</Typography>
        <IconButton onClick={() => history.push('/profile')}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box sx={{maxWidth: 500, margin: '0 auto'}}>
        <ProfileForm onSuccess={() => history.push('/profile')} />
      </Box>
    </PageWrapper>
  );
};

export default EditProfile;
