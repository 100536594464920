import { mainApi, TAGS } from '../../services/mainApi';

export const invitationApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    hostInvitations: build.query({
      query: ({id, params}) => ({url: `invitations/all/${id}`, params}),
      providesTags: [TAGS.HOST_INVITATIONS]
    }),
    acceptedInvitations: build.query({
      query: ({params}) => ({url: `invitations/accepted`, params}),
      providesTags: [TAGS.HOST_INVITATIONS]
    }),
    appliedInvitations: build.query({
      query: ({params}) => ({url: `invitations/applied`, params}),
      providesTags: [TAGS.HOST_INVITATIONS]
    }),
    invitations: build.query({
      query: ({params}) => ({url: `invitations`, params}),
      providesTags: [TAGS.HOST_INVITATIONS]
    }),
    invitation: build.query({
      query: (id) => ({url: `invitations/${id}`}),
      providesTags: [TAGS.INVITATION]
    }),
    draft: build.query({
      query: (id) => ({url: `invitations/draft/${id}`}),
      providesTags: [TAGS.DRAFT]
    }),
    createInvitation: build.mutation({
      query: ({body}) => {
        return {
          url: 'invitations',
          method: 'POST',
          body
        }
      },
      invalidatesTags: [TAGS.HOST_INVITATIONS],
    }),
    createDraft: build.mutation({
      query: ({body}) => ({
        url: 'invitations/create-draft',
        method: 'POST',
        body
      }),
      invalidatesTags: [TAGS.HOST_INVITATIONS],
    }),
    updateInvitation: build.mutation({
      query: ({id, body}) => ({
        url: `invitations/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.INVITATION, TAGS.APPLICATIONS, TAGS.APPLICATION],
    }),
    updateDraft: build.mutation({
      query: ({id, body}) => ({
        url: `invitations/draft/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.INVITATION, TAGS.DRAFT],
    }),
    deleteDraft: build.mutation({
      query: ({id}) => ({
        url: `draft/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.DRAFT]
    }),
    deleteInvitation: build.mutation({
      query: ({id}) => ({
        url: `invitations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.INVITATION]
    }),
    addHostImages: build.mutation({
      query: ({id, images}) => {
        const formData = new FormData();
        images.forEach((item) => formData.append('hostGallery', item))
        return {
          url: `invitations/galleryforhost/${id}`,
          method: 'PATCH',
          body: formData
        };
      },
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.INVITATION, TAGS.DRAFT],
    }),
    addCoHostImages: build.mutation({
      query: ({id, images}) => {
        const formData = new FormData();
        images.forEach((item) => formData.append('coHostsGallery', item));
        return {
          url: `invitations/galleryforcohosts/${id}`,
          method: 'PATCH',
          body: formData
        };
      },
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.INVITATION],
    }),
    addEventPhotos: build.mutation({
      query: ({id, images}) => {
        const formData = new FormData();
        images.forEach((item) => formData.append('eventPhotos', item));
        return {
          url: `invitations/event-photos/${id}`,
          method: 'PATCH',
          body: formData
        };
      },
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.INVITATION],
    }),
    deleteImage: build.mutation({
      query: ({id, body}) => ({
          url: `invitations/delete-image/${id}`,
          method: 'PATCH',
          body
      }),
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.INVITATION],
    }),
    addVideo: build.mutation({
      query: ({id, video}) => {
        const formData = new FormData();
        formData.append('video', video)
        return {
          url: `invitations/video/${id}`,
          method: 'PATCH',
          body: formData
        };
      },
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.INVITATION],
    }),
    deleteVideo: build.mutation({
      query: ({id, body}) => ({
        url: `invitations/delete-video/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.INVITATION],
    }),
    editInvitationBackground: build.mutation({
      query: ({id, image}) => {
        const formData = new FormData();
        formData.append('img', image);
        return {
          url: `/invitations/image/${id}`,
          method: 'PATCH',
          body: formData
        };
      },
      invalidatesTags: [TAGS.INVITATION],
    }),
    sendRequestForAccommodation: build.mutation({
      query: (body) => {
        return {
          url: `/invitations/send-request-for-accommodation`,
          method: 'POST',
          body
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useHostInvitationsQuery,
  useInvitationsQuery,
  useAcceptedInvitationsQuery,
  useAppliedInvitationsQuery,
  useInvitationQuery,
  useDraftQuery,
  useCreateInvitationMutation,
  useCreateDraftMutation,
  useUpdateInvitationMutation,
  useUpdateDraftMutation,
  useDeleteInvitationMutation,
  useAddHostImagesMutation,
  useDeleteImageMutation,
  useAddCoHostImagesMutation,
  useAddEventPhotosMutation,
  useAddVideoMutation,
  useDeleteVideoMutation,
  useEditInvitationBackgroundMutation,
  useSendRequestForAccommodationMutation,
} = invitationApi;
