import { useHistory } from 'react-router-dom';
import StaticPageWrapper from '../../components/StaticPageWrapper';
import BgPattern from '../../assets/founding-members-bg.jpg';
import { Box, Container, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '../../components/UI/Button';

const FoundingMembersProgram = () => {
  const history = useHistory();

  return (
    <StaticPageWrapper
      hideFooter
      sx={{
        background: `url(${BgPattern}) 50% / contain no-repeat`,
        minHeight: '100vh',
        position: 'relative',
      }}
    >
      <Container sx={{maxWidth: 1320}} maxWidth={'xl'}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', my: 6}}>
          <Box sx={{width: '100%', background: 'linear-gradient(125deg, rgba(253,253,253,1) 10%, rgba(215,215,215,1) 100%)', p: 2}}>
            <Typography
              sx={{
                fontSize: {xs: 20, md: 26},
                textTransform: 'uppercase',
                fontWeight: 100,
                letterSpacing: '5.25px',
                color: '#0E1D31',
                textAlign: {xs: 'center', sm: 'left'},
              }}
            >
              apply for founder membership
            </Typography>
          </Box>
          <List sx={{...ListSx, mt: 4}}>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Be part of the initial wave of members that define our platform." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Influence the number and type of experiences posted and completed." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Enjoy exclusive benefits and incentives." />
            </ListItem>
          </List>
          <Typography sx={{textTransform: 'uppercase', fontWeight: 100, fontSize: {xs: 20, md: 26}, width: '100%', mt: 4}}>benefits</Typography>
          <Typography sx={{fontSize: {xs: 16, md: 18}, color: '#000', letterSpacing: '0.25px', width: '100%', mt: 3}}>
            Enjoy privileges available only to Founding Members:
          </Typography>
          <List sx={{...ListSx, mt: 2}}>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Lifetime membership – completely free." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Exclusive experience curation service: First one free, and 50% off subsequent ones." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="A distinguished Founding Member badge." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Right to introduce an unlimited number of new members." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Right to introduce three founding members" />
            </ListItem>
          </List>
          <Typography sx={{textTransform: 'uppercase', fontWeight: 100, fontSize: {xs: 20, md: 26}, width: '100%', mt: 4}}>requirements</Typography>
          <Typography sx={{fontSize: {xs: 16, md: 18}, color: '#000', letterSpacing: '0.25px', width: '100%', mt: 3}}>
            In order to maintain Founding Member status you need to:
          </Typography>
          <List sx={{...ListSx, mt: 2}}>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Post an invitation or apply to an invitation within 3 months." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Repeat once a year for two more years." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Refer at least 10 other members within 5 months that are accepted." />
            </ListItem>
          </List>
          <Typography sx={{textTransform: 'uppercase', fontWeight: 100, fontSize: {xs: 20, md: 26}, width: '100%', mt: 4}}>OWNERSHIP STAKES</Typography>
          <Typography sx={{fontSize: {xs: 16, md: 18}, color: '#000', letterSpacing: '0.25px', width: '100%', mt: 3}}>
            Referring members to the platform will earn you a shareholding stake that will be of significant value in the future.
          </Typography>
          <List sx={{...ListSx, mt: 2}}>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Earn 1 equity unit for every member referred and approved." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText primary="Each equity unit = 0.001% stake in the Invite.Club’s parent company." />
            </ListItem>
          </List>
          <Button
            variant={'contained'}
            color={'primary'}
            sx={{mt: 6, maxWidth: 300, width: '100%', mr: 'auto'}}
            onClick={() => history.push({pathname: '/join', state: {isFoundingMember: true}})}
          >
            apply for membership
          </Button>
        </Box>
      </Container>
    </StaticPageWrapper>
  );
};

export default FoundingMembersProgram;

const ListSx = {
  p: 0,
  width: '100%',
  '& .MuiListItem-root': {
    alignItems: 'flex-start',
    '& + .MuiListItem-root': {
      mt: 2,
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'auto',
    pt: 1,
    '& .MuiSvgIcon-root': {
      color: '#0E1D31',
      fontSize: 10,
      mr: 1
    },
  },
  '& .MuiListItemText-root': {
    m: 0,
    '& .MuiTypography-root': {
      fontSize: {xs: 16, md: 18},
      color: '#000',
      letterSpacing: '0.25px',
    }
  }
};
