import { Container, Typography } from "@mui/material";
import { Box } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import StaticPageWrapper from "../StaticPageWrapper";
import { Button } from "../UI/Button";

const PageNotFound = () => {
  const history = useHistory();
  return (
    <StaticPageWrapper>
      <Container sx={{ maxWidth: 1320 }} maxWidth={"xl"}>
        <Box className={"home-inner"} sx={{ mt: 3 }}>
          <Typography variant={"h5"} sx={{ mb: 3 }}>
            Sorry, page not found, please return to home page.
          </Typography>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => history.push("/events-gallery")}
          >
            Return to home page
          </Button>
        </Box>
      </Container>
    </StaticPageWrapper>
  );
};

export default PageNotFound;
