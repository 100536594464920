import { Grid, Icon, Stack, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import dayjs from 'dayjs';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import accommodationIconFile from '../../../assets/accomodation.svg';
import PersonIcon from '@mui/icons-material/Person';
import PaidIcon from '@mui/icons-material/Paid';
import React from 'react';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlusOneIcon from '@mui/icons-material/PlusOne';

const EventInvitationInfo = ({invitation}) => {
  return (
    <>
      <Typography sx={{color: '#373632', fontSize: 15, fontWeight: 600}}>
        Information
      </Typography>
      <Grid container sx={{mt: 1}} spacing={2}>
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <LocationOnIcon sx={iconSx}/>
            <Typography sx={titleSx}>
              Location
            </Typography>
          </Stack>
          <Typography sx={descriptionSx}>
            {invitation?.locationFrom?.city},{' '}{invitation?.locationFrom?.country}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <CalendarMonthIcon sx={iconSx}/>
            <Typography sx={titleSx}>
              Date
            </Typography>
          </Stack>
          <Typography sx={descriptionSx}>
            {dayjs(invitation?.startDate).format('DD MMM YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <AccessTimeIcon sx={iconSx}/>
            <Typography sx={titleSx}>
              Start time
            </Typography>
          </Stack>
          <Typography sx={descriptionSx}>
            {dayjs(invitation?.startTime).format('HH:mm')}
          </Typography>
        </Grid>
        {invitation?.endTime && (
          <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <AccessTimeIcon sx={iconSx}/>
              <Typography sx={titleSx}>
                End time
              </Typography>
            </Stack>
            <Typography sx={descriptionSx}>
              {dayjs(invitation?.endTime).format('HH:mm')}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <EventAvailableIcon sx={iconSx}/>
            <Typography sx={titleSx}>
              Deadline for Application
            </Typography>
          </Stack>
          <Typography sx={descriptionSx}>
            {dayjs(invitation?.deadlineOfApplications).format('DD MMM YYYY')}
          </Typography>
        </Grid>
        {invitation?.type === 'coHost' && (
          <>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <Icon sx={{width: 18, '& > img': {width: '100%'}}}>
                  <img src={accommodationIconFile} alt={'genderIcon'}/>
                </Icon>
                <Typography sx={titleSx}>
                  Max Capacity
                </Typography>
              </Stack>
              <Typography sx={descriptionSx}>
                {invitation?.totalRooms}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <PersonIcon sx={iconSx} />
                <Typography sx={titleSx}>
                  Available Capacity
                </Typography>
              </Stack>
              <Typography sx={descriptionSx}>
                {invitation?.roomsTaken}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <PaidIcon sx={iconSx} />
                <Typography sx={titleSx}>
                  Total Cost
                </Typography>
              </Stack>
              <Typography sx={descriptionSx}>
                ${invitation?.totalCost}
              </Typography>
            </Grid>
            {invitation?.linkOfAccommodation && (
              <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
                <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                  <InsertLinkIcon sx={iconSx} />
                  <Typography sx={titleSx}>
                    Link of Accommodation
                  </Typography>
                </Stack>
                <Typography sx={descriptionSx}>
                  {invitation?.linkOfAccommodation}
                </Typography>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <PlusOneIcon sx={iconSx} />
            <Typography sx={titleSx}>
              Plus One is allowed
            </Typography>
          </Stack>
          <Typography sx={descriptionSx}>
            {invitation?.hasPlusOne ? 'Yes' : 'No'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default EventInvitationInfo;


const iconSx = {
  color: '#000',
  fontSize: 18
};

const titleSx = {
  color: '#000',
  fontSize: 14,
  fontWeight: 600,
};

const descriptionSx = {
  textTransform: 'capitalize',
  color: '#373632',
  fontSize: 14,
  fontWeight: 400,
  mt: 0.5
};
