import {
  Badge,
  Box,
  Typography,
  Stack,
  Link,
  Menu as MuiMenu,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUpdateNotificationStatusMutation } from "../features/notifications/api";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { transformDate } from "../util/utils";
import NotificationDescription from "../components/NotificationDescription";
import NotificationsIcon from "./icons/NotificationsIcon";

const NotificationDropdown = () => {
  const { notifications } = useSelector((store) => store);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateStatus] = useUpdateNotificationStatusMutation();
  const history = useHistory();

  useEffect(() => {
    if (
      notifications.notifications?.length > 0 &&
      notifications.notifications?.some((item) => !item?.read) &&
      anchorEl
    ) {
      updateStatus({
        body: {
          notifications: notifications.notifications
            .filter((item) => !item?.read)
            .map((item) => item?.id),
        },
      });
    }
  }, [notifications.notifications, updateStatus, anchorEl]);

  const handleMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    if (notifications.notifications?.length === 0) {
      history.push("/notifications");
      return;
    }
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          cursor: "pointer",
          "& > svg": {
            color: anchorEl ? theme.palette.action.active : "primary",
          },
        })}
        onClick={handleMenu}
      >
        <Badge
          badgeContent={notifications?.newNotificationsCount}
          color="primary"
          sx={{ "& .MuiBadge-badge": { color: "#fff" } }}
        >
          <NotificationsIcon
            sx={{ width: 24, height: 24, strokeColor: "#000" }}
          />
        </Badge>
      </Box>
      <MuiMenu
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
        PaperProps={{
          elevation: 2,
          sx: {
            boxShadow: "0 2px 16px rgba(0,0,0,0.12)",
            maxHeight: "calc(100% - 65px)",
          },
        }}
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
          sx: {
            p: 0,
            minWidth: anchorEl && anchorEl.offsetWidth,
          },
        }}
      >
        <NotificationsChildren notifications={notifications.notifications} />
      </MuiMenu>
    </>
  );
};

export default NotificationDropdown;

const NotificationsChildren = ({ notifications }) => {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ px: 2, py: 1 }}
      >
        <Typography>Notifications</Typography>
        <Link
          component={RouterLink}
          to={"/notifications"}
          sx={{ fontSize: 14 }}
        >
          Show all
        </Link>
      </Stack>
      {notifications?.map((item) => {
        return (
          <Link
            component={RouterLink}
            to={
              item?.title === "New Application"
                ? `/applications/${item.invitationId}/${item.applicationId}`
                : item?.activeConversation
                  ? {
                      pathname: `/inbox/${item.activeConversation}`,
                      state: { activeConversation: item.activeConversation },
                    }
                  : `/invitation/${item?.invitationId}`
            }
            key={item?.id}
            sx={{
              px: 2,
              py: 1,
              color: "#000",
              textDecoration: "none",
              display: "block",
            }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 400, width: "100%" }}>
              {item?.title}
            </Typography>
            {!item?.userName && !item?.invitationName ? (
              <Typography sx={{ fontSize: 14, fontWeight: 100, width: "100%" }}>
                {item?.description}
              </Typography>
            ) : (
              <NotificationDescription
                title={item?.title}
                invitationName={item?.invitationName}
                userName={item?.userName}
                description={item?.description}
                sx={{ fontSize: 14, fontWeight: 100, width: "100%" }}
              />
            )}
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                width: "100%",
                color: "#8F8F8F",
              }}
            >
              {transformDate(item?.createdAt)}
            </Typography>
          </Link>
        );
      })}
    </Box>
  );
};
