import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Select,
  Box,
  IconButton
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OutlinedInput from '@mui/material/OutlinedInput';
import dayjs from 'dayjs';
import Dropdown from '../../components/UI/Dropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ViewWaitlistApplication from './ViewWaitlistApplication';
import ConfirmationDialog from './ConfirmationDialog';
import { useDeleteWaitingListApplicationMutation } from './api';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

const WaitingListTable = ({users, isLoading, onFilter}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [userType, setUserType] = useState([]);
  const [status, setStatus] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteApplication] = useDeleteWaitingListApplicationMutation();

  const onOpenModal = (u) => () => {
    setSelectedUser(u);
    setOpenModal(true);
  };

  const onChangeFilter = ({target: {value}}) => {
    setUserType(value);
    onFilter(value);
  };

  const onChangeStatus = ({target: {value}}) => {
    setStatus(value);
    onFilter(value, 'status');
  }

  const onDelete = () => {
    deleteApplication({id: openConfirmModal}).then(res => {
      if (res?.data) {
        setOpenConfirmModal(false);
        enqueueSnackbar(`Application from ${res?.data?.email} was deleted`, {
          variant: 'success',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon sx={{fontSize: '20px'}}/>
          </IconButton>
        });
      }
    });
  };

  const onOpenConfirmModal = (value) => () => {
    setOpenConfirmModal(value);
  };

  return (
    <>
      {isLoading && <Typography>Loading...</Typography>}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{'& > .MuiTableCell-root': { background: '#F9F5EF', textTransform: 'uppercase'}}}>
                <TableCell style={{ width: 126, minWidth: 126 }} sx={{p: 0}}>
                  <Select
                    name={'status'}
                    sx={{
                      background: 'transparent',
                      '& .MuiSelect-select': {
                        fontSize: 14,
                        fontWeight: 600,
                        '&[aria-expanded="true"]': {
                          background: '#0E1D31',
                        },
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      }
                    }}
                    multiple
                    autoWidth
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    value={userType}
                    onChange={onChangeFilter}
                    MenuProps={{ classes: { paper: "0px 3px 6px #00000029 !important" } }}
                    input={<OutlinedInput />}
                    renderValue={() => {
                      return <span>USER TYPE</span>;
                    }}
                  >
                    <SelectItem value={'host'} name={'host'} checked={userType.indexOf('host') > -1} />
                    <SelectItem value={'guest'} name={'guest'} checked={userType.indexOf('guest') > -1} />
                  </Select>
                </TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  EMAIL
                </TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  Full name
                </TableCell>
                <TableCell style={{ width: 126, minWidth: 126 }} sx={{p: 0}}>
                  <Select
                    name={'status'}
                    sx={{
                      background: 'transparent',
                      '& .MuiSelect-select': {
                        fontSize: 14,
                        fontWeight: 600,
                        '&[aria-expanded="true"]': {
                          background: '#0E1D31',
                        },
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      }
                    }}
                    multiple
                    autoWidth
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    value={status}
                    onChange={onChangeStatus}
                    MenuProps={{ classes: { paper: "0px 3px 6px #00000029 !important" } }}
                    input={<OutlinedInput />}
                    renderValue={() => {
                      return <span>Status</span>;
                    }}
                  >
                    <SelectItem value={'pending'} name={'pending'} checked={status.indexOf('pending') > -1} />
                    <SelectItem value={'deferred'} name={'deferred'} checked={status.indexOf('deferred') > -1} />
                    <SelectItem value={'approved'} name={'approved'} checked={status.indexOf('approved') > -1} />
                    <SelectItem value={'active'} name={'active'} checked={status.indexOf('active') > -1} />
                  </Select>
                </TableCell>
                <TableCell style={{ width: 126, minWidth: 126 }}>
                  JOINED
                </TableCell>
                <TableCell align={'center'} style={{ width: 100, minWidth: 100 }}>
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            {users?.length > 0 && !isLoading && (
              <TableBody>
              {users?.map(item => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={item._id}
                  onClick={onOpenModal(item)}
                  sx={{cursor: 'pointer', '& > .MuiTableCell-root': { fontSize: 15, fontWeight: 400, color: '#373632', p: 2, borderBottomColor: '#E1E1E1'}}}
                >
                  <TableCell>
                    {item.role}
                  </TableCell>
                  <TableCell>
                    {item.email}
                  </TableCell>
                  <TableCell>
                    {item.firstName} {item.lastName}
                  </TableCell>
                  <TableCell sx={{textTransform: 'uppercase'}}>
                    {item?.status}
                  </TableCell>
                  <TableCell>
                    {dayjs(item.createdAt).format("DD MMM YYYY")}
                  </TableCell>
                  <TableCell align={'center'}>
                    <Dropdown
                      dropdownItems={
                        <Box>
                          <MenuItem
                            onClick={onOpenModal(item)}
                            sx={{textTransform: 'uppercase', fontSize: 15}}>
                            View application
                          </MenuItem>
                          <MenuItem
                            onClick={onOpenConfirmModal(item?._id)}
                            sx={{textTransform: 'uppercase', fontSize: 15}}>
                            Delete application
                          </MenuItem>
                        </Box>
                      }
                    >
                      <MoreVertIcon/>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            )}
          </Table>
        </TableContainer>
      {users?.length === 0 && !isLoading && <Typography align={'center'} sx={{mt: 2}}>No users found</Typography>}
      <ViewWaitlistApplication open={openModal} onClose={() => setOpenModal(false)} userInfo={selectedUser}/>
      <ConfirmationDialog
        onConfirm={onDelete}
        open={Boolean(openConfirmModal)}
        onClose={() => setOpenConfirmModal(false)}
        title={'Do you want to delete this application?'}
      />
    </>
  );
};

export default WaitingListTable;

const SelectItem = ({value, name, checked, ...props}) => {
  return (
    <MenuItem
      sx={{
        minWidth: "126px",
        padding: "5px 10px !important",
        "& span": {
          fontSize: "12px",
          fontWeight: 600,
        },
        "& .MuiCheckbox-root": {
          padding: "0px !important",
          marginRight: "8px",
        },
      }}
      value={value}
      {...props}
    >
      <Checkbox
        sx={{'& .MuiSvgIcon-root': {fill: '#0E1D31'}}}
        disableRipple
        color="default"
        checked={checked}
      />
      <ListItemText
        sx={{textTransform: "uppercase", color: '#000'}}
        primary={name}
      />
    </MenuItem>
  )
};
