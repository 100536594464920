import React from "react";
import { withStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Typography, AccordionSummary, AccordionDetails, Accordion } from "@mui/material";
import { Link } from 'react-router-dom';

const styles = {
  accordion: {
    boxShadow: "unset !important",
    borderTop: "1px solid #0E1D31",
    borderRadius: "0px !important",
    padding: "0px 0px 0px 0px !important",
    "&.Mui-expanded": {
      margin: "0px 0px !important",
    },
    "&::before": {
      content: "none !important",
    },
    "&:last-child": {
      borderBottom: "1px solid #0E1D31",
    },
    "& .MuiAccordionSummary-expandIconWrapper svg": {
      fill: "#0E1D31",
    },
  },
  accordionHeader: {
    paddingLeft: "0px !important",
    "&:not(.Mui-expanded)": {
      margin: "25px 0px !important",
    },

    "& > .MuiAccordionSummary-content": {
      margin: "0px 0px !important",
    },
    "& p ": {
      color: "#0E1D31",
      fontSize: "17px",
      lineHeight: "24px",
      fontWeight: "600",
    },
  },
  accordionBody: {
    "& p, .MuiBox-root ": {
      color: "#373632",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "normal",
      textAlign: 'justify',
      "& a": {
        color: "#0E1D31 !important",
        textDecoration: "none !important",
      },
    },
  },
};
function AccordionCompTerms(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { classes } = props;

  return (
    <div>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel1" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            1. Acceptance of Terms of Use
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            The protection of personal data is important for us.
          </Typography>
          <br />
          <Typography>
            <strong>Binding contract.</strong> By creating an Invite.Club
            account, whether through a mobile device, mobile application or
            computer (collectively, the “Service”) you agree that you have read
            and agreed to be bound by these Terms and all other terms and
            conditions, operating rules, policies, and procedures that may be
            published from time to time through the Services by us or otherwise
            provided to you in connection with the Services, each of which is
            incorporated by reference into these Terms. If you do not accept and
            agree to be bound by all of Terms, please do not use the Service.
          </Typography>
          <br />
          <Typography>
            <strong>Applicability.</strong> These Terms apply to all Users of
            the Services, including, without limitation, users who are
            contributors of content, information, and other materials or
            services, registered or otherwise.
          </Typography>
          <br />
          <Typography>
            <strong>Modification.</strong> We reserve the right, in our sole and
            absolute discretion, to modify or replace any of these Terms, or
            change, suspend, or discontinue the Services (including without
            limitation, the availability of any feature, database, or content)
            at any time by posting a notice on the Site or by sending you notice
            through the Services, via email or by another appropriate means of
            electronic communication. We may also impose limits on certain
            features and services or restrict your access to parts or all of the
            Services without notice or liability. While we will timely provide
            notice of modifications, it is also your responsibility to check
            these Terms periodically for changes. Your continued use of the
            Services following notification of any changes to these Terms
            constitutes acceptance of those changes, which will apply to your
            use of the Services going forward. Your use of the Services is
            subject to the Terms in effect at the time of such use. If you don't
            agree to be bound by the modified Terms, then you are not permitted
            to use any of the Services anymore.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel2" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            2. Eligibility
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            <strong>Eligibility.</strong> You are not authorized to create an
            account or access or use the Service or systems it resides on,
            unless all of the following are true:
          </Typography>
          <Box>
            <ul>
              <li>you are at least 18 years of age. </li>
              <li>you can form a binding contract with Invite.Club,</li>
              <li>
                you will comply with these Terms and all applicable local,
                state, national and international laws, rules and regulations,
                and
              </li>
              <li>
                you have never been convicted of a felony or indictable offense
                (or crime of similar severity), a sex crime, or any crime
                involving violence, and that you are not required to register as
                a sex offender with any state, federal or local sex offender
                registry.
              </li>
            </ul>
          </Box>
          <br />
          <Typography>
            We may, in our sole discretion, refuse to offer the Services to any
            person or entity and change its eligibility criteria at any time.
            You are not eligible for using the Services if you violate any
            provision of these Terms as determined in our sole discretion.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel3" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            3. Registration
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            In order to use Invite.Club, you may sign in using your email. For
            more information regarding the information we collect from you and
            how we use it, please consult our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>.
          </Typography>
          <br />
          <Typography>
            If you want to use certain features of the Services, we may require
            you to register for an account on the Services (the "Account”).
          </Typography>
          <br />
          <Typography>
            You must provide accurate and complete information and keep your
            Account information updated. If you provide any information that is
            inaccurate, outdated or incomplete, or Invite.Club has reasonable
            grounds to suspect that such information is inaccurate, outdated or
            incomplete, Invite.Club may deny you to use the Services, areas
            requiring registration and/or suspend or terminate your Account, at
            its sole discretion.
          </Typography>
          <br />
          <Typography>
            You shall not: (i) select or use as a username a name of another
            person with the intent to impersonate that person; (ii) use as a
            username a name subject to any rights of a person other than you
            without appropriate authorization; or (iii) use, as a username, a
            name that is otherwise offensive, vulgar or obscene. You are solely
            responsible for the activity that occurs on your Account, and for
            keeping your Account password and login credentials secure. You may
            never use another person’s user Account or registration information
            for the Services without permission. You must notify us immediately
            of any change in your eligibility to use the Services (including any
            changes to or revocation of any licenses from state authorities),
            breach of security or unauthorized use of your Account. You should
            never publish, distribute or post login information for your
            Account. You shall have the ability to delete your Account, either
            directly or through a request made to one of our employees or
            affiliates.
          </Typography>
          <br />
          <Typography>
            You’ll have great fun on Invite.Club, but if you feel the need to
            leave, you can delete your Account at any time by going to the ‘User
            Profile’ page when you are logged in, ‘Edit’ user profile and
            clicking on the ‘Close account’. Your Account will be deleted
            immediately but it may take a little while for Your Content (defined
            below) to be completely removed from Invite.Club. Your profile
            information will be treated in accordance with our Privacy Policy.
            If you delete your Account and try to create a new account within
            this time period using the same credentials, we will re-activate
            your Account for you.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel4" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            4. Safety; Your Interactions with Other Members
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            Though Invite.Club strives to encourage a respectful member
            experience through features like the instant messaging after a
            successful connection that allows members to communicate only after
            they have both indicated interest in one another, Invite.Club is not
            responsible for the conduct of any member on or off of the Service.
            You agree to use caution in all interactions with other members,
            particularly if you decide to communicate off the Service or meet in
            person. You agree that you will not provide your financial
            information (for example, your credit card or bank account
            information), or wire or otherwise send money to other members.
          </Typography>
          <br />
          <Typography>
            YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS.
            YOU UNDERSTAND THAT INVITE.CLUB DOES NOT CONDUCT CRIMINAL BACKGROUND
            CHECKS ON ITS MEMBERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF
            ITS MEMBERS. INVITE.CLUB MAKES NO REPRESENTATIONS OR WARRANTIES AS
            TO THE CONDUCT OR COMPATIBILITY OF ANY MEMBERS.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel5" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            5. Content
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>• Definition</Typography>
          <br />
          <Typography>
            For purposes of these Terms, the term "Content" includes, without
            limitation, information, data, text, photographs, videos, audio
            clips, written posts and comments, software, scripts, graphics,
            works of authorship of any kind, interactive features and
            information or materials that are posted, generated, provided, or
            otherwise made accessible on or through the Services. For the
            purposes of the present, “Content” also includes all User Content
            (as defined below).
          </Typography>
          <br />
          <Typography>• Invite.Club’s Content – Use Rights</Typography>
          <br />
          <Typography>
            The Services may contain Content specifically provided by us, our
            partners or our users and such Content is protected by copyrights,
            trademarks, service marks, patents, trade secrets or other
            proprietary rights and laws. You shall abide by and maintain all
            copyright notices, information, and restrictions contained in any
            Content accessed through the Services.
          </Typography>
          <br />
          <Typography>
            Invite.Club grants you a personal, worldwide, royalty-free,
            non-assignable, nonexclusive, revocable, and non-sublicensable
            license to access and use the Service. This license is for the sole
            purpose of letting you use and enjoy the Service’s benefits as
            intended by Invite.Club and permitted by these Terms. Use,
            reproduction, modification, distribution or storage of any Content
            (other than your User Content) for other than purposes of using the
            Services is expressly prohibited without prior written permission
            from us. You shall not sell, license, rent, or otherwise use or
            exploit any Content (other than your User Content) for commercial
            use or in any way that violates any third party right.
          </Typography>
          <br />
          <Typography>
            This license and any authorization to access the Service are
            automatically revoked in the event that you do any of the following:
          </Typography>
          <br />
          <Box>
            <ul>
              <li>
                act in an unlawful or unprofessional manner including being
                dishonest, abusive or discriminatory
              </li>
              <li>
                misrepresent your identity, your age, your current or previous
                positions, qualifications or affiliations with a person or
                entity
              </li>
              <li>
                disclose information that you do not have the consent to
                disclose
              </li>
              <li> stalk or harass any other user of Invite.Club</li>
              <li>
                create or operate a pyramid scheme, fraud or other similar
                practice
              </li>
              <li>
                use the Service or any content contained in the Service for any
                commercial purposes without our written consent.
              </li>
              <li>
                copy, modify, transmit, create any derivative works from, make
                use of, or reproduce in any way any copyrighted material,
                images, trademarks, trade names, service marks, or other
                intellectual property, content or proprietary information
                accessible through the Service without Invite.Club’s prior
                written consent.
              </li>
              <li>
                express or imply that any statements you make are endorsed by
                Invite.Club.
              </li>
              <li>
                develop, support or use software, devices, scripts, robots,
                other types of mobile code or any other means or processes
                (including crawlers, browser plugins and add-on or other
                technology) to scrape or otherwise exfiltrate from Bumble or its
                services, or otherwise copy profiles and other data from the
                services.
              </li>
              <li>
                use the Service in any way that could interfere with, disrupt or
                negatively affect the Service or the servers or networks
                connected to the Service.
              </li>
              <li>
                upload viruses or other malicious code or otherwise compromise
                the security of the Service.
              </li>
              <li>
                forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any information transmitted to or through
                the Service.
              </li>
              <li>
                "frame" or "mirror" any part of the Service without
                Invite.Club’s prior written authorization.{" "}
              </li>
              <li>
                use meta tags or code or other devices containing any reference
                to Invite.Club or the Service (or any trademark, trade name,
                service mark, logo or slogan of Invite.Club) to direct any
                person to any other Site for any purpose.{" "}
              </li>
              <li>
                modify, adapt, sublicense, translate, sell, reverse engineer,
                decipher, decompile or otherwise disassemble any portion of the
                Service, or cause others to do so.
              </li>
              <li>
                use or develop any third-party applications that interact with
                the Service or other members' Content or information without our
                written consent.{" "}
              </li>
              <li>
                probe, scan or test the vulnerability of our Service or any
                system or network.{" "}
              </li>
              <li>
                encourage or promote any activity that violates these Terms.{" "}
              </li>
            </ul>
          </Box>
          <Typography>
            The names, logos, product and service names, designs, slogans, and
            other trademarks associated with the Services are ours and those of
            our licensors. You must not use any of the foregoing without our
            prior written permission. All other names, logos, product and
            service names, designs, slogans, and other trademarks used in
            connection with the Services are the trademarks of their respective
            owners.
          </Typography>
          <br />
          <Typography>
            Invite.Club may investigate and take any available legal action in
            response to illegal or unauthorized uses of the Service, including
            termination of your account.
          </Typography>
          <br />
          <Typography>
            Any software that we provide you may automatically download and
            install upgrades, updates, or other new features. You may be able to
            adjust these automatic downloads through your device's settings.
          </Typography>
          <br />
          <Typography>• User Content – Use Rights</Typography>
          <br />
          <Typography>
            All Content added, created, uploaded, submitted, distributed, or
            posted to the Site by users (the "User Content"), whether publicly
            posted or privately transmitted, is the sole responsibility of the
            person who originated such User Content. You represent that all User
            Content provided by you is accurate, complete, up-to-date, and in
            compliance with all applicable laws, rules and regulations. You
            retain ownership of any and all User Content created and/or uploaded
            by you and you have all rights that are necessary to grant us the
            license rights in your User Content under these Terms. You
            acknowledge that all Content, including User Content, accessed by
            you using the Services is at your own risk and you will be solely
            responsible for any damage or loss to you or any third party
            resulting therefrom. We do not guarantee that any Content you access
            on or through the Services is or will continue to be accurate. You
            also represent and warrant that neither your User Content, nor your
            use and provision of your User Content to be made available through
            the Services will infringe, misappropriate or violate a third
            party's intellectual property rights, or rights of publicity or
            privacy, or result in the violation of any applicable law or
            regulation.
          </Typography>
          <br />
          <Typography>
            You hereby grant to Invite.Club a non-exclusive, transferable,
            sublicenseable, worldwide, perpetual, royalty-free license to host,
            store, use, copy, display, reproduce, adapt, edit, publish, modify
            and distribute your User Content in connection with operating and
            providing the Services.
          </Typography>
          <br />
          <Typography>
            You can remove your User Content by requesting to us its deletion.
            However, in certain instances, some of your User Content may not be
            completely removed and copies of your User Content may continue to
            exist on the Invite.Club’s Services to the extent permitted by law.
            We are not responsible or liable for the removal or deletion of (or
            the failure to remove or delete) any of your User Content. You are
            responsible in constantly checking and validating the Content
            related to you.
          </Typography>
          <br />
          <Typography>• Third Party’s Content</Typography>
          <br />
          <Typography>
            We may display Content that is owned by a third party or licensed to
            us by a third party ("Third-Party Content"). Invite.Club does not
            claim any ownership rights in the Third-Party Content. We provide
            the Third-Party Content only as a convenience and you shall not
            publish or distribute any of the Third-Party Content. Our provision
            of the Third-Party Content does not mean that we have endorsed the
            third parties or the Third-Party Content that they have provided.
            Your use of the Third-Party Content is at your own risk and
            Invite.Club disclaims all liability relating to your interaction
            with the Third-Party Content or the applicable third parties.
            Invite.Club may share personally identifiable information you
            provide to us via the Services and financial and other information
            we have about you with third-parties so that the third parties may
            personalize the Third-Party Content that they provide to you
            according to the Privacy Policy. We will comply with applicable
            legal requirements, including requirements to obtain your consent
            before we share such information with third parties, in connection
            with how we share this information. If you see Content on the
            Service that violates these Terms, please report it via our contact
            form.
          </Typography>
          <br />
          <Typography>• Availability of Content</Typography>
          <br />
          <Typography>
            We do not guarantee that any Content will be made available on the
            Site or through the Services. We reserve the right to, but do not
            have any obligation to remove, edit, block, modify or otherwise
            manipulate any Content in our sole discretion, at any time, without
            notice to you and for any reason (including, but not limited to,
            upon receipt of claims or allegations from third parties or
            authorities relating to such Content or if we are concerned that you
            may have violated these Terms), or for no reason at all.
          </Typography>
          <br />
          <Typography>• Rules of Conduct</Typography>
          <br />
          <Typography>
            a. As a condition of use, you promise not to use the Services for
            any purpose that is prohibited by these Terms. You are responsible
            for all of your activity in connection with the Services. Violation
            of our rules may result in the termination and cancellation of your
            Account. You acknowledge and agree that we may terminate any Account
            at any time for any reason (including, but not limited to, our
            independent assessment or the receipt of claims or allegations from
            third parties or authorities).
          </Typography>
          <br />
          <Typography>
            b. By way of example, and not as a limitation, you shall not (and
            shall not permit any third party to) either (a) take any action or
            (b) upload, download, post, submit or otherwise distribute or
            facilitate distribution of any Content on or through the Service,
            including without limitation any User Content, that:
          </Typography>
          <br />
          <Typography>
            i. infringes any patent, trademark, trade secret, copyright, right
            of publicity or other right of any other person or entity or
            violates any law or contractual duty;
          </Typography>
          <br />
          <Typography>
            ii. uses the Services for any unauthorized purpose, or in violation
            of any applicable law, including intellectual property laws;
          </Typography>
          <br />
          <Typography>
            iii. uses screen reader technology, algorithms, or any other
            automated technological means to interpret, analyze, research, or
            gain information about any User Content which is not yours;
          </Typography>
          <br />
          <Typography>
            iv. you know is false, misleading, untruthful or inaccurate,
            including, but not limited to, providing inaccurate contact or
            Account information;
          </Typography>
          <br />
          <Typography>
            v. results in the creation or operation of multiple user accounts;
          </Typography>
          <br />
          <Typography>
            vi.is unlawful, threatening, abusive, harassing, defamatory,
            libelous, deceptive, fraudulent, invasive of another's privacy,
            tortious, obscene, vulgar, pornographic, offensive, profane,
            contains or depicts nudity, contains or depicts sexual activity,
            otherwise violates any law or right of any third party, or is
            otherwise inappropriate as determined by us in our sole discretion;
          </Typography>
          <br />
          <Typography>
            vii. constitutes unauthorized or unsolicited advertising, junk or
            bulk email ("spamming");
          </Typography>
          <br />
          <Typography>
            viii. contains software viruses or any other computer codes, files,
            content, or programs that are designed or intended to disrupt,
            damage, limit or interfere with the proper function of any software,
            hardware, or telecommunications equipment or to damage or obtain
            unauthorized access to any system, data, password or other
            information of ours or of any third party;
          </Typography>
          <br />
          <Typography>
            ix. is designed or intended to obtain password, Account, or private
            information from any User;
          </Typography>
          <br />
          <Typography>
            x. impersonates any person or entity, including any of our
            employees, representatives, or users;
          </Typography>
          <br />
          <Typography>
            xi. includes anyone’s identification documents or sensitive
            financial information.
          </Typography>
          <br />
          <Typography>
            xii. includes any confidential information, violates the rights
            (including, without limitation, the rights of publicity and privacy
            and rights under a contract) of others, or otherwise contains any
            material that could give rise to any civil or criminal liability
            under applicable laws or regulations, or that otherwise may be in
            conflict with these Terms.
          </Typography>
          <br />
          <Typography>
            c. You shall not: (i) take any action that imposes or may impose (as
            determined by us in our sole discretion) an unreasonable or
            disproportionately large load on our (or our third party providers’)
            infrastructure; (ii) interfere or attempt to interfere with the
            proper working of the Services or any activities conducted on the
            Services; (iii) bypass, circumvent or attempt to bypass or
            circumvent any measures we may use to prevent or restrict access to
            the Services (or other accounts, computer systems or networks
            connected to the Services); (iv) run any form of auto-responder or
            "spam" on the Services; (v) use manual or automated software,
            devices, or other processes to “crawl” or “spider” any page of the
            Site without our express written permission; (vi) harvest or scrape
            any Content from the Services; or (vii) otherwise take any action in
            violation of our guidelines and policies (viii)use the Service for
            any purpose that is illegal or prohibited by this Agreement, (ix)
            use the Service for any harmful or nefarious purpose or (x) use the
            Service in order to damage Invite.Club, (xi) spam, solicit money
            from or defraud any Members, (xii), impersonate any person or entity
            or post any images of another person without his or her permission,
            (xiii) bully, "stalk", intimidate, assault, harass, mistreat or
            defame any person.
          </Typography>
          <br />
          <Typography>
            d. You shall not (directly or indirectly): (i) decipher, decompile,
            disassemble or otherwise attempt to derive any source code or
            underlying ideas or algorithms of any part of the Services
            (including without limitation any application), (ii) modify,
            translate, or otherwise create derivative works of any part of the
            Services, or (iii) copy, rent, lease, distribute, or otherwise
            transfer any of the rights that you receive hereunder. You shall
            abide by all applicable local, state, national and international
            laws and regulations.
          </Typography>
          <br />
          <Typography>
            e. You shall not recruit, solicit, or encourage any other User to
            use third party services or Sites that are competitive to ours;
          </Typography>
          <br />
          <Typography>
            f. We also reserve the right to access, read, preserve and disclose
            any information as we reasonably believe is necessary to (i) satisfy
            any applicable law, regulation, legal process or governmental
            request, (ii) enforce these Terms, including investigation of
            potential violations hereof, (iii) detect, prevent, or otherwise
            address fraud, security or technical issues, (iv) respond to user
            support requests, or (v) protect the rights, property or safety of
            us, our users and the public.
          </Typography>
          <br />
          <Typography>
            g. If for any reason the Service is not running as originally
            planned (e.g., if it becomes corrupted or does not allow the proper
            usage and processing of entries in accordance with the rules, or if
            infection by a computer virus, bugs, tampering, unauthorized
            intervention, actions by entrants, fraud, technical failures, or any
            other causes of any kind, in the sole opinion of Invite.Club
            corrupts or affects the administration, security, fairness,
            integrity or proper conduct of the Service), Invite.Club reserves
            the right, in its sole discretion, to disqualify any individual
            implicated in or relating to the cause and/or to cancel, terminate,
            extend, modify or suspend the Service.
          </Typography>
          <br />
          <Typography>
            In consideration for Invite.Club allowing you to use the Service,
            you agree that we, our affiliates, and our third-party partners may
            place advertising on the Service. By submitting suggestions or
            feedback to Invite.Club regarding our Service, you agree that
            Invite.Club may use and share such feedback for any purpose without
            compensating you.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel6" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            6. Payment processing
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            To process your information as described in this Privacy Policy, we
            rely on the following legal bases:
          </Typography>
          <br />
          <Typography>
            <strong> Generally. </strong>From time to time, Invite.Club may
            offer products and services for purchase ("in app purchases")
            through carrier billing, Invite.Club direct billing or other payment
            platforms authorized by Invite.Club. If you choose to make a
            purchase, you will be prompted to confirm your purchase with the
            applicable payment provider, and your method of payment will be
            charged at the prices displayed to you for the service(s) you've
            selected as well as any sales or similar taxes that may be imposed
            on your payments, and you authorize Invite.Club or the third party
            account, as applicable, to charge you.
          </Typography>
          <br />
          <Typography>
            <strong>Refunds.</strong> Generally, all charges for purchases are
            nonrefundable, and there are no refunds or credits. We may make an
            exception in the following cases:
          </Typography>
          <br />
          <Box>
            <ul>
              <li>
                If a Guest cancels their approved application and no replacement
                is found after the deadline of the invitation plus three days,
                the acceptance fee for that Guest is returned to the Host.
              </li>
            </ul>
          </Box>
          <br />
          <Typography>
            We shall make such a refund using the same means of payment as used
            by you in the initial transaction. In any case, no fees will be
            charged to you as a result of the refund.
          </Typography>
          <br />
          <Typography>
            <strong>Pricing.</strong> Invite.Club operates a global business,
            and our pricing varies by a number of factors. We frequently offer
            promotional rates - which can vary based on region and more. We also
            regularly test new features and payment options.{" "}
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel7" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            7. Notice and Procedure for Making Claims of Copyright Infringement
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            If you believe that your work has been copied and posted on the
            Service in a way that constitutes copyright infringement, please
            contact us through our contact us form
          </Typography>
          <br />
          <Typography>
            If you contact us regarding alleged copyright infringement, please
            be sure to include the following information:
          </Typography>
          <br />
          <Box>
            <ul>
              <li>
                an electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright interest;
              </li>
              <li>
                a description of the copyrighted work that you claim has been
                infringed;
              </li>
              <li>
                a description of where the material that you claim is infringing
                is located on the Service (and such description must be
                reasonably sufficient to enable us to find the alleged
                infringing material);
              </li>
              <li>
                your contact information, including address, telephone number
                and email address and the copyright owner’s identity;
              </li>
              <li>
                a written statement by you that you have a good faith belief
                that the disputed use is not authorized by the copyright owner,
                its agent, or the law; and
              </li>
              <li>
                a statement by you, made under penalty of perjury, that the
                above information in your notice is accurate and that you are
                the copyright owner or authorized to act on the copyright
                owner's behalf.
              </li>
            </ul>
          </Box>
          <br />
          <Typography>
            Invite.Club will terminate the accounts of repeat infringers.
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel8" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            8. Third Party Services
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            The Services may permit you to link to or otherwise access other
            Sites, services or resources on your device and the Internet, and
            other Sites, services or resources may contain links to or be
            accessed by the Services or the Site (including, without limitation,
            sites and services to synchronize video to music). These other
            resources are not under our control, and you acknowledge that we are
            not responsible or liable for the content, functions, accuracy,
            legality, appropriateness or any other aspect of such Sites or
            resources. The inclusion of any such link or access does not imply
            our endorsement or any association between us and their operators.
            You further acknowledge and agree that we shall not be responsible
            or liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with the use of or reliance
            on any such content, goods or services available on or through any
            such Site or resource.
          </Typography>
          <br />
          <Typography>
            The Site may include Google Maps features and content which are
            subject to the then-current version of the Google Maps Terms of Use
            and Privacy Policy.
          </Typography>
          <br />
          <Typography>
            <strong>Location-Based Services.</strong> We may offer features that
            are based on the location of users and which may report on the
            locations of those users as they use the Services (the
            "Location-Based Services"). You may partake in using these
            Location-Based Services solely at your own discretion and may opt
            out of providing such information by turning off those features.
            Should you use Location-Based Services, you are consenting to our
            collection and dissemination of your location information through
            the Services. Under no circumstances shall we be liable for claims
            or damages therefrom arising out of your informed decision to
            disseminate your location information through the Service.
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel9" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            9. Risk Assumption and Precautions
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            YOUR USE OF OUR SERVICES, OUR CONTENT, OR OTHER CONTENT AVAILABLE
            THROUGH OUR SERVICES, IS AT YOUR OWN DISCRETION AND RISK. YOU ASSUME
            ALL RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF OUR
            SERVICES, OUR CONTENT, OR OTHER CONTENT AVAILABLE THROUGH OUR
            SERVICES. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOU ΟR YOUR
            PROPERTY (INCLUDING ANY COMPUTER SYSTEM OR MOBILE DEVICE USED IN
            CONNECTION WITH OUR SERVICES), OR THE LOSS OF DATA THAT MAY RESULT
            FROM THE USE OF OUR SERVICES, OUR CONTENT, OR OTHER CONTENT
            AVAILABLE THROUGH OUR SERVICES.
          </Typography>
          <br />
          <Typography>
            YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
            LAW, THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND
            ANY CONTACT YOU HAVE WITH OTHER MEMBERS, WHETHER IN PERSON OR
            ONLINE, REMAINS WITH YOU.
          </Typography>
          <br />
          <Typography>
            ADDITIONALLY, YOUR ACCEPTANCE OF AN INVITATION AND YOUR JOINING OF
            ANY OTHER MEMBER FOR HOLIDAYS IS AT YOUR OWN DISCRETION AND RISK.
            YOU ASSUME ANY AND ALL RISK FOR ANY PROPERTY DAMAGE OR LOSS, OR
            BODILY, MENTAL, OR PERSONAL INJURY, INCLUDING DEATH, RELATING TO OR
            ARISING FROM CONNECTING WITH OTHER MEMBERS.
          </Typography>
          <br />
          <Typography>
            You also understand that Invite.Club makes no guarantees, either
            express or implied, regarding your ultimate compatibility with
            individuals you meet through the Service or as to the conduct of
            such individuals.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel10" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            10. Disclaimers
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>• Services</Typography>
          <br />
          <Typography>
            INVITE.CLUB PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE”
            BASIS AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, GRANTS NO
            WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR
            OTHERWISE WITH RESPECT TO THE SERVICE (INCLUDING ALL CONTENT
            CONTAINED THEREIN). INVITE.CLUB DOES NOT REPRESENT OR WARRANT THAT
            (A) THE SERVICE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE, (B) ANY
            DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED, OR (C) THAT ANY
            CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICE WILL BE
            ACCURATE.
          </Typography>
          <br />
          <Typography>
            Invite.Club cannot guarantee and does not promise any specific
            results from use of the Site and/or the Services.
          </Typography>
          <br />
          <Typography>
            Invite.Club is not responsible for any error, omission,
            interruption, deletion, defect, delay in operation or transmission,
            communications, line failure, theft or destruction or unauthorized
            access to, or alteration of, user or Member communications.
          </Typography>
          <br />
          <Typography>
            Invite.Club is not responsible for any problems or technical
            malfunction of any telephone network or lines, computer online
            systems, servers or providers, computer equipment, software, failure
            of email or players on account of technical problems or traffic
            congestion on the Internet or at any Site or combination thereof,
            including injury or damage to users and/or Members or to any other
            person’s computer related to or resulting from participating or
            downloading materials in connection with the Site and/or in
            connection with the Service.
          </Typography>
          <br />
          <Typography>• Content</Typography>
          <br />
          <Typography>
            INVITE.CLUB TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR
            ANOTHER MEMBER OR THIRD PARTY POSTS, SENDS OR RECEIVES THROUGH THE
            SERVICE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
            USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK.
          </Typography>
          <br />
          <Typography>
            Invite.Club is not responsible for any incorrect or inaccurate
            Content posted on the Site or in connection with the Service,
            whether caused by users of the Site, Members, or by any of the
            equipment or programming associated with or utilized in the Service.
          </Typography>
          <br />
          <Typography>
            This Site is only a venue—it acts as a venue for individuals to find
            compatible companionship for holidays. Invite.Club is not required
            to screen or censor information posted on the Site, including but
            not limited to, the identity of any user and any user content. We
            are not involved in any actual communication between Members. As a
            result, we have no control over the quality, safety, or legality of
            the information or profiles or the truth or accuracy of such
            information. You agree that you are solely responsible for your
            interactions with other Members and that Invite.Club is not liable
            in any way for Member communications. Invite.Club reserves the
            right, but has no obligation, to monitor communications on the Site
            between you and other Members, or any other correspondence or
            interaction that occurs outside the scope of the Site. The Site and
            Service is a tool for providing the initial contact between Members,
            and anything beyond that is not in our control and is carried out at
            the Members’ own risk. Members must use common sense about what
            information they reveal to others via email or any other means of
            communication. It is your responsibility to ensure your safety and
            to investigate Members and verify they are legitimate holiday
            partner seekers. There are many different frauds, schemes, and
            deceptions on the Internet, and we strongly caution you to be
            skeptical of any Member until you learn more about them and verify
            their background.
          </Typography>
          <br />
          <Typography>
            In addition to the preceding information under these Terms, any
            advice or thoughts that may be posted on the Site or through the
            Service provided for under these Terms are for informational and
            entertainment purposes only and are not intended to replace, or
            substitute for, any professional, financial, medical, legal or other
            advice. The Company makes no representations or warranties and
            expressly disclaims any and all liability concerning any treatment,
            action by, or effect on any person following the information offered
            or provided within or through the Site. If You have specific
            concerns or a situation arises in which You require professional or
            medical advice, You should consult with an appropriately trained and
            qualified specialist and seek immediate attention of the proper
            professional.
          </Typography>
          <br />
          <Typography>
            You specifically acknowledge and agree that Invite.Club is not
            liable for your defamatory, offensive, infringing or illegal
            materials or conduct or that of third parties, and we reserve the
            right to remove such materials from the Site without liability or
            notice to You.
          </Typography>
          <br />
          <Box>
            The Site may be used only for lawful purposes by individuals. As one
            of the conditions of your use of the Site and the Service, you
            represent, warrant, and agree that You will not use (or plan,
            encourage or help others to use) the Site or the Service for any
            purpose or in any manner that is prohibited by these Terms or by
            applicable law. It is your responsibility to ensure that your use of
            the Site and the Service complies with these Terms, and to seek
            prior written consent from Invite.Club for any uses not permitted or
            not expressly specified herein. Invite.Club specifically prohibits
            any use of the Site or the Service, and You hereby agree not to use
            the Site or the Service, for any of the following:
            <br />
            <ul>
              <li>
                Using the Site as an escort or prostitute or using the Service
                to promote, solicit, or engage clients for an escort or
                prostitution service, or to engage or facilitate human
                trafficking of any kind, including past escort activities or
                affiliation with an escort site or service.
              </li>
              <li>
                Posting or sending material that exploits people under the age
                of 18, soliciting personal information from anyone under 18,
                failing to report knowledge of a person under the age of 18, or
                continuing to use the site to interact in any way with anyone
                you know or believe is under the age of 18. Any violation of
                these prohibitions will result in termination of your Membership
                and possible referral to law enforcement.
              </li>
              <li>
                Posting or providing in any way any incomplete, false,
                misleading or inaccurate Content about yourself and/or your
                profile.
              </li>
              <li>
                Impersonating or otherwise misrepresenting an affiliation,
                connection or association with any person or entity.
              </li>
              <li>
                Soliciting, from other users, passwords or personal identifying
                information for commercial or unlawful purposes, or for any
                other reason that would constitute a violation of these Terms.
              </li>
              <li>
                Engaging in advertising to, or solicitation of, other Members to
                send money, or buy or sell any products or services. You may not
                transmit any chain letters or junk/spam email to other Members.
                If You breach the terms of this subsection and send or post
                unsolicited bulk email, spam, or other unsolicited
                communications of any kind through the Service, you acknowledge
                that you will have caused substantial harm to Invite.Club.
              </li>
              <li>
                Initiating contact with Members off site by any other means
                without first obtaining explicit permission from them to do so
                via the Site;
              </li>
              <li>
                Posting advertisements or solicitations of employment, business
                or pyramid schemes.
              </li>
              <li>
                Using the Site or Service for activities that violate any law,
                statute, ordinance or regulations.
              </li>
              <li>
                Using the Site or Service to encourage, promote, facilitate or
                instruct others to engage in illegal activity.
              </li>
              <li>
                Engage in any activity that violates criminal laws of the
                jurisdiction in which the Member resides, visits or contacts
                Members.
              </li>
              <li>
                Bullying, stalking, intimidating or otherwise harassing any
                other Members or Company employees or representatives.
              </li>
              <li>
                Framing or mirroring any part of the Service or the Site,
                without the Company's prior written authorization.
              </li>
              <li>
                Using the Site for any competitive purpose, including copying,
                soliciting, competitive or market analysis or any other use by a
                competitor.
              </li>
            </ul>
          </Box>
          <br />
          <Typography>• Third party content and conduct</Typography>
          <br />
          <Typography>
            WE MAKE NO WARRANTY AS TO THE IDENTITY, CHARACTER OR CONDUCT OF ANY
            MEMBERS AND ASSUME NO RESONSIBILITY FOR A MEMBER’S COMPLIANCE WITH
            ANY APPLICABLE LAWS AND REGULATIONS. YOU AGREE TO TAKE REASONABLE
            PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER
            MEMBERS WITH WHOM YOU COMMUNICATE OR INTERACT, AS A RESULT OF YOUR
            USE OF THE SERVICES - PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR
            IN PERSON. INVITE.CLUB EXPLICITLY DISCLAIMS ALL LIABILITY FOR ANY
            ACT OR OMISSION OF ANY MEMBER OR OTHER THIRD PARTY.
          </Typography>
          <br />
          <Typography>
            WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR
            ANY CONTENT, OR SERVICE ADVERTISED, CREATED OR OFFERED BY A THIRD
            PARTY THROUGH THE SERVICES OR ANY HYPERLINKED SITE OR FEATURED IN
            ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN
            ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU
            AND THIRD PARTY PROVIDERS CONTENT, OR SERVICES, UNLESS TO THE EXTENT
            REQUIRED TO PROVIDE OUR SERVICES.{" "}
          </Typography>
          <br />
          <Typography>
            Invite.Club is not responsible for user or Member conduct of any
            kind, whether online or offline.
          </Typography>
          <br />
          <Typography>
            You agree to, and hereby do, release Invite.Club and its successors
            from any claims, demands, losses, damages, rights, and actions of
            any kind, including personal injuries, death and property damage,
            that either directly or indirectly arises from your interactions
            with or conduct of other Members.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel11"}
        onChange={handleChange("panel11")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel11" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            11. Limitation of Liability
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
            INVITE.CLUB, ITS AFFILIATES, EMPLOYEES, LICENSORS OR SERVICE
            PROVIDERS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
            INCIDENTAL, SPECIAL, PUNITIVE, OR ENHANCED DAMAGES, INCLUDING,
            WITHOUT LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR
            INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE
            LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO
            ACCESS OR USE THE SERVICE, (II) THE CONDUCT OR CONTENT OF OTHER
            MEMBERS OR THIRD PARTIES ON, THROUGH, OR FOLLOWING USE OF THE
            SERVICE; OR (III) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
            CONTENT, EVEN IF INVITE.CLUB HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. IN NO EVENT WILL INVITE.CLUB’S AGGREGATE LIABILITY TO
            YOU FOR ALL CLAIMS RELATING TO THE SERVICE EXCEED THE GREATER OF THE
            AMOUNT PAID, IF ANY, BY YOU TO INVITE.CLUB FOR THE SERVICE.
          </Typography>
          <br />
          <Typography>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
            CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN
            THIS SECTION MAY NOT APPLY TO YOU.
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel12"}
        onChange={handleChange("panel12")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel12" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            12. Indemnity by You
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            You shall indemnify, defend and hold harmless the Company, its
            affiliates and their respective officers, directors, employees and
            agents, from and against any and all claims, disputes, demands,
            liabilities, damages, losses, and costs and expenses, including,
            without limitation, reasonable legal and accounting fees arising out
            of or in any way connected with the following, to the extent that
            they have not be caused based on our willful misconduct or gross
            negligence: (i) your access to, use or misuse of the Services,
            Content (ii) your User Content; your violation of these Terms; (iv)
            your non-compliance with any applicable law; or (v) your breach of
            your representation and warranties hereunder, (vi) your breach of
            any contract or other agreement you enter into in relation to our
            Services or through our Site, (vi) personal injury, property damage
            or other claims or damages, (vii) your interactions or transactions
            with any other user/members; (viii) infringement by you, or any
            third party using your Account or identity in connection with the
            Services, of any intellectual property or other right of any person
            or entity; or (x) any payments due and payable by you to us or any
            third party in connection with the Services.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel13"}
        onChange={handleChange("panel13")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel13" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            13. Governing Law
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            These Terms shall be governed by and construed in accordance with
            the laws of Cyprus. You agree that any dispute arising from or
            relating to the subject matter of these Terms shall be governed by
            the exclusive jurisdiction of the courts of Nicosia, Cyprus.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel14"}
        onChange={handleChange("panel14")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel14" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            14. Entire Agreement; Other
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            These Terms, which include the Privacy Policy and the Cookies Policy
            and any terms disclosed and agreed to by you if you purchase
            additional features, products or services we offer on the Service,
            contains the entire agreement between you and Invite.Club regarding
            the use of the Service. If any provision of these Terms is held
            invalid, the remainder of these Terms shall continue in full force
            and effect. The failure of the Company to exercise or enforce any
            right or provision of these Terms shall not constitute a waiver of
            such right or provision. You agree that your Invite.Club account is
            non-transferable and all of your rights to your account and its
            Content terminate upon your death. No agency, partnership, joint
            venture, fiduciary or other special relationship or employment is
            created as a result of these Terms and you may not make any
            representations on behalf of or bind Invite.Club in any manner.
          </Typography>
          <br />
          <Typography>
            If you have any questions, complaints or claims about these Terms or
            the Services, you may contact us through contact@inviteclub.com
            <br />
            Effective Date of Terms: 09/01/2022
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default withStyles(styles)(AccordionCompTerms);
