import PageWrapper from "../../components/PageWrapper";
import React, { useEffect, useMemo, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  Tooltip,
  useMediaQuery,
  DialogContent,
  Dialog,
} from "@mui/material";
import ScrollableSidebar from "../../components/ScrollableSidebar";
import { Button } from "../../components/UI/Button";
import MapsUgcRoundedIcon from "@mui/icons-material/MapsUgcRounded";
import Input from "../../components/UI/Input";
import { useConversationQuery, useConversationsQuery } from "./api";
import { useSelector } from "react-redux";
import { transformDate } from "../../util/utils";
import ChatBox from "./ChatBox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreateConversation from "./CreateConversation";
import { theme } from "../../services/Theme";
import ModalTitle from "../../components/UI/DialogTitle";
import { useHistory } from "react-router-dom";
import NoItemsBox from "../../components/NoItemsBox";
import useFullPageHeight from "../../util/useFullPageHeight";

const Inbox = () => {
  const [fullScreen, setFullScreen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedConversation, setSelectedConversation] = useState(null);
  const { user } = useSelector((store) => store.user);
  const [conversations, setConversations] = useState([]);
  const {
    data: dataConversations,
    isLoading,
    refetch: refetchConversations,
  } = useConversationsQuery({});
  const { refetch: refetchChat } = useConversationQuery(selectedConversation, {
    skip: !selectedConversation,
  });
  const [newConversation, setNewConversation] = useState(false);
  const [openChatModal, setOpenChatModal] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const showChatView =
    history.location.state?.activeUser ||
    Boolean(dataConversations?.length) ||
    newConversation;
  const height = useFullPageHeight();
  const activeConversation = useMemo(
    () =>
      dataConversations?.find(
        (item) =>
          item?.users?.length === 2 &&
          item?.users?.find(
            (user) => user?.id === history?.location.state?.activeUser?.id,
          ),
      ),
    [dataConversations, history.location.state?.activeUser?.id],
  );

  useEffect(() => {
    if (dataConversations) {
      setConversations(dataConversations);
    }
    if (
      dataConversations &&
      !selectedConversation &&
      !isMobile &&
      !history.location.state?.activeUser
    ) {
      setSelectedConversation(dataConversations[0]?.id);
    }
  }, [dataConversations]); //eslint-disable-line

  useEffect(() => {
    if (selectedConversation) {
      refetchChat();
      refetchConversations();
    }
  }, [selectedConversation]); //eslint-disable-line

  useEffect(() => {
    if (
      history.location.state?.activeUser &&
      !activeConversation &&
      !isLoading
    ) {
      setNewConversation(true);
      if (isMobile) {
        setOpenChatModal(true);
      }
    }
    if (
      history.location.state?.activeUser &&
      activeConversation &&
      !isLoading
    ) {
      setSelectedConversation(activeConversation.id);
      if (isMobile) {
        setOpenChatModal(true);
      }
    }
  }, [history.location.state?.activeUser, activeConversation, isLoading]); //eslint-disable-line

  const handleSearch = ({ target: { value } }) => {
    if (!value) {
      resetSearch();
      return;
    }
    setSearchValue(value);
    setConversations(
      dataConversations.filter((item) =>
        item.users.some((user) =>
          user?.username.toLowerCase().includes(value.toLowerCase()),
        ),
      ),
    );
    setSelectedConversation(null);
  };

  const resetSearch = () => {
    setSearchValue("");
    setConversations(dataConversations);
    setSelectedConversation(dataConversations[0]?.id);
  };

  const onSelectConversation = (id) => () => {
    setSelectedConversation(id);
    setNewConversation(false);
    if (isMobile) {
      setOpenChatModal(true);
    }
    if (history.location.state?.activeUser) {
      history.replace({ state: {} });
    }
  };

  const onCreatingNewConversation = (id) => {
    setNewConversation(false);
    if (!isMobile) {
      setSelectedConversation(id);
    }
  };

  const onCloseModal = () => {
    setOpenChatModal(false);
    setSelectedConversation(null);
  };

  return (
    <PageWrapper sx={{ overflowY: "hidden", height: `calc(${height} - 45px)` }}>
      <Stack direction={"row"} sx={{ height: "100%" }}>
        <Box
          sx={{
            width: { xs: "100%", md: showChatView ? "30%" : "100%" },
            height: "100%",
            display: fullScreen ? "none" : "flex",
            flexDirection: "column",
            position: "relative",
            "&:after": {
              content: '""',
              position: "absolute",
              top: -30,
              right: 0,
              width: "1px",
              height: "calc(100% + 60px)",
              backgroundColor:
                dataConversations?.length > 0 && !isLoading
                  ? "#0E1D31"
                  : "transparent",
              display: { xs: "none", md: "block" },
            },
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ mb: 3, pr: { xs: 0, md: 4 } }}
          >
            <Typography sx={{ fontSize: 18, fontWeight: 400 }}>
              Inbox
            </Typography>
            {user?.role === "admin" && (
              <Button
                variant={"outlined"}
                startIcon={<MapsUgcRoundedIcon />}
                sx={{ height: 32 }}
                onClick={() => setNewConversation(true)}
              >
                New message
              </Button>
            )}
          </Stack>
          {showChatView && !isLoading && (
            <>
              {conversations?.length > 0 && (
                <>
                  <Input
                    sx={{ mb: 3, pr: { xs: 0, md: 4 } }}
                    value={searchValue}
                    placeholder={"Search user"}
                    onChange={handleSearch}
                  />
                  <ScrollableSidebar
                    sx={{ pr: { xs: 0, md: 2 }, mr: { xs: 0, md: 1.5 } }}
                  >
                    {conversations?.map((conversation) => (
                      <ConversationItem
                        key={conversation.id}
                        conversation={conversation}
                        onConversationClick={onSelectConversation}
                        selectedConversation={
                          selectedConversation === conversation.id &&
                          !newConversation
                        }
                      />
                    ))}
                  </ScrollableSidebar>
                </>
              )}
              {conversations?.length === 0 && dataConversations?.length > 0 && (
                <Stack
                  direction={"column"}
                  spacing={2}
                  alignItems={"center"}
                  sx={{ pr: { xs: 0, md: 4 } }}
                >
                  <Typography align={"center"}>
                    Search has no results
                  </Typography>
                  <Button
                    variant={"contained"}
                    onClick={resetSearch}
                    sx={{ height: 32 }}
                  >
                    Reset search
                  </Button>
                </Stack>
              )}
            </>
          )}
          {dataConversations?.length === 0 && !showChatView && !isLoading && (
            <NoItemsBox
              title={"It's quiet for now.."}
              description={
                "Looks like the perfect moment to plan your next big event!"
              }
              buttonText={"Plan a New event"}
            />
          )}
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: showChatView ? "block" : "none" },
            width: fullScreen ? "100%" : "70%",
            position: "relative",
            height: "100%",
            pl: fullScreen ? 0 : 3,
            overflowY: "auto",
          }}
        >
          {selectedConversation && !newConversation && (
            <ChatBox
              isFullScreen={fullScreen}
              onChangeScreenSize={() => setFullScreen((state) => !state)}
              onChangeConversation={() => setSelectedConversation(null)}
            />
          )}
          {dataConversations?.length > 0 &&
            !selectedConversation &&
            !newConversation &&
            !isLoading && (
              <Typography>Please select the conversation</Typography>
            )}
          {newConversation && !isLoading && (
            <CreateConversation
              conversations={dataConversations}
              onSuccess={onCreatingNewConversation}
              onChangeConversation={onSelectConversation}
              activeValue={history.location.state?.activeUser}
            />
          )}
        </Box>
      </Stack>
      <Dialog
        fullScreen
        open={openChatModal && isMobile}
        onClose={onCloseModal}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: 1300,
            width: "100%",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "#b0b0b04d",
          },
        }}
      >
        <ModalTitle onClose={onCloseModal} />
        <DialogContent sx={{ p: 3, mt: 3 }}>
          <ChatBox onChangeConversation={() => setSelectedConversation(null)} />
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={newConversation && isMobile}
        onClose={() => setNewConversation(false)}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: 1300,
            width: "100%",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "#b0b0b04d",
          },
        }}
      >
        <ModalTitle onClose={() => setNewConversation(false)} />
        <DialogContent sx={{ p: 3, mt: 3 }}>
          <CreateConversation
            conversations={dataConversations}
            onSuccess={onCreatingNewConversation}
            onChangeConversation={onSelectConversation}
            activeValue={history.location.state?.activeUser}
          />
        </DialogContent>
      </Dialog>
    </PageWrapper>
  );
};

export default Inbox;

const ConversationItem = ({
  conversation,
  selectedConversation,
  onConversationClick,
}) => {
  const { user } = useSelector((store) => store.user);
  const conversationUsers = conversation?.users?.filter(
    (item) => item.id !== user?.id,
  );

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={2}
      sx={{
        width: "100%",
        p: 2,
        background: selectedConversation ? "#F9F5EF" : "#fff",
        transition: "background 200ms linear 200ms",
        "&:hover": {
          backgroundColor: "#F9F5EF",
          cursor: "pointer",
        },
        "&:not(:last-child)": {
          borderBottom: "1px solid #E1E1E1",
        },
      }}
      onClick={onConversationClick(conversation.id)}
    >
      {conversationUsers?.length > 1 ? (
        <Box
          sx={{ position: "relative", width: 87, height: 87, flexShrink: 0 }}
        >
          <Box sx={{ ...MultipleUsersAvatarSx, left: 0, bottom: 0, zIndex: 2 }}>
            {conversationUsers[0]?.imgUrl[
              conversationUsers[0]?.imgUrl.length - 1
            ] ? (
              <img
                src={
                  conversationUsers[0]?.imgUrl[
                    conversationUsers[0]?.imgUrl.length - 1
                  ]
                }
                alt={`application-item`}
              />
            ) : (
              <AccountCircleIcon
                viewBox={"2 2 20 20"}
                preserveAspectRatio="none"
              />
            )}
          </Box>
          <Box sx={{ ...MultipleUsersAvatarSx, right: 0, top: 0, zIndex: 1 }}>
            {conversationUsers[0]?.imgUrl[
              conversationUsers[0]?.imgUrl?.length - 1
            ] ? (
              <img
                src={
                  conversationUsers[1]?.imgUrl[
                    conversationUsers[1]?.imgUrl.length - 1
                  ]
                }
                alt={`application-item`}
              />
            ) : (
              <AccountCircleIcon
                viewBox={"2 2 20 20"}
                preserveAspectRatio="none"
              />
            )}
          </Box>
          {conversationUsers?.length > 2 && (
            <Box
              sx={{
                position: "absolute",
                right: 0,
                bottom: 0,
                color: "#0E1D31",
                fontWeight: "900",
                fonSize: 18,
              }}
            >
              +{conversationUsers?.length - 2}
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            flexShrink: 0,
            borderRadius: "50%",
            width: 87,
            height: 87,
            overflow: "hidden",
            "& > img, & > svg": {
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          }}
        >
          {conversationUsers[0]?.imgUrl[
            conversationUsers[0]?.imgUrl.length - 1
          ] ? (
            <img
              src={
                conversationUsers[0]?.imgUrl[
                  conversationUsers[0].imgUrl?.length - 1
                ]
              }
              alt={`application-item`}
            />
          ) : (
            <AccountCircleIcon
              viewBox={"2 2 20 20"}
              preserveAspectRatio="none"
            />
          )}
        </Box>
      )}

      <Stack direction={"column"} spacing={0.5}>
        {conversationUsers?.length > 1 && (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#373632",
                  color: "#0E1D31",
                  padding: 1,
                  fontSize: 14,
                  fontWeight: 400,
                  "& .MuiTooltip-arrow": {
                    color: "#373632",
                  },
                },
              },
            }}
            placement={"top"}
            title={conversationUsers.map((item) => item?.username).join(", ")}
            arrow
          >
            <Typography
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                fontSize: 15,
                fontWeight: 600,
              }}
            >
              {conversationUsers.map((item) => item?.username).join(", ")}
            </Typography>
          </Tooltip>
        )}
        {conversationUsers?.length === 1 && (
          <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
            {conversationUsers[0]?.username}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: "normal",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {conversation?.messages[0]?.message}
        </Typography>
        <Stack direction={"row"} spacing={2} sx={{ mt: 1 }}>
          <Typography
            sx={{ fontSize: 11, fontWeight: 600, textTransform: "uppercase" }}
          >
            {conversationUsers[0]?.role}
          </Typography>
          <Typography sx={{ fontSize: 11, fontWeight: 600, color: "#8F8F8F" }}>
            {transformDate(conversation?.updatedAt)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const MultipleUsersAvatarSx = {
  borderRadius: "50%",
  overflow: "hidden",
  border: "1px solid #fff",
  width: 57,
  height: 57,
  position: "absolute",
  "& > img, & > svg": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};
