import { useEffect, useState } from 'react';

const UseFullPageHeight = () => {
  const [height, setHeight] = useState('100vh');
  useEffect(() => {
    const updateHeight = () => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
        setHeight(`${window.innerHeight * 0.01 * 100}px`);
      } else {
        setHeight('100vh')
      }
    }
    window.addEventListener('resize', updateHeight);
    updateHeight();
    return () => window.removeEventListener('resize', updateHeight);
  }, []);
  return height;
};

export default UseFullPageHeight;
