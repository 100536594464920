import React from 'react';
import Select from './UI/Select';
import { MenuItem } from '@mui/material';

const ACCOMMODATION_TYPES = [
  'Villa',
  'Hotel',
  'Yacht',
  'Sailing boat',
  'Cruiseship',
  'Other'
];
const AccommodationTypeSelect = ({...props}) => {
  return (
    <Select {...props}>
      {ACCOMMODATION_TYPES.map((item, i) => (
        <MenuItem value={item} key={`${item}-${i}`}>{item}</MenuItem>
      ))}
    </Select>
  );
};

export default AccommodationTypeSelect;
