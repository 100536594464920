import { Link, Stack, Typography, Box, IconButton, Tooltip, Button } from '@mui/material';
import ToggleFullscreenButton from '../../components/ToggleFullscreenButton';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Input from '../../components/UI/Input';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDeleteConversationMutation, useSendMessageMutation } from './api';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import { addMessage } from './slice';

const ChatBox = ({isFullScreen, onChangeScreenSize, onChangeConversation, showPartyName = false}) => {
  const {user: {user}, inbox: {chat}} = useSelector(store => store)
  const [message, setMessage] = useState('');
  const chatRef = useRef(null);
  const emptyRef = useRef(null);
  const chatUsers = chat?.users?.filter(item => item.id !== user?.id);
  const [sendMessage, {isLoading}] = useSendMessageMutation();
  const [deleteConversation] = useDeleteConversationMutation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (chatRef) {
      chatRef.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
      emptyRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [])

  const onChangeMessage = ({target: {value}}) => {
    if (!value) {
      setMessage('');
      return;
    }
    setMessage(value);
  };

  const onSubmitMessage = () => {
    sendMessage({id: chat?.id, body: {message}}).then(res => {
      if (res.data) {
        setMessage('');
        dispatch(addMessage(res.data));
      }
    })
  };

  const onDeleteConversation = () => {
    deleteConversation(chat?.id).then(res => {
      if (res.data) {
        setOpen(false);
        onChangeConversation();
      }
    });
  };

  return (
    <Stack direction={'column'} sx={{height: '100%'}}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={2}
        sx={{pb: 3}}
      >
        <Box>
          <Button
            disableRipple
            component={Link}
            sx={{color: '#0E1D31', fontSize: 14, border: 'none', p: 0, textTransform: 'capitalize'}}
            onClick={() => setOpen(true)}
          >
            Delete
          </Button>
        </Box>
        <Box>
          {showPartyName && <Typography>{chat?.connectedInvitation?.name}</Typography>}
          {chatUsers?.length === 1 && (
            <>
              <Typography align={'center'} sx={{fontWeight: 'bold', fontSize: 17}}>{chatUsers[0].username}</Typography>
              <Typography align={'center'} sx={{textTransform: 'uppercase', fontSize: 11, fontWeight: 600}}>{chatUsers[0].role}</Typography>
            </>
          )}
          {chatUsers?.length > 1 && (
            <>
              <Tooltip
                title={chatUsers?.map(item => item?.username).join(', ')}
                placement={'top'}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#373632',
                      color: '#0E1D31',
                      padding: 1,
                      fontSize: 14,
                      fontWeight: 400,
                      '& .MuiTooltip-arrow': {
                        color: '#373632',
                      },
                    },
                  },
                }}
              >
                <Typography align={'center'} sx={{fontWeight: 'bold', fontSize: 17}}>{chatUsers?.length} users</Typography>
              </Tooltip>
            </>
          )}
        </Box>
        <Box>
          {onChangeScreenSize && (
            <ToggleFullscreenButton sx={{position: 'static'}} isFullScreen={isFullScreen} onChange={onChangeScreenSize} />
          )}
        </Box>
      </Stack>
      <Stack direction={'column'} sx={{flexGrow: 1, overflowY: 'auto', pr: 2, mr: 1.5}} ref={chatRef}>
        {chat?.messages?.length > 0 && chat.messages.map((message, i) => (
          <ChatItem
            messageInfo={message}
            key={message?.createdAt}
            showDate={!dayjs(message?.createdAt).isSame((chat?.messages[i - 1]?.createdAt || null), 'day')}
            isPrevMessageFromSameUser={message?.from?.id === chat?.messages[i - 1]?.from?.id}
          />
        ))}
        <Box ref={emptyRef} />
      </Stack>
      <Stack direction={'row'} alignItems={'center'} spacing={2} sx={{mt: 5}}>
        <Input
          fullWidth
          placeholder={'Write a message…'}
          value={message}
          onChange={onChangeMessage}
        />
        <IconButton onClick={onSubmitMessage} disabled={isLoading || !message}>
          <SendIcon sx={{color: '#0E1D31'}} />
        </IconButton>
      </Stack>
      <DeleteConfirmationDialog open={open} onClose={() => setOpen(false)} onConfirm={onDeleteConversation} />
    </Stack>
  );
};

export default ChatBox;

const ChatItem = ({messageInfo: {createdAt, from, message}, showDate, isPrevMessageFromSameUser}) => {
  const {user} = useSelector(store => store.user);
  const isOwnMessage = from?.id === user?.id;
  const isYesterday = useMemo(() => dayjs(createdAt).isSame(dayjs().subtract(1, 'day'), 'day'), [createdAt]);
  const isToday = dayjs(createdAt).isSame(dayjs(), 'day');

  return (
    <Box sx={{width: '100%', mt: isPrevMessageFromSameUser && !showDate ? 1 : 6}}>
      {createdAt && showDate && (
        <Typography sx={{fontSize: 15, fontWeight: 'medium', mb: 6}} align={'center'}>
          {isToday ? 'Today' : isYesterday ? 'Yesterday' : dayjs(createdAt).format('DD MMM YYYY')}
        </Typography>
      )}
      <Stack direction={isOwnMessage ? 'row' : 'row-reverse'} justifyContent={'flex-start'} spacing={3}>
        <Box
          sx={{
            width: 40,
            height: 40,
            overflow: 'hidden',
            borderRadius: '50%',
            flexShrink: 0,
            opacity: isPrevMessageFromSameUser && !showDate ? 0 : 1,
            '& > img, & > svg': {
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }
          }}
        >
          {from.imgUrl[from.imgUrl.length -1] ? <img src={from.imgUrl[from.imgUrl.length -1]} alt="user-profile" /> : <AccountCircleIcon />}
        </Box>
        <Box
          sx={{
            p: 2,
            background: isOwnMessage ? '#F9F5EF' : '#0E1D31',
            color: isOwnMessage ? '#373632' : '#fff',
            fontSize: 16,
            borderRadius: '6px',
            position: 'relative',
            '&:after': {
              content: '""',
              opacity: isPrevMessageFromSameUser && !showDate ? 0 : 1,
              position: 'absolute',
              top: 13,
              left: isOwnMessage ? -12 : '100%',
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: isOwnMessage ? '9px 12px 9px 0' : '9px 0 9px 12px',
              borderColor: isOwnMessage ? 'transparent #F9F5EF transparent transparent' : 'transparent transparent transparent #0E1D31'
            }
          }}
        >
          {message}
        </Box>
      </Stack>
    </Box>
  );
};


