import PageWrapper from "../../components/PageWrapper";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useUserQuery } from "./api";
import ProfileDetails from "../../components/ProfileDetails";

const UserProfile = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data: user, isLoading, isError } = useUserQuery(id, { skip: !id });

  return (
    <PageWrapper>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        sx={{ mb: 3 }}
      >
        <IconButton
          onClick={() =>
            window.history.state
              ? history.goBack()
              : history.push("/events-gallery")
          }
          sx={{ "& > svg": { color: "#ccc", fontSize: 32 } }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      {user && !isLoading && <ProfileDetails user={user} />}
      {isError && !isLoading && <Typography>User was not found</Typography>}
    </PageWrapper>
  );
};

export default UserProfile;
