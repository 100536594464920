import { Box, Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import Logo from "./../assets/inviteclub_public_gold.svg";
import HideOnScroll from "./HideOnScroll";
import NotificationDropdown from "./NotificationDropdown";

const AppHeader = ({ smUp }) => {
  const { user } = useSelector((store) => store);

  return (
    <HideOnScroll>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            px: "24px",
            height: 65,
            width: "100vw",
          }}
          spacing={2}
        >
          <Link
            to={"/"}
            component={NavLink}
            sx={{
              maxWidth: 130,
              width: "100%",
              "& > img": {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <img src={Logo} alt="invite-club-logo" />
          </Link>
          {user?.authenticated && smUp && <NotificationDropdown />}
        </Box>
      </Box>
    </HideOnScroll>
  );
};

export default AppHeader;
