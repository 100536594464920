import { createSlice } from '@reduxjs/toolkit';
import { inboxApi } from './api';

const initialState = {
  chat: {},
};

const inbox = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    addMessage: (state, {payload}) => {
      state.chat.messages = [...state?.chat.messages, payload];
    },
    clearSlice: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      inboxApi.endpoints.conversation.matchFulfilled,
      (state, {payload}) => {
        state.chat = payload;
      }
    )
  }
});

export const {
  addMessage,
  clearSlice,
} = inbox.actions;
export default inbox.reducer;
