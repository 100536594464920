import React, { useState } from "react";
import {
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import DatePicker from "../../components/UI/DatePicker";
import Input from "../../components/UI/Input";
import Label from "../../components/UI/Label";
import TuneIcon from "@mui/icons-material/Tune";
import { theme } from "../../services/Theme";
import ModalTitle from "../../components/UI/DialogTitle";
import { Button } from "../../components/UI/Button";
import Checkbox from "../../components/UI/Checkbox";

const InvitationsFilters = ({
  handleClear,
  handleFilter,
  children,
  params,
  showCompleted = false,
  sx,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isShowClear = showCompleted
    ? params?.startDate ||
      params?.locationFrom ||
      params?.status?.find((item) => item === "completed")
    : params?.startDate || params?.locationFrom;
  const handleFiltersChange = ({ target: { name, value } }) => {
    handleFilter(name, value);
  };

  const handleDateFilter = (e) => {
    if (dayjs(e?.$d).isValid()) {
      handleFilter("startDate", dayjs(e?.$d || null).format());
    }
  };

  const onChangeStatus = (e, checked) => {
    if (checked) {
      handleFilter("status", [...params?.status, "completed"]);
    } else {
      handleFilter(
        "status",
        params?.status.filter((item) => item !== "completed"),
      );
    }
  };

  const onClearFilters = () => {
    handleClear();
  };

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={{ xs: "space-between", sm: "flex-start" }}
        sx={{ ...sx }}
        gap={2}
      >
        {children}
        <Button
          variant={"text"}
          onClick={() => setCollapsed((state) => !state)}
          sx={{ display: "flex", gap: 1, minWidth: "auto", height: "auto" }}
        >
          <TuneIcon sx={{ color: collapsed ? "#0E1D31" : "#000" }} />
          {!isMobile && (
            <Typography
              sx={{ fontSize: 12, color: collapsed ? "#0E1D31" : "#000" }}
            >
              Filters
            </Typography>
          )}
        </Button>
      </Stack>
      {!isMobile && (
        <Collapse in={collapsed} sx={{ width: "100%" }}>
          <Stack
            direction={"column"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
            spacing={2}
          >
            <Stack direction={"row"} flexWrap={"wrap"} gap={2}>
              <Box>
                <Label sx={{ fontSize: 14 }}>Location</Label>
                <Input
                  value={params?.locationFrom || ""}
                  onChange={handleFiltersChange}
                  name={"locationFrom"}
                  sx={{ "& .MuiInputBase-root": { height: 34 }, width: 200 }}
                />
              </Box>
              <Box>
                <Label sx={{ fontSize: 14 }}>Date</Label>
                <DatePicker
                  value={params?.startDate || null}
                  onChange={handleDateFilter}
                  disablePast
                  sx={{
                    width: 200,
                    p: 0,
                    "& .MuiInputBase-root": { height: 34 },
                  }}
                />
              </Box>
              {showCompleted && (
                <Box>
                  <Label>Include past events?</Label>
                  <Checkbox
                    value={
                      params?.status?.find((item) => item === "completed") ||
                      false
                    }
                    checked={
                      Boolean(
                        params?.status?.find((item) => item === "completed"),
                      ) || false
                    }
                    onChange={onChangeStatus}
                  />
                </Box>
              )}
              {isShowClear && (
                <Typography
                  sx={{
                    height: 34,
                    color: "#0E1D31",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "34px",
                    textDecoration: "underline",
                    cursor: "pointer",
                    alignSelf: "flex-end",
                  }}
                  onClick={onClearFilters}
                >
                  Clear filters
                </Typography>
              )}
            </Stack>
          </Stack>
        </Collapse>
      )}
      {isMobile && (
        <Dialog
          open={collapsed}
          fullScreen
          onClose={() => setCollapsed(false)}
          sx={{
            "& .MuiPaper-root": {
              background: "#fff",
            },
          }}
        >
          <ModalTitle onClose={() => setCollapsed(false)}>
            <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
              Filters
            </Typography>
          </ModalTitle>
          <DialogContent sx={{ px: 2, mt: 3 }}>
            <Stack direction={"column"} gap={2}>
              <Box>
                <Label sx={{ fontSize: 14 }}>Location</Label>
                <Input
                  value={params?.locationFrom || ""}
                  onChange={handleFiltersChange}
                  name={"locationFrom"}
                  sx={{ "& .MuiInputBase-root": { height: 34 }, width: "100%" }}
                />
              </Box>
              <Box>
                <Label sx={{ fontSize: 14 }}>Date</Label>
                <DatePicker
                  value={params?.startDate || null}
                  onChange={handleDateFilter}
                  disablePast
                  sx={{
                    width: "100%",
                    p: 0,
                    "& .MuiInputBase-root": { height: 34 },
                  }}
                />
              </Box>
              {showCompleted && (
                <Box>
                  <Label>Include past events?</Label>
                  <Checkbox
                    value={
                      params?.status?.find((item) => item === "completed") ||
                      false
                    }
                    checked={
                      Boolean(
                        params?.status?.find((item) => item === "completed"),
                      ) || false
                    }
                    onChange={onChangeStatus}
                  />
                </Box>
              )}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ gap: 2, px: 2 }}>
            {isShowClear && (
              <Button
                variant={"text"}
                color={"primary"}
                onClick={onClearFilters}
              >
                Clear filters
              </Button>
            )}
            <Button variant={"text"} onClick={() => setCollapsed(false)}>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default InvitationsFilters;
