import MenuItem from '@material-ui/core/MenuItem';
import React, { useMemo, useState } from 'react';
import Select from './UI/Select';
import Input from './UI/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { Box } from '@mui/material';

const SECTORS = [
  'Arts and Entertainment',
  'Technology',
  'Healthcare & Medical',
  'Education',
  'Finance',
  'Legal',
  'Engineering & Construction',
  'Hospitality & Tourism',
  'Public Service',
  'Retail & Consumer Goods',
  'Modelling',
  'Investments',
  'Social Media',
  'Marketing & Advertising',
  'Real Estate',
  'Science & Research',
  'Agriculture',
  'Fitness & Wellness',
  'Freelancer/Self-Employed'
];

const sortIgnoreCase = (a, b) => (
  a.toLowerCase().localeCompare(b.toLowerCase())
);

const SectorsSelect = ({value, onChange, ...props}) => {
  const sectorsValue = useMemo(() => (
    SECTORS.includes(value) ? [...SECTORS.sort(sortIgnoreCase), 'Other'] : [...[...SECTORS, value].sort(sortIgnoreCase), 'Other']
  ), [value]);
  const [input, setInput] = useState('');
  const handleInput = ({target: {value}}) => {
    setInput(value);
  }

  const handleAdd = () => {
    if (value === input) {
      return;
    }
    onChange({target: {name: props.name, value: input}});
  };

  const handleOnChange = (e) => {
    onChange(e);
    setInput('');
  };

  return (
    <>
      <Select
        value={value}
        onChange={handleOnChange}
        {...props}
      >
        {sectorsValue.map((sector, i) => (
          <MenuItem key={`${sector}-${i}`} value={sector}>
            {sector}
          </MenuItem>
        ))}
      </Select>
      {value === 'Other' && (
        <Input
          value={input}
          placeholder={'Type your industry type'}
          onChange={handleInput}
          sx={{mb: 2}}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{cursor: 'pointer', color: 'primary.main', fontWeight: 500}} onClick={handleAdd}>ADD</Box>
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
};

export default SectorsSelect;
