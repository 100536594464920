import ModalTitle from '../../components/UI/DialogTitle';
import { Box, Dialog, DialogContent, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { Button } from '../../components/UI/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Label from '../../components/UI/Label';
import Input from '../../components/UI/Input';
import { useResetPasswordMutation } from './api';
import { useLocation } from 'react-router-dom';
import useFullPageHeight from '../../util/useFullPageHeight';

const ResetPasswordDialog = ({open, onClose, onOpenLogin}) => {
  const classes = {};
  const loading = false;
  const [resetPassword, {isLoading, isSuccess}] = useResetPasswordMutation();
  // const [validateToken] =
  const [form, setForm] = useState({password: '', confirmPassword: ''});
  const [errors, setErrors] = useState({});
  const [passwordFields, setPasswordFields] = useState({password: false, confirmPassword: false});
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get("token");
  const height = useFullPageHeight();


  const handleChange = ({target: {value, name}}) => {
    setForm(state => ({...state, [name]: value,}));
    setErrors(state => ({...state, [name]: null}))
  };

  const toggleField = (name) => () => {
    setPasswordFields(state => ({...state, [name]: !state[name]}));
  };

  const handleSubmit = () => {
    if (form.password !== "" && form.confirmPassword !== "" && form.password === form.confirmPassword) {
      resetPassword({token, ...form});
      return;
    }
    if (form.password === "") {
      setErrors((state) => ({...state, password: "Password cannot be empty"}));
    }
    if (form.confirmPassword === "") {
      setErrors((state) => ({...state, confirmPassword: "Confirm Password cannot be empty"}));
    }
    if (form.password !== form.confirmPassword) {
      setErrors((state) => ({...state, confirmPassword: "Passwords do not match."}));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: {xs: '100%', sm: 600},
          width: '100%',
          height: {xs: height, sm: 'auto'},
          maxHeight: {xs: '100%', sm: 'auto'},
          m: {xs: 0, sm: 1}
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        <Typography sx={{fontSize: 15, fontWeight: 600}}>RECOVER YOUR PASSWORD</Typography>
      </ModalTitle>
      <DialogContent sx={{px: 2, mt: 3}}>
        <Box sx={{maxWidth: 340, width: '100%', mx: 'auto'}}>
          {!isSuccess ? (
            <>
              <Label htmlFor="password">
                Password
              </Label>
              <Input
                id="password"
                name="password"
                type={passwordFields.password ? "text" : "password"}
                placeholder="Enter Password"
                value={form.password}
                onChange={handleChange}
                helperText={errors?.password}
                error={!!errors?.password}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                    >
                      <IconButton
                        onClick={toggleField('password')}
                        onMouseDown={e => e.preventDefault()}
                        edge="end"
                      >
                        {passwordFields.password ? (
                          <VisibilityOff sx={{color: "#6B6963"}} />
                        ) : (
                          <Visibility sx={{color: "#6B6963"}} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
              <Label sx={{mt: 2}} htmlFor="password">
                Confirm Password
              </Label>
              <Input
                id="confirmPassword"
                name="confirmPassword"
                type={passwordFields.confirmPassword ? "text" : "password"}
                placeholder="Re Enter Password"
                value={form.confirmPassword}
                onChange={handleChange}
                helperText={errors?.confirmPassword}
                error={!!errors?.confirmPassword}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      // style={{ backgroundColor: "transparent" }}
                      position="end"
                    >
                      <IconButton
                        // aria-label="toggle password visibility"
                        onClick={toggleField('confirmPassword')}
                        onMouseDown={e => e.preventDefault()}
                        edge="end"
                      >
                        {passwordFields.confirmPassword ? (
                          <VisibilityOff style={{ color: "#6B6963" }} />
                        ) : (
                          <Visibility style={{ color: "#6B6963" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={isLoading}
                fullWidth
                sx={{my: 2}}
                onClick={handleSubmit}
              >
                RESET PASSWORD
                {loading && (
                  <CircularProgress size={30} />
                )}
              </Button>
              <Typography sx={{fontSize: 14}}>
                Problem with your account?
                <Link href="mailto:contactsupport@theparty.club" sx={{ml: 1}}>
                  Contact us
                </Link>
              </Typography>
            </>
          ) :
            <>
              <Typography align={'center'} sx={{fontSize: 18}}>
                Password was successfully reset.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={onOpenLogin}
                fullWidth
                sx={{mt: 4}}
              >
                LOG IN
              </Button>
            </>
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordDialog;
