import Label from './Label';
import React from 'react';
import Input from './Input';
import { Typography } from '@mui/material';

const FormInput = ({label, error, required, ...props}) => {
  return (
    <>
      <Label sx={{mb: 1}}>{label} {!required && (
        <Typography sx={{ml: 0.5, fontSize: 16, fontWeight: 400, fontStyle: 'italic'}}>
          • Optional
        </Typography>
      )}</Label>
      <Input
        fullWidth
        error={Boolean(error)}
        sx={{mb: error ? 1 : 2}}
        {...props}
      />
      {error && <Typography sx={{color: 'red', mb: 2, fontSize: 14}}>{error}</Typography>}
    </>
  )
};

export default FormInput;
