import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const DatePicker = ({readOnly = false, ...props}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    if (!readOnly) {
      setIsOpen(true);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        format={'DD/MM/YYYY'}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        slotProps={{
          inputAdornment: {
            sx: {display: 'none'}
          },
          textField: {
            variant: 'outlined',
            readOnly: true,
            onClick: handleOpen,
            InputProps: {
              startAdornment: false,
            },
            sx: {
              width: '100%',
              '&.Mui-error': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'red',
                  borderWidth: '1px !important'
                }
              },
              '& .MuiInputBase-input.MuiOutlinedInput-input': {
                color: '#000',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: "rgba(14, 29, 49, 0.5)",
                borderWidth: '1px !important'
              },
              '& .MuiInputBase-root.MuiOutlinedInput-root:hover, &.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused': {
                boxShadow: 'none',
                borderColor: '#0E1D31',
                '&.Mui-error,': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '1px !important'
                  }
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0E1D31',
                  borderWidth: '1px !important',
                }
              },
              ...props.sx
            }
          },
          popper: {
            sx: {
              "& .MuiPickersDay-root": {
                color: "#373632 !important",
                "&.Mui-selected": {
                  color: "white !important",
                  backgroundColor: "#0E1D31 !important",
                },
                "&.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.38) !important",
                },
              },
              "& .MuiPickersYear-root .MuiPickersYear-yearButton": {
                color: "#373632 !important",
                "&.Mui-selected": {
                  color: "white !important",
                  backgroundColor: "#0E1D31 !important",
                },
              },
              "& .PrivatePickersMonth-root": {
                color: "#373632 !important",
                "&.Mui-selected": {
                  color: "white !important",
                  backgroundColor: "#0E1D31 !important",
                },
              },
              '.MuiPickersCalendarHeader-switchViewIcon': {
                color: "#0E1D31",
              },
              '.MuiPickersArrowSwitcher-root': {
                '& .MuiSvgIcon-root': {
                  color: "#0E1D31",
                }
              }
            },
          },
        }}
        {...props}
        value={props?.value ? dayjs(props?.value) : null}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
