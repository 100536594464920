import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Container, Link, Stack, useMediaQuery } from "@mui/material";
import { theme } from "../services/Theme";

const Footer = ({ isStaticFooter = false, sx }) => {
  return (
    <Box
      sx={{
        zIndex: 100,
        position: { xs: "static", sm: "fixed" },
        bottom: 0,
        width: "100%",
        pt: 2,
        pb: { xs: "76px", sm: 2 },
        backgroundColor: "#fff",
        ...sx,
        // display: {xs: isVisibleOnMobile ? 'flex' : 'none', md: 'flex'},
      }}
    >
      <Container
        maxWidth={"xl"}
        sx={{
          maxWidth: isStaticFooter ? 1320 : "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={{ xs: 2, sm: 4 }}
          sx={{
            flexWrap: { sm: "nowrap" },
            overflowX: { xs: "auto", md: "visible" },
            width: { xs: "100%", md: "auto" },
            pb: { xs: 2, md: 0 },
            "& .MuiLink-root": {
              position: "relative",
            },
            "& .MuiLink-root + .MuiLink-root:before": {
              content: '""',
              display: { xs: "block", sm: "none" },
              width: 5,
              height: 5,
              borderRadius: "50%",
              backgroundColor: "#000",
              position: "absolute",
              left: -9,
              top: "50%",
              transform: "translateY(-50%)",
            },
          }}
        >
          <FooterNav />
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;

export const FooterNav = ({ sx }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Link sx={{ ...LinkSx, ...sx }} href="mailto:contactsupport@invite.club">
        contact us
      </Link>
      <Link
        component={NavLink}
        sx={{ ...LinkSx, ...sx }}
        to="/terms-and-conditions"
      >
        terms {!isMobile && " & conditions"}
      </Link>
      <Link component={NavLink} sx={{ ...LinkSx, ...sx }} to="/privacy-policy">
        privacy {!isMobile && " policy"}
      </Link>
    </>
  );
};

const LinkSx = {
  width: { xs: "auto", sm: "auto" },
  flexShrink: 0,
  color: "#696969",
  fontSize: "14px",
  fontWeight: 500,
  textDecoration: "none",
  position: "relative",
  textTransform: "capitalize",
  "&:after": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    right: 0,
    width: 0,
    bottom: -5,
    background: "#000",
    height: "1px",
    transition: "width 0.3s ease-in-out",
  },
  "&:hover:after, &.active:after": {
    left: 0,
    right: "auto",
    width: "100%",
  },
};
