import { createTheme as createThemeV5 } from "@mui/material/styles";

export const theme = createThemeV5({
  palette: {
    primary: {
      main: "#3CB9B2",
      default: "#F4FEFD",
    },
    secondary: {
      main: "#8C10CA",
      secondary: "#C369F01A",
    },
    grey: {
      50: "#FFFDFA",
      100: "#F7F7F7",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 320,
      sm: 640,
      md: 1030,
      lg: 1360,
      xl: 1536,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthMd: {
          maxWidth: 1180,
        },
        maxWidthLg: {
          maxWidth: 1280,
        },
      },
    },
  },
});
