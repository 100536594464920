import React from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

const InvitationCard = ({
  invitation: {
    id,
    name,
    startDate,
    startTime,
    locationFrom,
    status,
    eventPhotos,
  },
  showStatus = false,
}) => {
  const history = useHistory();
  const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const theme = useTheme();
  const goToInvitation = (e) => {
    e.stopPropagation();
    history.push(`/invitation/${id}`);
  };

  return (
    <Card
      sx={{
        width: "100%",
        position: "relative",
        boxShadow: "unset",
        overflow: "visible",
        "& > a": {
          textDecoration: "unset",
        },
      }}
      onClick={goToInvitation}
    >
      <Box
        sx={{
          width: smUp ? 328 : 266,
          minWidth: 266,
          height: smUp ? 276 : 212,
          minHeight: 212,
          position: "relative",
          boxShadow: "unset",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          cursor: "pointer",
          backgroundColor: "#ccc",
          backgroundPosition: "center",
          borderRadius: "20px",
          overflow: "hidden",
          "& > img": {
            width: "328px",
            height: "276px",
            objectFit: "cover",
          },
        }}
      >
        {eventPhotos?.length > 0 && <img src={eventPhotos[0]} alt={name} />}
        <Box
          sx={{
            width: "66px",
            height: "66px",
            position: "absolute",
            right: "5%",
            top: "5%",
            borderRadius: "8px",
            zIndex: 99,
            backgroundColor: theme.palette.grey[50],
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{dayjs(startDate).format("DD")}</Typography>
          <Typography variant="body2">
            {dayjs(startDate).format("MMM")}
          </Typography>
        </Box>
      </Box>
      <CardContent
        sx={{
          padding: "12px 0 0 0",
          "&:last-child": {
            pb: 0,
          },
        }}
      >
        <Typography
          sx={{
            color: "#000",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          {name}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: "100",
            }}
          >
            {dayjs(startDate).format("DD MMM YYYY")} ·{" "}
            {dayjs(startTime).format("hh:mm a")} ·{" "}
          </Typography>
          {(locationFrom?.city || locationFrom?.country) && (
            <Typography
              sx={{
                color: "#000",
                fontSize: "15px",
                fontWeight: "100",
              }}
            >
              {locationFrom?.city}
            </Typography>
          )}
        </Box>
        {showStatus && (
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: "14px",
              fontWeight: "100",
              color: "#000",
              display: "flex",
              alignItems: "center",
            }}
          >
            {status === "draft" && (
              <DescriptionIcon sx={{ mr: "2px", fontSize: 15 }} />
            )}
            {status}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default InvitationCard;
