import React from "react";

const NotificationsIcon = ({
  strokeColor = "#343A40",
  width = 22,
  height = 22,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4792 17.0149C14.908 18.6153 13.9582 20.2603 12.3578 20.6891C10.7574 21.118 9.11244 20.1682 8.68361 18.5678M0.890085 10.6134C0.500591 9.21524 0.905633 7.7036 1.94204 6.68749M10.9796 4.74096C11.2838 4.19366 11.384 3.53216 11.2091 2.87957C10.8518 1.5459 9.48092 0.754445 8.14725 1.1118C6.81359 1.46915 6.02213 2.84 6.37949 4.17366C6.55435 4.82626 6.97188 5.34903 7.50898 5.6709M19.1523 5.72011C18.7905 4.3145 17.6839 3.20791 16.2783 2.84613M16.3239 8.4454C15.9669 7.11326 15.014 5.9993 13.6747 5.34857C12.3355 4.69784 10.7195 4.56364 9.18247 4.9755C7.64539 5.38735 6.31307 6.31153 5.47859 7.54472C4.64411 8.77791 4.37583 10.2191 4.73277 11.5512C5.32335 13.7553 5.20867 15.5137 4.83572 16.8498C4.41065 18.3727 4.19812 19.1341 4.25554 19.287C4.32123 19.4619 4.36875 19.5099 4.54298 19.5774C4.69527 19.6364 5.33504 19.465 6.61458 19.1222L18.4797 15.9429C19.7592 15.6001 20.399 15.4286 20.5014 15.3014C20.6185 15.1558 20.6356 15.0905 20.6051 14.9061C20.5783 14.745 20.0136 14.1919 18.884 13.0856C17.893 12.1149 16.9145 10.6494 16.3239 8.4454Z"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NotificationsIcon;
