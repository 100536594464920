import React from 'react';
import { Box } from '@mui/material';

const TravelPackageBadge = ({title, color, small = false}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: small ? 10 : 30,
        left: small ? -10 : -12,
        padding: small ? "4px 11px" : "0.5rem 2.5rem",
        borderRadius: "0px 4px 4px 0px",
        color: "#fff",
        fontSize: small ? 12 : 14,
        lineHeight: 1,
        letterSpacing: "0.7px",
        fontWeight: "bold",
        backgroundColor: color || '#fff',
        "&::before": {
          content: "''",
          position: "absolute",
          top: small ? 20 : 30,
          left: 0,
          borderLeft: small ? "10px solid transparent" : "12px solid transparent",
          borderRight: "0px solid transparent",
          borderTop: "7px solid #000",
          width: 0,
          height: 0,
        },
      }}
    >
        {title}
      </Box>
  )
};

export default TravelPackageBadge;
