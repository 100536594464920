import ModalTitle from '../../components/UI/DialogTitle';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { Button } from '../../components/UI/Button';
import React from 'react';

const DeleteConfirmationDialog = ({open, onClose, onConfirm}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 600,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose} >
        <Typography sx={{fontSize: 15, fontWeight: 600}}>ARE YOU SURE YOU WANT TO PERMANENTLY DELETE THIS CONVERSATION?</Typography>
      </ModalTitle>
      <DialogContent sx={{p: 3, mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Button
          sx={{mt: 2, maxWidth: 300, width: '100%'}}
          variant="outlined"
          color="error"
          onClick={onConfirm}
        >
          Delete
        </Button>
        <Button
          sx={{mt: 2, maxWidth: 300, width: '100%'}}
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
