import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { IconButton } from '@mui/material';

const ToggleFullscreenButton = ({isFullScreen = false, onChange, sx}) => {
  return (
    <IconButton onClick={onChange} sx={{
      position: 'absolute',
      right: 0,
      top: -30,
      p: 0,
      '& .MuiSvgIcon-root': {
        fill: '#000',
        fontSize: 28,
      },
      ...sx
    }}>
      {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
    </IconButton>
  )
};

export default ToggleFullscreenButton
