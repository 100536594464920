import { connection } from "./sse";

export const notificationsMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    const { user } = getState();

    if (!connection.connection && user.authenticated) {
      connection.connect();
    }

    return next(action);
  };
