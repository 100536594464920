import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDeleteInvitationMutation, useInvitationQuery } from "../api";
import dayjs from "dayjs";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  MenuItem,
  useMediaQuery,
  Divider,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import PageWrapper from "../../../components/PageWrapper";
import { Button } from "../../../components/UI/Button";
import Dropdown from "../../../components/UI/Dropdown";
import InvitationInfo from "./InvitationInfo";
import InvitationTimeline from "./InvitationTimeline";
import DeleteConfirmModal from "../DeleteConfirmModal";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import {
  useCheckIsUserAppliedQuery,
  useInvitationApplicationsQuery,
} from "../../applications/api";
import EventInvitationInfo from "./EventInvitationInfo";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NoItemsBox from "../../../components/NoItemsBox";
import InvitationMediaList from "./InvitationMediaList";
import { theme } from "../../../services/Theme";
import MobileSlider from "./MobileSlider";
import { ReactComponent as ShareIcon } from "../../../assets/share-icon.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

const Invitation = () => {
  const { id } = useParams();
  const { user } = useSelector((store) => store);
  const { data: invitation, error, isLoading } = useInvitationQuery(id);
  const { data: isUserApplied } = useCheckIsUserAppliedQuery(
    { invitationId: id, userId: user.user.id },
    { skip: !id || !user?.user?.id },
  );
  const [deleteInvitation, { isSuccess: isDeleteSuccess }] =
    useDeleteInvitationMutation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { data: applications = [] } = useInvitationApplicationsQuery({
    id,
    params: { status: "accepted" },
  });
  const isUserAccepted = applications?.find(
    (item) => item?.user?.id === user?.user?.id,
  );

  useEffect(() => {
    if (isDeleteSuccess) {
      setOpen(false);
      history.push("/my-events");
    }
  }, [isDeleteSuccess, history]);

  const onDeleteDraft = () => {
    const name = invitation?.name;
    deleteInvitation({ id }).then((res) => {
      if (res?.data) {
        enqueueSnackbar(`${name} was deleted`, {
          variant: "error",
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: "not_found",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          ),
        });
      }
    });
  };

  const onCopy = () => {
    enqueueSnackbar(`Link copied to clipboard! Share it now`, {
      variant: "success",
      autoHideDuration: 10000,
      preventDuplicate: true,
      key: "not_found",
      action: (key) => (
        <IconButton onClick={() => closeSnackbar(key)} color={"primary"}>
          <CloseIcon sx={{ color: "#0E1D31", fontSize: "20px" }} />
        </IconButton>
      ),
    });
  };

  return (
    <PageWrapper
      hideMobileNav
      sx={{
        pt: {
          xs: invitation?.eventPhotos?.length === 0 ? "60px" : 0,
          sm: "70px",
          md: "95px",
        },
      }}
    >
      {error && !isLoading && <NoItemsBox />}
      {invitation?.deleted && !isLoading && (
        <Typography>Event was deleted</Typography>
      )}
      {invitation && !error && !isLoading && !invitation?.deleted && (
        <>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent={"space-between"}
            spacing={1}
            sx={{
              width: "100%",
              pb: 1,
              background: "#fff",
              display: { xs: "none", sm: "flex" },
            }}
          >
            <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 26,
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                {invitation?.name}
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", md: "center" }}
                spacing={1}
                sx={{ display: "flex" }}
              >
                <Typography
                  sx={{
                    color: "#373632",
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <CalendarMonthIcon sx={{ fontSize: 14 }} />
                  {dayjs(invitation?.startDate).format("DD MMMM YYYY")}
                </Typography>
                <Typography
                  sx={{
                    color: "#373632",
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <LocationOnIcon sx={{ fontSize: 14 }} />
                  {invitation?.locationFrom?.city},{" "}
                  {invitation?.locationFrom?.country}
                </Typography>
              </Stack>
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 1,
                flexShrink: 0,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              {invitation?.status !== "draft" && (
                <CopyToClipboard onCopy={onCopy} text={window.location.href}>
                  <Button
                    variant={"text"}
                    color={"secondary"}
                    sx={{
                      "&.MuiButton-root": {
                        height: 35,
                        fontSize: 14,
                        fontWeight: 500,
                        textTransform: "capitalize",
                        borderRadius: 0,
                      },
                      "& > svg": { mr: 0.5 },
                    }}
                  >
                    <ShareIcon />
                    Share
                  </Button>
                </CopyToClipboard>
              )}
              {((invitation?.host.id === user?.user?.id &&
                applications?.length > 0) ||
                isUserAccepted) && (
                <Button
                  sx={{
                    "&.MuiButton-root": {
                      height: 35,
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      textTransform: "capitalize",
                      borderRadius: 0,
                    },
                  }}
                  variant="text"
                  component={Link}
                  to={`/invitation/${invitation?.id}/attendees`}
                >
                  Attendees
                </Button>
              )}
              {invitation?.host.id === user?.user?.id &&
                invitation?.status === "active" &&
                invitation?.totalApplications?.length > 0 && (
                  <Button
                    sx={{
                      "&.MuiButton-root": {
                        height: 35,
                        py: 0.5,
                        fontSize: 14,
                        fontWeight: 500,
                        textTransform: "capitalize",
                        borderRadius: 0,
                        color: "#000",
                      },
                    }}
                    variant="text"
                    component={Link}
                    to={`/applications/${invitation?.id}`}
                  >
                    {invitation.totalApplications?.length} applications
                  </Button>
                )}
              {invitation?.host.id === user?.user?.id &&
                invitation?.status === "active" && (
                  <Button
                    sx={{
                      "&.MuiButton-root": {
                        height: 35,
                        py: 0.5,
                        fontSize: 14,
                        fontWeight: 500,
                        textTransform: "capitalize",
                        borderRadius: 0,
                      },
                    }}
                    variant="text"
                    component={Link}
                    startIcon={
                      <CreateIcon sx={{ fontSize: "16px !important" }} />
                    }
                    to={`/invitation/${invitation?.id}/edit`}
                  >
                    edit
                  </Button>
                )}
              {invitation?.host.id === user?.user?.id &&
                invitation?.status === "draft" && (
                  <>
                    <Button
                      sx={{
                        "&.MuiButton-root": {
                          height: 35,
                          py: 0.5,
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "capitalize",
                          borderRadius: 0,
                        },
                      }}
                      variant="text"
                      color={"error"}
                      onClick={() => setOpen(true)}
                    >
                      Delete Draft
                    </Button>
                    <Button
                      sx={{
                        "&.MuiButton-root": {
                          height: 35,
                          py: 0.5,
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "capitalize",
                          borderRadius: 0,
                        },
                      }}
                      variant="text"
                      component={Link}
                      to={`/create-invitation/${invitation?.id}`}
                    >
                      Finish Draft
                    </Button>
                  </>
                )}
              {isUserApplied?.isApplied && (
                <Typography
                  sx={{
                    fontSize: 14,
                    lineHeight: "35px",
                    color: "black",
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                >
                  {isUserAccepted ? "Accepted" : "already applied"}
                </Typography>
              )}
              {!isUserApplied?.isApplied &&
                invitation?.host.id !== user?.user?.id &&
                user?.user?.role !== "admin" && (
                  <Button
                    sx={{
                      "&.MuiButton-root": {
                        height: 35,
                        py: 0.5,
                        fontSize: 14,
                        fontWeight: 500,
                        textTransform: "capitalize",
                        borderRadius: 0,
                      },
                    }}
                    variant="text"
                    color="primary"
                    component={Link}
                    to={`/invitation/${invitation?.id}/apply`}
                  >
                    apply
                  </Button>
                )}
              {user?.user?.role === "admin" && (
                <Dropdown
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "center", md: "flex-start" },
                  }}
                  dropdownItems={
                    <Box>
                      <MenuItem
                        onClick={() =>
                          history.push({
                            pathname: "/inbox",
                            state: {
                              activeUser: {
                                username: invitation?.host?.username,
                                id: invitation?.host?.id,
                              },
                            },
                          })
                        }
                      >
                        SEND MESSAGE TO HOST
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={`/invitation/${invitation?.id}/edit`}
                      >
                        EDIT INVITATION
                      </MenuItem>
                    </Box>
                  }
                >
                  {
                    <Typography sx={{ display: { xs: "flex", sm: "none" } }}>
                      Options
                    </Typography>
                  }
                  <MoreVertIcon />
                </Dropdown>
              )}
            </Box>
          </Stack>
          {isMobile && (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              spacing={2}
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                px: 2,
                py: 1,
                background: "#fff",
                zIndex: 10,
                borderTop: "1px solid rgb(221, 221, 221)",
              }}
            >
              {invitation?.host.id === user?.user?.id &&
                invitation?.status === "active" && (
                  <Button
                    sx={{
                      "&.MuiButton-root": {
                        height: 35,
                        py: 0.5,
                        fontSize: 14,
                        fontWeight: 500,
                        textTransform: "capitalize",
                        borderRadius: 0,
                      },
                    }}
                    variant="contained"
                    component={Link}
                    to={`/invitation/${invitation?.id}/edit`}
                  >
                    edit
                  </Button>
                )}
              {invitation?.host.id === user?.user?.id &&
                invitation?.status === "draft" && (
                  <>
                    <Button
                      sx={{
                        "&.MuiButton-root": {
                          height: 35,
                          py: 0.5,
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "capitalize",
                          borderRadius: 0,
                        },
                      }}
                      variant="contained"
                      color={"error"}
                      onClick={() => setOpen(true)}
                    >
                      Delete Draft
                    </Button>
                    <Button
                      sx={{
                        "&.MuiButton-root": {
                          height: 35,
                          py: 0.5,
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "capitalize",
                          borderRadius: 0,
                        },
                      }}
                      variant="contained"
                      component={Link}
                      to={`/create-invitation/${invitation?.id}`}
                    >
                      Finish Draft
                    </Button>
                  </>
                )}
              {isUserApplied?.isApplied && (
                <Button
                  sx={{
                    "&.MuiButton-root": {
                      height: 35,
                      py: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      textTransform: "capitalize",
                      borderRadius: 0,
                    },
                  }}
                  variant="contained"
                  disabled
                >
                  {isUserAccepted ? "Accepted" : "already applied"}
                </Button>
              )}
              {!isUserApplied?.isApplied &&
                invitation?.host.id !== user?.user?.id &&
                user?.user?.role !== "admin" && (
                  <Button
                    sx={{
                      "&.MuiButton-root": {
                        height: 48,
                        py: 2,
                        px: 7,
                        fontSize: 16,
                        borderRadius: "8px",
                        fontWeight: 500,
                        textTransform: "capitalize",
                      },
                    }}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/invitation/${invitation?.id}/apply`}
                  >
                    apply
                  </Button>
                )}
            </Stack>
          )}
          {isMobile && invitation?.eventPhotos.length > 0 && (
            <Box sx={{ position: "relative" }}>
              <Stack
                direction="row"
                alignitems={"center"}
                justifyContent={"space-between"}
                sx={{
                  position: "absolute",
                  zIndex: 1000,
                  py: 1,
                  left: 0,
                  top: 0,
                  width: "100%",
                }}
              >
                <IconButton
                  sx={{
                    background: "#fff",
                    borderRadius: "50%",
                    border: "1px solid #ccc",
                  }}
                  onClick={() =>
                    window.history.state
                      ? history.goBack()
                      : history.push("/events-gallery")
                  }
                >
                  <ArrowBackIosNewIcon sx={{ color: "#000", fontSize: 16 }} />
                </IconButton>
                <Stack direction={"row"} alignitems={"center"} spacing={2}>
                  {invitation?.status !== "draft" && (
                    <CopyToClipboard
                      onCopy={onCopy}
                      text={window.location.href}
                    >
                      <IconButton
                        sx={{
                          background: "#fff",
                          borderRadius: "50%",
                          "& > svg": {
                            color: "#000",
                            fontSize: 16,
                            strokeWidth: "3px !important",
                          },
                        }}
                      >
                        <ShareIcon />
                      </IconButton>
                    </CopyToClipboard>
                  )}
                </Stack>
              </Stack>
              {((invitation?.host.id === user?.user?.id &&
                applications?.length > 0) ||
                isUserAccepted) && (
                <IconButton
                  sx={{
                    position: "absolute",
                    left: 0,
                    bottom: 60,
                    background: "#fff",
                    zIndex: 1000,
                    borderRadius: "4px",
                    fontSize: 14,
                    fontWeight: 500,
                    minWidth: 138,
                    height: 35,
                    justifyContent: "flex-start",
                    py: 0.5,
                    px: 2,
                    "& > svg": {
                      fontSize: 16,
                      mr: 0.5,
                    },
                  }}
                  component={Link}
                  // sx={{background: '#fff', borderRadius: '50%'}}
                  to={`/invitation/${invitation?.id}/attendees`}
                >
                  <PeopleAltIcon sx={{ color: "#000", fontSize: 16 }} />
                  Attendees
                </IconButton>
              )}
              {invitation?.host.id === user?.user?.id &&
                invitation?.status === "active" &&
                invitation?.totalApplications?.length > 0 && (
                  <Button
                    sx={{
                      "&.MuiButton-root": {
                        height: 35,
                        py: 0.5,
                        fontSize: 14,
                        fontWeight: 500,
                        textTransform: "capitalize",
                        borderRadius: "4px",
                        position: "absolute",
                        bottom: 10,
                        left: 0,
                        zIndex: 1000,
                      },
                    }}
                    variant="contained"
                    color={"secondary"}
                    component={Link}
                    to={`/applications/${invitation?.id}`}
                  >
                    {invitation.totalApplications?.length} applications
                  </Button>
                )}
              <MobileSlider
                images={invitation?.eventPhotos}
                video={invitation?.video}
              />
            </Box>
          )}
          {!isMobile && invitation?.eventPhotos.length > 0 && (
            <InvitationMediaList
              images={invitation?.eventPhotos}
              video={invitation?.video}
            />
          )}
          {isMobile && (
            <Typography
              sx={{
                color: "#000",
                fontSize: 26,
                fontWeight: 600,
                textTransform: "capitalize",
                mt: 1,
              }}
            >
              {invitation?.name}
            </Typography>
          )}
          {invitation?.additionalInfo && (
            <>
              <Typography sx={{ pt: 2, fontSize: 14, fontWeight: 400, mb: 1 }}>
                {invitation?.additionalInfo}
              </Typography>
              <Divider />
            </>
          )}
          {invitation && (
            <Grid
              container
              direction={{ xs: "column-reverse", sm: "row" }}
              justifyContent="space-between"
              columnSpacing={5}
              sx={{ mt: 4 }}
            >
              <Grid item xs={12} sm={7}>
                <InvitationTimeline invitation={invitation} />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box
                  sx={{
                    mb: 2,
                    p: { xs: 0, sm: 3 },
                    boxShadow: {
                      xs: "none",
                      sm: "rgba(0, 0, 0, 0.12) 0px 6px 16px",
                    },
                    borderRadius: "10px",
                  }}
                >
                  {invitation?.host.id !== user?.user?.id &&
                    user?.user?.role !== "admin" &&
                    !isMobile && (
                      <Button
                        sx={{
                          "&.MuiButton-root": {
                            height: 45,
                            mb: 2,
                            py: 0.5,
                            fontSize: 14,
                            fontWeight: 500,
                            textTransform: "capitalize",
                            borderRadius: 0,
                          },
                        }}
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/invitation/${invitation?.id}/apply`}
                        disabled={isUserApplied?.isApplied}
                        fullWidth
                      >
                        {!isUserApplied?.isApplied
                          ? "apply"
                          : isUserAccepted
                            ? "Accepted"
                            : "already applied"}
                      </Button>
                    )}
                  {invitation?.experienceType === "event" ? (
                    <EventInvitationInfo invitation={invitation} />
                  ) : (
                    <InvitationInfo invitation={invitation} />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}
      <DeleteConfirmModal
        open={open}
        onConfirm={onDeleteDraft}
        onClose={() => setOpen(false)}
      />
    </PageWrapper>
  );
};

export default Invitation;
