import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  applicationForm: {},
  errors: null,
};

const application = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setForm: (state, {payload}) => {
      state.applicationForm = {...state?.applicationForm, ...payload};
    },
    clearSlice: () => {
      return initialState;
    },
    updateForm: (state, {payload: {key, value}}) => {
      state.applicationForm = {...state.applicationForm, [key]: value};
    },
    setError: (state, {payload: {key, value}}) => {
      state.errors = {...state.errors, [key]: value};
    },
  }
});

export const {
  setForm,
  updateForm,
  setError,
  clearSlice,
} = application.actions;
export default application.reducer;
