import React, { Component } from "react";
import OnBoardingSlider from "../../components/StaticPages/OnBoarding/OnBoardingSlider";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import StaticPageWrapper from '../../components/StaticPageWrapper';

const styles = {
  toggle: {
    marginTop: "60px",
  },
  bodyWrapper: {
    maxWidth: "713px",
    minHeight: "calc(100vh - 180px)",
    padding: "15px ",
    width: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    "@media (max-width:700px)": {
      paddingBottom: "80px",
    },
  },
};
class Onboarding extends Component {
  constructor() {
    super();
    this.state = {
      showButtons: false,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <StaticPageWrapper>
        <div className={classes.bodyWrapper}>
          <OnBoardingSlider />
        </div>
      </StaticPageWrapper>
    );
  }
}

Onboarding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(withStyles(styles)(Onboarding));
