import { styled } from '@mui/material/styles';
import { FormLabel } from '@mui/material';

const Label = styled(FormLabel)(() => ({
  color: '#373632',
  fontSize: 14,
  lineHeight: 1.2,
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center'
}));

export default Label;
