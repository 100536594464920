import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import React from 'react';

const CustomTooltip = ({error = false, tooltipText}) => {
  return (
    <Tooltip
      title={tooltipText}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#0E1D31',
            p: 1.25,
            fontSize: 14,
            fontWeight: 400,
            '& .MuiTooltip-arrow': {
              color: '#0E1D31',
            },
          },
        },
      }}
    >
      <InfoOutlinedIcon
        sx={{
          ml: 0.5,
          fontSize: 20,
          width: 20,
          height: 20,
          fill: error ? '#F44336' : '#0E1D31'
        }}
      />
    </Tooltip>
  );
};

export default CustomTooltip;
