import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import React from "react";
import ProfileIcon from "./icons/ProfileIcon";
import { IconButton } from "@material-ui/core";
import { theme } from "../services/Theme";

const Profile = () => {
  const location = useLocation();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <IconButton
        color={location.pathname === "/profile" ? "secondary" : "default"}
        aria-label={"Profile"}
        href={"/profile"}
        style={{
          borderRadius: "8px",
          backgroundColor: "#FFF",
        }}
      >
        <ProfileIcon
          strokeColor={
            location.pathname === "/profile"
              ? theme.palette.secondary.main
              : undefined
          }
        />
      </IconButton>
    </Box>
  );
};

export default Profile;
