import dayjs from 'dayjs';

export const GOOGLE_MAPS_API_KEY = "AIzaSyB4qbHMQlbaONX-y3c0SJ5G6cckPCKtFLc";

export const dataURLtoBlob = (dataUrl) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type: mime});
}

export const blobToDataURL = (blob, callback) => {
  let a = new FileReader();
  a.onload = function(e) {callback(e.target.result);}
  a.readAsDataURL(blob);
}

export const transformDate = (date) => {
  const dayDiff = dayjs().diff(dayjs(date), 'day');
  const hoursDiff = dayjs().diff(dayjs(date), 'hour');
  const minutesDiff = dayjs().diff(dayjs(date), 'minute');
  const secondsDiff = dayjs().diff(dayjs(date), 'second');

  if (dayDiff > 0) {
    return `${dayDiff}d ago`;
  }
  if (hoursDiff > 0) {
    return `${hoursDiff}h ago`;
  }
  if (minutesDiff > 0) {
    return `${minutesDiff}m ago`;
  }
  if (secondsDiff < 60 && secondsDiff > 0) {
    return `${secondsDiff}s ago`;
  }
  if (secondsDiff < 60 && secondsDiff === 0) {
    return `just now`;
  }
}

export const getVideoDuration = (file) => {
  const video = document.createElement('video')
  video.preload = 'metadata'

  const onLoadedMetaDataPromise = new Promise((resolve) => {
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src)
      resolve(video.duration)
      video.remove()
    }
  })

  video.src = window.URL.createObjectURL(file)

  return onLoadedMetaDataPromise
}

export const isEmptyObject = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}
