import React from 'react';
import ThumbSlider from '../../../components/ThumbSlider';
import Video from '../../../components/Video';
import { Grid, Typography, Link, Box } from '@mui/material';
import { Link as NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ProfileDetails from '../../../components/ProfileDetails';
const Application = ({application, isTwoColumnsLayout = false, isPreview = false}) => {
  const {user} = useSelector(store => store.user);
  return (
    <Grid container>
      <Grid item xs={12} sm={isTwoColumnsLayout ? 6 : 12}>
        {application?.messageToHost && (
          <>
            <Typography sx={{fontSize: 16, color: '#000', fontWeight: 400,}}>Personal Greeting</Typography>
            <Typography sx={{fontSize: 14, color: '#000', fontWeight: 100, mb: 2}}>{application?.messageToHost}</Typography>
          </>
        )}
        {application?.video && (
          <>
            <Typography sx={{
              fontSize: 16,
              fontWeight: 400,
              mb: 1,
              lineHeight: '1',
            }}>
              Video
            </Typography>
            <Video video={application?.video} videoHeight={{xs: 200, sm: 345}} />
          </>
        )}
        {isPreview ? (
          <Link component={NavLink} to={`/user/${user?.id}`} sx={{'&:hover .MuiTypography-root':{textDecoration: 'underline'}, display: 'flex', alignItems: 'center', gap: 1}}>
            {user?.imgUrl?.length > 0 && (
              <Box sx={{width: 46, height: 46, '& > img, & > svg': {width: '100%', height: '100%', objectFit: 'cover'}, borderRadius: '50%', overflow: 'hidden'}}>
                {user?.imgUrl[user?.imgUrl.length - 1] ? <img src={user?.imgUrl[user?.imgUrl?.length - 1]} alt="host" /> :  <AccountCircle color={'secondary'}/>}
              </Box>
            )}
            <Typography sx={{fontSize: 12, color: '#373632', display: 'inline-block'}}>
              @{user?.username}
            </Typography>
          </Link>
        ) : (
          <ProfileDetails user={application?.user} sx={{alignItems: 'flex-start', mx: 0}} />
        )}
      </Grid>
      {isTwoColumnsLayout && (
        <Grid item xs={12} sm={6}>
          {application?.gallery?.length > 0 && (
            <Box sx={{mb: 3}}>
              <Typography sx={{
                color: "#0E1D31",
                fontSize: 16,
                fontWeight: 500,
                mb: 1,
                lineHeight: '1'
              }}>
                Photos
              </Typography>
              <ThumbSlider images={application?.gallery} />
            </Box>
          )}
          {application?.video && (
            <>
              <Typography sx={{
                color: "#0E1D31",
                fontSize: 16,
                fontWeight: 500,
                mb: 1,
                lineHeight: '1'
              }}>
                Video
              </Typography>
              <Video video={application?.video} videoHeight={{xs: 200, sm: 345}} />
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Application;
