import React from 'react';
import { Box } from '@mui/material';

const ScrollableSidebar = ({children, sx, removeRightMargin = false}) => (
  <Box sx={{flexGrow: 1, overflowY: 'auto', pr: removeRightMargin ? 0 : {xs: 0, md: 2}, mr: removeRightMargin ? 0 : {xs: 0, md: 1.5}, ...sx}}>
    {children}
  </Box>
);

export default ScrollableSidebar;
