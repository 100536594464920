import { Stack, Typography } from '@mui/material';
import React from 'react';

const FiltersInfo = ({filtersCount = 0, onClear}) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
      <Stack direction={'row'}>
        <Typography sx={{
          color: "#373632",
          fontSize: "15px",
          letterSpacing: "0.23px"
        }}>
          Filters ({filtersCount})
        </Typography>
      </Stack>
      <Typography
        sx={{
          mt: 0.5,
          color: "#0E1D31",
          fontWeight: "600",
          fontSize: "14px",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={onClear}
      >
        Clear filters
      </Typography>
    </Stack>
  );
};

export default FiltersInfo;
