import FormWrapper from "./FormWrapper";
import { useDispatch, useSelector } from "react-redux";
import { setError, updateDraft } from "../slice";
import dayjs from "dayjs";
import { FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Label from "../../../components/UI/Label";
import GoogleAutocomplete from "../../../components/GoogleAutocomplete";
import DatePicker from "../../../components/UI/DatePicker";
import Input from "../../../components/UI/Input";
import CustomSwitch from "../../../components/UI/CustomSwitch";
import FormButtons from "./FormButtons";
import PreviewInvitationModal from "../PreviewInvitationModal";
import ImageRow from "../ImageRow";
import {
  useAddEventPhotosMutation,
  useAddVideoMutation,
  useDeleteImageMutation,
  useDeleteVideoMutation,
} from "../api";
import { blobToDataURL, getVideoDuration } from "../../../util/utils";
import PreviewButton from "../PreviewButton";
import Loader from "../../../components/Loader";
import CustomTimePicker from "../../../components/CustomTimePicker";
import Video from "../../../components/Video";

const EventGuestInvitationForm = () => {
  const { newInvitation } = useSelector((store) => store);
  const dispatch = useDispatch();
  const isDisabled =
    !newInvitation?.draft?.locationFrom ||
    !newInvitation?.draft?.startDate ||
    !newInvitation?.draft?.eventPhotos?.length ||
    !newInvitation?.draft?.startTime ||
    !newInvitation?.draft?.additionalInfo ||
    !newInvitation?.draft?.deadlineOfApplications ||
    !newInvitation?.draft?.preciseLocation ||
    !newInvitation?.draft?.numberOfGuest;
  const [open, setOpen] = useState(false);
  const [uploadEventPhotos, { isLoading: isUploadImageLoading }] =
    useAddEventPhotosMutation();
  const [deleteImage, { isLoading: isDeleteImageLoading }] =
    useDeleteImageMutation();
  const [uploadVideo, { isLoading: isUploadVideoLoading }] =
    useAddVideoMutation();
  const [deleteVideo, { isLoading: isDeleteVideoLoading }] =
    useDeleteVideoMutation();

  const handleDateChange = (e) => (key) => {
    dispatch(
      updateDraft({
        key,
        value: dayjs(e?.$d).isValid() ? dayjs(e?.$d || null).format() : null,
      }),
    );
  };

  const handleChange = ({ target: { name, value, type } }) => {
    if (type === "number") {
      dispatch(
        updateDraft({ key: name, value: value < 0 ? Math.abs(value) : value }),
      );
      return;
    }
    dispatch(updateDraft({ key: name, value }));
  };

  const handleLocation = (key) => (value) => {
    dispatch(setError({ key, value: null }));
    dispatch(updateDraft({ key, value }));
    if (!value?.city || !value?.country) {
      dispatch(setError({ key, value: "Please provide city and country" }));
    }
  };

  const handleRequiredFieldsSwitch = ({ target: { name, checked } }) => {
    dispatch(
      updateDraft({
        key: "requiredFields",
        value: { ...newInvitation?.draft?.requiredFields, [name]: checked },
      }),
    );
  };

  const handleSwitch = ({ target: { name, checked } }) => {
    dispatch(updateDraft({ key: name, value: checked }));
  };

  const handleImage = (name) => (e) => {
    if (!e?.target?.files[0]) {
      return;
    }
    if (e?.target?.files[0].size > 4000000) {
      dispatch(
        setError({ key: name, value: "Image size should be less than 4mb." }),
      );
      return;
    }
    if (newInvitation?.draft?.id) {
      uploadEventPhotos({
        id: newInvitation?.draft?.id,
        images: [e?.target?.files[0]],
      });
      dispatch(setError({ key: name, value: null }));
      e.target.value = "";
      return;
    }
    blobToDataURL(e?.target?.files[0], (dataUrl) => {
      dispatch(
        updateDraft({
          key: name,
          value: !newInvitation?.draft[name].includes(dataUrl)
            ? [...newInvitation?.draft[name], dataUrl]
            : newInvitation?.draft[name],
        }),
      );
    });
    dispatch(setError({ key: name, value: null }));
    e.target.value = "";
  };

  const handleDeleteImage = (name) => (item) => {
    if (item.startsWith("data:")) {
      dispatch(
        updateDraft({
          key: name,
          value: newInvitation?.draft[name].filter((img) => img !== item),
        }),
      );
    } else {
      deleteImage({
        id: newInvitation?.draft?.id,
        body: { filename: item },
      }).then(
        dispatch(
          updateDraft({
            key: name,
            value: newInvitation?.draft[name].filter((img) => img !== item),
          }),
        ),
      );
    }
  };

  const handleVideo = (e) => {
    getVideoDuration(e.target.files[0]).then((duration) => {
      // if (duration > 30) {
      //   dispatch(setError({key: 'video', value: 'Video duration should be less than 30 seconds.'}));
      //   return;
      // }
      if (!e.target.files[0]) {
        return;
      }
      if (e?.target?.files[0].size > 60000000) {
        dispatch(
          setError({
            key: "video",
            value: "Video size should be less than 60mb.",
          }),
        );
        return;
      }
      if (newInvitation?.draft?.id) {
        uploadVideo({
          id: newInvitation?.draft?.id,
          video: e?.target?.files[0],
        });
        dispatch(setError({ key: "video", value: null }));
        e.target.value = "";
        return;
      }
      blobToDataURL(e?.target?.files[0], (dataUrl) => {
        dispatch(
          updateDraft({
            key: "video",
            value: dataUrl,
          }),
        );
      });
      dispatch(setError({ key: "video", value: null }));
      e.target.value = "";
    });
  };

  const handleVideoDelete = () => {
    if (newInvitation?.draft?.video.startsWith("data:")) {
      dispatch(updateDraft({ key: "video", value: "" }));
    } else {
      deleteVideo({
        id: newInvitation?.draft?.id,
        body: { filename: newInvitation?.draft?.video },
      }).then(dispatch(updateDraft({ key: "video", value: "" })));
    }
  };

  return (
    <>
      <FormWrapper>
        <Typography sx={{ fontSize: 20, fontWeight: 600, mb: 2 }}>
          Create invitation for guests
        </Typography>
        <Label sx={{ mb: 0.5 }}>Event name</Label>
        <Input
          name="name"
          placeholder=""
          multiline
          maxRows={5}
          value={newInvitation?.draft?.name}
          onChange={handleChange}
          sx={{ mb: 3 }}
        />
        <Label>Location</Label>
        <Typography sx={{ fontSize: 12, fontWeight: 200, mb: 0.5 }}>
          Enter a general area, like 'Psychiko'
        </Typography>
        <GoogleAutocomplete
          value={newInvitation?.draft?.locationFrom || null}
          handleChange={handleLocation("locationFrom")}
          error={newInvitation?.errors?.locationFrom}
        />
        <Label>Precise address</Label>
        <Typography sx={{ fontSize: 12, fontWeight: 200, mb: 0.5 }}>
          This will be shared only when you accept a guest, ensuring privacy.
        </Typography>
        <Input
          onChange={handleChange}
          value={newInvitation?.draft?.preciseLocation || ""}
          sx={{ mb: 4 }}
          name={"preciseLocation"}
        />
        <Label sx={{ mb: 0.5 }}>Date</Label>
        <DatePicker
          name={"startDate"}
          value={newInvitation?.draft?.startDate || null}
          onChange={(e) => handleDateChange(e)("startDate")}
          minDate={dayjs().add(1, "day")}
          maxDate={
            newInvitation?.draft?.endDate
              ? dayjs(newInvitation?.draft?.endDate).add(-1, "day")
              : dayjs().add(1, "year")
          }
          sx={{ mb: 4 }}
        />
        <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <Label sx={{ mb: 0.5 }}>Start time</Label>
            <CustomTimePicker
              value={
                newInvitation?.draft?.startTime
                  ? dayjs(newInvitation?.draft?.startTime)
                  : null
              }
              onChange={(e) => handleDateChange(e)("startTime")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label sx={{ mb: 0.5 }}>
              End time •<em>Optional</em>
            </Label>
            <CustomTimePicker
              value={
                newInvitation?.draft?.endTime
                  ? dayjs(newInvitation?.draft?.endTime)
                  : null
              }
              onChange={(e) => handleDateChange(e)("endTime")}
            />
          </Grid>
        </Grid>
        <Label sx={{ mb: 0.5 }}>Event information</Label>
        <Input
          name="additionalInfo"
          multiline
          maxRows={5}
          value={newInvitation?.draft?.additionalInfo}
          onChange={handleChange}
          sx={{ mb: 4 }}
          placeholder={
            "Enter details about your event here (e.g., theme, dress code, type of music)"
          }
        />
        <Label sx={{ mb: 0.5 }}>Total Guest Number</Label>
        <Input
          name="numberOfGuest"
          placeholder="Enter the expected number of guests"
          type="number"
          value={newInvitation?.draft?.numberOfGuest || ""}
          onChange={handleChange}
          sx={{ mb: 4 }}
          inputProps={{ inputMode: "numeric", min: 1 }}
          helperText={newInvitation?.errors?.numberOfGuest}
          error={!!newInvitation?.errors?.numberOfGuest}
          fullWidth
        />
        <Label>Plus One Policy</Label>
        <Typography sx={{ fontSize: 12, fontWeight: 200, mb: 0.5 }}>
          Are guests allowed to bring a plus one?
        </Typography>
        <FormControlLabel
          sx={{ mb: 3 }}
          control={
            <CustomSwitch
              name={"hasPlusOne"}
              checked={newInvitation?.draft?.hasPlusOne}
              onChange={handleSwitch}
            />
          }
          label={
            <Typography
              sx={{ color: "#373632", fontSize: 14, fontWeight: 400 }}
            >
              {newInvitation?.draft?.hasPlusOne ? "Yes" : "No"}
            </Typography>
          }
        />
        <Label sx={{ mb: 2 }}>
          Upload up to 5 photos to showcase your event
        </Label>
        <ImageRow
          images={newInvitation?.draft?.eventPhotos}
          error={newInvitation?.errors?.eventPhotos}
          onChange={handleImage("eventPhotos")}
          onDelete={handleDeleteImage("eventPhotos")}
          fiveImages
        />
        <Label sx={{ mb: 2 }}>
          Upload a video to showcase your event •<em>Optional</em>
        </Label>
        <Video
          video={newInvitation?.draft?.video}
          onChange={handleVideo}
          onDelete={handleVideoDelete}
          sx={{ mb: 3 }}
        />
        <Label sx={{ mb: 0.5 }}>Deadline for application</Label>
        <DatePicker
          value={newInvitation?.draft?.deadlineOfApplications || null}
          maxDate={dayjs(newInvitation?.draft?.startDate).add(-1, "day")}
          minDate={dayjs().add(1, "day")}
          onChange={(e) => handleDateChange(e)("deadlineOfApplications")}
          sx={{ mb: 3 }}
        />
        <Label>Guest Introduction Video</Label>
        <Typography sx={{ fontSize: 12, fontWeight: 200, mb: 0.5 }}>
          Choose whether guests need to upload a short video introducing
          themselves
        </Typography>
        <FormControlLabel
          sx={{ mb: 3 }}
          control={
            <CustomSwitch
              name={"video"}
              checked={newInvitation?.draft?.requiredFields?.video}
              onChange={handleRequiredFieldsSwitch}
            />
          }
          label={
            <Typography
              sx={{ color: "#373632", fontSize: 14, fontWeight: 400 }}
            >
              {newInvitation?.draft?.requiredFields?.video ? "Yes" : "No"}
            </Typography>
          }
        />
        <PreviewButton onClick={() => setOpen(true)} />
      </FormWrapper>
      <FormButtons showPrev showCreate isCreateDisabled={isDisabled} />
      <PreviewInvitationModal
        open={open}
        onClose={() => setOpen(false)}
        invitation={{ ...newInvitation?.draft }}
      />
      {(isUploadImageLoading ||
        isDeleteImageLoading ||
        isDeleteVideoLoading ||
        isUploadVideoLoading) && <Loader />}
    </>
  );
};

export default EventGuestInvitationForm;
