import { styled } from '@mui/material/styles';
import { Tab } from '@mui/material';

const TabItem = styled(Tab)(() => ({
  color: '#696969 !important',
  padding: '8px 24px',
  backgroundColor: 'transparent',
  alignItems: 'center',
  flexDirection: 'row',
  minHeight: 'auto',
  textTransform: 'inherit',
  '&.Mui-selected': {
    color: '#373632 !important',
    fontWeight: 'bold',
    backgroundColor: '#F9F5EF',
    '& .MuiSvgIcon-root': {
      color: '#0E1D31',
    },
  },
  '& .MuiSvgIcon-root': {
    margin: '0 8px 0 0',
  }
}));

export default TabItem;
