import Label from './UI/Label';
import uploadIconFile from '../assets/create_inv/upload_picture.svg';

const VideoUploader = ({onChange}) => {
  return (
    <Label sx={{...boxSx}}>
      <input type="file" accept="video/mp4, video/webm, video/quicktime" onChange={onChange}/>
      <img src={uploadIconFile} alt={`upload-icon`}/>
    </Label>
  )
};

const boxSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  backgroundColor: '#AFAFAF',
  width: '100%',
  height: 245,
  '& > input': {
    display: 'none'
  }
};

export default VideoUploader;
