import { MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material/styles';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({theme}) => ({
  '&.notistack-MuiContent': {
    minWidth: 400,
    [theme.breakpoints.down('md')]: {
      minWidth: 300
    },
    '& #notistack-snackbar': {
      width: 'calc(100% - 60px)',
    },
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: '#007771',
    color: '#fff',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#007771',
    color: '#fff',
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#007771',
    color: '#fff',
  },
  '& button': {
    '& svg': {
      color: '#fff',
    }
  }
}));

export default StyledMaterialDesignContent;
