import React from 'react';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { GOOGLE_MAPS_API_KEY } from '../util/utils';
import { Typography, Grid, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import Input from './UI/Input';

const GoogleAutocomplete = ({value, handleChange, error, ...props}) => {
  const {
    placePredictions,
    getPlacePredictions,
    placesService,
  } = useGoogle({
    apiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places', 'geometry'],
    language: 'en',
    options: {
      types: ['(cities)']
    }
  });
  const onChange = (option) => {
    const value = {
      coordinates: {
        lat: null,
        long: null,
      },
      city: '',
      country: '',
      place_id: option.place_id,
      description: option.description
    };
    placesService.getDetails({placeId: placePredictions[0].place_id}, (placeDetails) => {
      value.coordinates.lat = placeDetails.geometry.location.lat();
      value.coordinates.long = placeDetails.geometry.location.lng();
      value.city = placeDetails.address_components.filter(item => item.types[0].includes('locality'))[0]?.long_name;
      value.country = placeDetails.address_components.filter(item => item.types[0].includes('country'))[0]?.long_name;
      handleChange(value)
    })
  };

  return (
    <>
      <Autocomplete
        fullWidth
        getOptionLabel={(option) => option.description}
        isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
        filterOptions={(x) => x}
        options={placePredictions}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        error={error}
        onInputChange={(event, newInputValue) => {
          getPlacePredictions({ input: event?.target?.value || newInputValue || null});
        }}
        clearIcon={false}
        renderInput={(params) => {
          return (
            <Input
              {...params}
              fullWidth
            />
          );
        }}
        renderOption={(props, option) => {
          return (
            <Box {...props} onClick={(e) => {
              onChange(option);
              props.onClick(e);
            }} >
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon />
                </Grid>
                <Grid item xs>
                  <Typography sx={{fontWeight: 700}}>
                    {option?.structured_formatting?.main_text}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {option?.structured_formatting?.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        }}
        sx={{mb: error ? 1 : 4,...props.sx}}
        {...props}
      />
      {error && <Typography sx={{fontSize: 14, mb: 4}} color={'red'}>{error}</Typography>}
    </>
  );
};

export default GoogleAutocomplete;
