import { Box, Grid } from '@mui/material';
import MediaSlider from '../../../components/MediaSlider';
import React, { useState } from 'react';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';
import { Button } from '../../../components/UI/Button';

const InvitationMediaList = ({images, video}) => {
  const [open, setOpen] = useState(false);
  // const mediaArray = video ? [video, ...images] : images;

  return (
    <Box sx={{borderRadius: '12px', overflow: 'hidden', position: 'relative'}}>
      <Grid container spacing={1} sx={{height: 400}}>
        <Grid item xs={images?.length > 1 ? 6 : 12} sx={{height: '100%'}}>
          {video ? <VideoItem video={video} onClick={() => setOpen(0)} /> :
            <ImageItem image={images[0]} alt={`invitation-gallery-main`} onClick={() => setOpen(0)} />
          }
        </Grid>
        <Grid item container xs={6} columnSpacing={1} rowSpacing={images?.length > 3 ? 1 : 0} sx={{height: '100%'}}>
          <Grid item xs={images?.length > 2 ? 6 : 12} sx={{height: images?.length > 3 ? 'calc(50% + 4px)' : '100%'}}>
            {video ? <ImageItem image={images[0]} alt={`invitation-gallery-main`} onClick={() => setOpen(1)} /> :
              <ImageItem image={images[1]} alt={`invitation-gallery-main`} onClick={() => setOpen(1)} />}
          </Grid>
          {(images?.length > 2 || video) && (
            <Grid item xs={6} sx={{height: images?.length > 3 ? 'calc(50% + 4px)' : '100%'}}>
              {video ? <ImageItem image={images[1]} alt={`invitation-gallery-main`} onClick={() => setOpen(2)} /> :
                <ImageItem image={images[2]} alt={`invitation-gallery-main`} onClick={() => setOpen(2)} />}
            </Grid>
          )}
          {images?.length > 3 && (
            <Grid item xs={images?.length === 4 ? 12 : 6} sx={{height: 'calc(50% + 4px)'}}>
              {video ? <ImageItem image={images[2]} alt={`invitation-gallery-main`} onClick={() => setOpen(3)} /> :
                <ImageItem image={images[3]} alt={`invitation-gallery-main`} onClick={() => setOpen(3)} />}
            </Grid>
          )}
          {images?.length > 4 && (
            <Grid item xs={6} sx={{height: 'calc(50% + 4px)'}}>
              {video ? <ImageItem image={images[3]} alt={`invitation-gallery-main`} onClick={() => setOpen(4)} /> :
                <ImageItem image={images[4]} alt={`invitation-gallery-main`} onClick={() => setOpen(4)} />}
            </Grid>
          )}
          {images?.length > 1 && (
            <Button
              sx={{
                '&.MuiButtonBase-root.MuiButton-root': {
                  position: 'absolute',
                  right: 20,
                  bottom: 10,
                  background: '#fff',
                  py: 1,
                  px: 2,
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  fontSize: 14,
                  cursor: 'pointer',
                  color: '#000 !important',
                  textTransform: 'inherit',
                  height: 'auto'
                }
              }}
              onClick={() => setOpen(0)}
            >
              <AppsSharpIcon sx={{fontSize: 18, color: '#000'}} />
              Show all
            </Button>
          )}
        </Grid>
      </Grid>
      <MediaSlider open={open === +open} currentSlide={open} onClose={() => setOpen(false)} images={images} video={video} />
    </Box>
  );
};

export default InvitationMediaList;


const ImageItem = ({image, alt, onClick}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        '& > img':{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        '&:hover:after': {
          opacity: 0.15,
          cursor: 'pointer'
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#000',
          opacity: 0,
          transition: 'opacity .3s cubic-bezier(0.1,0.9,0.2,1)'
        }
      }}>
      <img src={image} alt={alt}/>
    </Box>
  )
};


const VideoItem = ({video, onClick}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        '& > img':{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        '&:hover:after': {
          opacity: 0.15,
          cursor: 'pointer'
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#000',
          opacity: 0,
          transition: 'opacity .3s cubic-bezier(0.1,0.9,0.2,1)'
        }
      }}>
      <video>
        <source src={video} type="video/mp4" />
      </video>
    </Box>
  )
};
