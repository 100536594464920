import { logout } from '../features/auth/slice';

export const errorMiddleware = store => next => action => {
  if (action.meta?.condition) {
    return next(action);
  }
  if (action?.error) {
    if (action.payload?.status === 401) {
      store.dispatch(logout());
    }
    if (action.meta.arg.endpointName === 'getUser' && action.payload?.status === 403) {
      store.dispatch(logout());
    }
  }
  return next(action)
}
