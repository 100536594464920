import React, { useEffect } from 'react';
import Footer from './Footer';
import { Box } from '@mui/material';
import AppHeader from './AppHeader';
import { useLocation } from 'react-router-dom';

const StaticPageWrapper = ({children, sx, hideFooter = false, footerStyles = {}}) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box sx={{pt: {xs: location.pathname === '/join' || location.pathname === '/login' ? 6 : 0, sm: 6}, pb: {xs: 0, sm: 4}, overflow: 'hidden', ...sx}}>
      <AppHeader />
      {children}
      {!hideFooter && <Footer isStaticFooter sx={footerStyles}/>}
    </Box>
  )
};

export default StaticPageWrapper;
