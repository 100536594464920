import React, { useMemo } from 'react';
import { Box, Typography, Link, Stack, Grid, Divider, useMediaQuery } from '@mui/material';
import { Link as NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import { theme } from '../../../services/Theme';

const InvitationTimeline = ({invitation}) => {
  const {user} = useSelector(store => store.user);
  const isUserAccepted = useMemo(() => invitation?.acceptedApplications?.find(item => item.user === user.id), [invitation.acceptedApplications, user.id]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container spacing={2}>
        {invitation?.host?.id && (
          <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column'}}>
            {isMobile && <Divider sx={{ mb: 2}} />}
            <Link
              component={NavLink}
              to={`/user/${invitation?.host?.id}`}
              sx={{
                textDecoration: 'none',
                '&:hover .MuiTypography-root ': {
                  textDecoration: 'none',
                  '& + .MuiTypography-root': {
                    textDecoration: 'underline'
                  },
                },
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              {invitation?.host?.imgUrl?.length > 0 && (
                <Box sx={{width: 46, height: 46, '& > img, & > svg': {width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%'}}}>
                  <img src={invitation?.host?.imgUrl[invitation?.host?.imgUrl.length -1]} alt="host" />
                </Box>
              )}
              <Stack direction={'column'} spacing={0.5}>
                <Typography sx={titleSx}>
                  Host
                </Typography>
                <Typography sx={descriptionSx}>
                  @{invitation?.host?.username}
                </Typography>
              </Stack>
            </Link>
            {isMobile && <Divider sx={{mt: 2, mb: 1}} />}
          </Grid>
        )}
        {(invitation?.host?.id === user?.id || isUserAccepted) && invitation?.preciseLocation && (
          <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column'}}>
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <HomeIcon sx={iconSx}/>
              <Typography sx={titleSx}>
                Precise address
              </Typography>
            </Stack>
            <Typography sx={descriptionSx}>
              {invitation?.preciseLocation}
            </Typography>
          </Grid>
        )}
      {invitation?.numberOfGuest && (
        <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column'}}>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <GroupIcon sx={iconSx}/>
            <Typography sx={titleSx}>
              Number of Guests
            </Typography>
          </Stack>
          <Typography sx={descriptionSx}>
            {invitation?.numberOfGuest}
          </Typography>
        </Grid>
      )}
      </Grid>
    </>
  );
};

export default InvitationTimeline;

const iconSx = {
  color: '#000',
  fontSize: 18
};

const titleSx = {
  color: '#000',
  fontSize: 14,
  fontWeight: 600,
};

const descriptionSx = {
  textTransform: 'capitalize',
  color: '#373632',
  fontSize: 14,
  fontWeight: 400,
  mt: 0.5
};
