import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

const CustomDateField = ({sx, ...props}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateField
        disableFuture
        hiddenLabel
        fullWidth
        format={'DD/MM/YYYY'}
        sx={{
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'red',
              borderWidth: '1px !important'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(14, 29, 49, 0.5)",
            borderWidth: '1px !important'
          },
         '&:hover, &.Mui-focused': {
            boxShadow: 'none',
            '&.Mui-error,': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
                borderWidth: '1px !important'
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#0E1D31',
              borderWidth: '1px !important',
            }
          },
          ...sx
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default CustomDateField;
