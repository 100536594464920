import { Box, Stack } from '@mui/material';
import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const SidebarItem = ({selected = false, image = null, children, ...props}) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={2}
      sx={{
        width: '100%',
        p: 2,
        background: selected ? '#F9F5EF' : '#fff',
        transition: 'background 200ms linear 200ms',
        '&:hover': {
          backgroundColor: '#F9F5EF',
          cursor: 'pointer',
        },
        '&:not(:last-child)': {
          borderBottom: '1px solid #E1E1E1',
        }
      }}
      {...props}
    >
      <Box
        sx={{
          flexShrink: 0,
          borderRadius: '50%',
          width: 87,
          height: 87,
          overflow: 'hidden',
          '& img, & svg': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }
        }}
      >
        {image ? <img src={image} alt={`application-item`} /> : <AccountCircleIcon viewBox={'2 2 20 20'} preserveAspectRatio='none' />}
      </Box>
      {children}
    </Stack>
  );
};

export default SidebarItem;
