import { mainApi, TAGS } from '../../services/mainApi';

export const userManagementApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    user: build.query({
      query: (id) => ({url: `users/${id}`}),
    }),
    users: build.query({
      query: (params) => ({url: `users`, params}),
      providesTags: [TAGS.USERS]
    }),
    addUser: build.mutation({
      query: ({body}) => {
        return {
          url: 'users',
          method: 'POST',
          body
        }
      },
      invalidatesTags: [TAGS.USERS],
    }),
    deleteUser: build.mutation({
      query: ({id}) => {
        return {
          url: `users/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [TAGS.USERS],
    }),
    suspendUser: build.mutation({
      query: (id) => {
        return {
          url: `users/${id}/suspend`,
          method: 'PATCH',
        }
      },
      invalidatesTags: [TAGS.USERS],
    }),
    reinstateUser: build.mutation({
      query: (id) => {
        return {
          url: `users/${id}/reinstate`,
          method: 'PATCH',
        }
      },
      invalidatesTags: [TAGS.USERS],
    }),
    waitingList: build.query({
      query: (params) => ({url: `waiting-list`, params}),
      providesTags: [TAGS.WAITLISTED_USERS]
    }),
    changeStatus: build.mutation({
      query: (body) => {
        return {
          url: 'waiting-list/change-status',
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: [TAGS.WAITLISTED_USERS],
    }),
    deleteWaitingListApplication: build.mutation({
      query: (body) => {
        return {
          url: 'waiting-list/delete',
          method: 'DELETE',
          body
        }
      },
      invalidatesTags: [TAGS.WAITLISTED_USERS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useUserQuery,
  useUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useSuspendUserMutation,
  useReinstateUserMutation,
  useWaitingListQuery,
  useChangeStatusMutation,
  useDeleteWaitingListApplicationMutation,
} = userManagementApi;
