import React, { useState } from 'react';
import PageWrapper from '../../components/PageWrapper';
import { Stack, Typography, Grid, Box, Dialog, DialogContent, MenuItem, Link } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Button } from '../../components/UI/Button';
import { useUsersUsedReferralsQuery } from '../../services/mainApi';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import ModalTitle from '../../components/UI/DialogTitle';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useHistory } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import Dropdown from '../../components/UI/Dropdown';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const ProfileReferrals = () => {
  const history = useHistory();
  const {data, isLoading} = useUsersUsedReferralsQuery();
  const {user} = useSelector(store => store.user);
  const [open, setOpen] = useState(history?.location?.state?.isOpenRefModal || false);

  return (
    <PageWrapper sx={{overflowY: {xs: 'visible', md: 'hidden'}, height: 'calc(100vh - 45px)'}}>
      <Typography sx={{fontWeight: 500, mb: 3}}>Referrals</Typography>
      <Stack direction={'row'} alignItems={'center'} spacing={0.5} sx={{mb: 3}}>
        <InfoIcon sx={{color: '#0E1D31', fontSize: 16}} />
        <Typography sx={{fontSize: 16}}>You have {user?.availableReferralCodes?.length} referral codes</Typography>
      </Stack>
      <Button
        sx={{maxWidth: 190, height: 40, mb: 5}}
        variant={'contained'}
        color={'primary'}
        onClick={() => setOpen(true)}
      >
        Refer a friend
      </Button>
      {(data?.hosts?.length > 0 || data?.guests?.length > 0) && !isLoading && (
        <Grid
          container
          rowSpacing={{xs: 3, md: 0}}
          columnSpacing={{xs: 0, md: 6, lg: 10}}
          sx={{height: {xs: 'auto', md: '100%'}, overflow: {xs: 'visible', md: 'hidden'}}}
        >
          <Grid item xs={12} md={6} sx={{height: {xs: 'auto', md: '100%'}, display: 'flex', flexDirection: 'column'}}>
            <Typography sx={{fontSize: 14, fontWeight: 600, textAlign: {xs: 'center', md: 'left'}}}>
              HOSTS THAT HAVE BEEN REFERRED BY YOU AND JOINED
            </Typography>
            <Stack direction={'column'} spacing={3} sx={{flexGrow: {xs: 0, md: 1}, overflowY: {xs: 'visible', md: 'auto'}, mt: 3, pr: {xs: 0, md: 4}}}>
              {data?.hosts?.map(host => (
                <UserCard user={host} key={host.id} />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} sx={{height: {xs: 'auto', md: '100%'}, display: 'flex', flexDirection: 'column'}}>
            <Typography sx={{fontSize: 14, fontWeight: 600, textAlign: {xs: 'center', md: 'left'}}}>
              GUESTS THAT HAVE BEEN REFERRED BY YOU AND JOINED
            </Typography>
            <Stack direction={'column'} spacing={3} sx={{flexGrow: {xs: 0, md: 1}, overflowY: {xs: 'visible', md: 'auto'}, mt: 3, pr: {xs: 0, md: 4}}}>
              {data?.guests?.map(guest => (
                <UserCard user={guest} key={guest.id} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      )}
      <ReferralCodesModal open={open} onClose={() => setOpen(false)} />
    </PageWrapper>
  );
};

export default ProfileReferrals;

const UserCard = ({user}) => {
  return (
    <Stack direction={'row'} spacing={1.5} alignItems={'center'} sx={{bgcolor: '#F9F5EF', p: 2,}}>
      <Box
        sx={{
          width: 60,
          height: 60,
          borderRadius: '50%',
          overflow: 'hidden',
          '& > img, & > svg': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }
        }}
      >
        {user?.imgUrl?.length > 0 ? <img src={user?.imgUrl[user?.imgUrl.length - 1]} alt="user"/> : <AccountCircleIcon />}
      </Box>
      <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography sx={{fontSize: 14, fontWeight: 600}}>{user?.username}</Typography>
        <Typography sx={{fontSize: 15, fontWeight: 'medium'}}>{user?.credits}$</Typography>
      </Box>
    </Stack>
  );
};

const ReferralCodesModal = ({open, onClose}) => {
  const {user} = useSelector(store => store.user);

  const getMessage = (code) => (
    `Hey, I'm using Invite.Club for travel and connection with like-minded people. Use my referral code ${code} when you sign up. Apply for a membership at www.invite.club.`
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 560,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        <Typography sx={{fontSize: 15, fontWeight: 600}}>REFERRAL CODES</Typography>
      </ModalTitle>
      <DialogContent sx={{p: 3, mt: 3}}>
        <Typography sx={{fontSize: 16, mb: 3}} align={'center'}>Share your unique referral codes.</Typography>
        <Stack direction={'column'} spacing={3}>
          {user?.availableReferralCodes?.map(code => (
            <Stack
              key={code}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{px: 2, py: 3, border: '1px solid #E1E1E1'}}
            >
              <Typography sx={{fontSize: 15, fontWeight: 600}}>{code}</Typography>

                <Dropdown
                  sx={{display: 'flex', alignItems: 'center', minWidth: 150}}
                  dropdownItems={
                    <Box sx={{'& svg': {fontSize: 20, mr: 1}}}>
                      <CopyToClipboard text={code}>
                        <MenuItem>
                          <ContentCopyIcon />
                          Copy code
                        </MenuItem>
                      </CopyToClipboard>
                      <MenuItem href={`https://wa.me/?text=${getMessage(code)}`} target={'_blank'} component={Link}>
                        <WhatsAppIcon />
                        Whats App
                      </MenuItem>
                    </Box>
                  }
                >
                  <Button
                    startIcon={<ShareIcon />}
                    sx={{height: 32, maxWidth: 131, width: '100%', '&.MuiButtonBase-root': {fontSize: 13, p: 0.5}}}
                    variant={'contained'}
                  >
                    Share
                  </Button>
                </Dropdown>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
