import React from "react";
import { Stack, useMediaQuery } from "@mui/material";
import PageWrapper from "../../../components/PageWrapper";
import AthensInvitations from "./AthensInvitations";
import RestInvitations from "./RestInvitations";
import InvitationsHeader from "./InvitationsHeader";

const InvitationsGallery = () => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <PageWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          paddingLeft: mdUp ? "61px" : "16px",
          paddingTop: mdUp ? "30px" : "16px",
        }}
      >
        <Stack sx={{ paddingRight: mdUp ? "61px" : "16px" }}>
          <InvitationsHeader />
        </Stack>
        <Stack>
          <AthensInvitations />
        </Stack>
        <Stack>
          <RestInvitations />
        </Stack>
      </div>
    </PageWrapper>
  );
};

export default InvitationsGallery;
