import { Slide, useScrollTrigger } from '@mui/material';

const HideOnScroll = ({ children, window, slideDirection = 'down' }) => {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction={slideDirection} in={!trigger}>
      {children}
    </Slide>
  );
}

export default HideOnScroll;
