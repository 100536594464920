import { mainApi, TAGS } from '../../services/mainApi';

export const applicationApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    applications: build.query({
      query: ({params}) => ({url: `applications`, params}),
      providesTags: [TAGS.APPLICATIONS]
    }),
    application: build.query({
      query: (id) => ({url: `applications/${id}`}),
      providesTags: [TAGS.APPLICATION]
    }),
    invitationApplications: build.query({
      query: ({id, params}) => ({url: `applications/invitation/${id}`, params}),
      providesTags: [TAGS.APPLICATIONS]
    }),
    createApplication: build.mutation({
      query: ({body}) => {
        return {
          url: 'applications',
          method: 'POST',
          body
        }
      },
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.INVITATION, TAGS.APPLICATIONS, TAGS.APPLICATION, TAGS.APPLIED_USERS],
    }),
    updateApplication: build.mutation({
      query: ({id, body}) => {
        return {
          url: `applications/${id}`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: [TAGS.HOST_INVITATIONS, TAGS.APPLICATIONS, TAGS.APPLICATION, TAGS.APPLIED_USERS],
    }),
    uploadApplicationImage: build.mutation({
      query: ({id, images}) => {
        const formData = new FormData();
        images.forEach((item) => formData.append('gallery', item))
        return {
          url: `applications/gallery/${id}`,
          method: 'PATCH',
          body: formData
        };
      },
      invalidatesTags: [TAGS.APPLICATIONS, TAGS.APPLICATION],
    }),
    uploadApplicationVideo: build.mutation({
      query: ({id, video}) => {
        const formData = new FormData();
        formData.append('video', video);
        return {
          url: `applications/video/${id}`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: [TAGS.APPLICATIONS, TAGS.APPLICATION],
    }),
    deleteApplicationMedia: build.mutation({
      query: ({id, body}) => ({
        url: `applications/delete-image/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [TAGS.APPLICATIONS, TAGS.APPLICATION],
    }),
    updateApplicationStatus: build.mutation({
      query: ({id, body}) => ({
        url: `applications/update-status/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [TAGS.APPLICATIONS, TAGS.APPLICATION, TAGS.APPLIED_USERS],
    }),
    checkIsUserApplied: build.query({
      query: ({invitationId, userId}) => ({
        url: `applications/${invitationId}/check/${userId}`,
      }),
      providesTags: [TAGS.APPLIED_USERS]
    })
  }),
  overrideExisting: false,
});

export const {
  useApplicationsQuery,
  useApplicationQuery,
  useInvitationApplicationsQuery,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
  useUploadApplicationImageMutation,
  useUploadApplicationVideoMutation,
  useDeleteApplicationMediaMutation,
  useUpdateApplicationStatusMutation,
  useCheckIsUserAppliedQuery,
} = applicationApi;
