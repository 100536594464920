import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TravelPackageBadge from './UI/TravelPackageBadge';
import { Button } from './UI/Button';

const TravelPackage = ({title, description, color, checkboxItems, hintItems, selectable = false, selected = null}) => {
  return (
    <Box sx={{
      backgroundColor: '#F9F5EF',
      borderRadius: "5px",
      padding: "80px 25px 40px 25px",
      position: 'relative'
    }}>
      <TravelPackageBadge title={title} color={color} />
      {description && (
        <Typography
          sx={{
            color: "#373632",
            fontSize: 15,
            lineHeight: "19px",
            letterSpacing: "0.2px",
          }}
        >
          {description}
        </Typography>
      )}
      {checkboxItems?.length > 0 && (
        <Stack direction={'column'} sx={{mb: 4}}>
          { checkboxItems?.map((checkboxItem, i) => (
            <Stack direction={'row'} spacing={3} key={`${checkboxItem}-${i}`}>
              <CheckCircleOutlineIcon sx={{mr: 2, color: '#373632'}} />
              <Typography
                sx={{
                  color: '#373632',
                  fontSize: 16,
                  lineHeight: "24px",
                  letterSpacing: "0.24px",
                  textTransform: "initial"
                }}>
                {checkboxItem}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
      {hintItems?.length > 0 && (
        <Stack direction={'column'} spacing={2} sx={{mb: 4}}>
          {hintItems?.map((hintItem, i) => (
            <Typography
              sx={{
                color: "#373632",
                fontSize: 13,
                lineHeight: "19px",
                letterSpacing: "0.2px",
                textTransform: "initial",
              }}
              key={`${hintItem}-${i}`}
            >
              *{hintItem}
            </Typography>
          ))}
        </Stack>
      )}
      {selectable && (
        <Button
          sx={{ml: 'auto', maxWidth: 240, display: 'block'}}
          component={Box}
          variant={selected ? 'contained' : 'outlined'}
          color={'primary'}
        >
          {selected ? 'SELECTED' : 'SELECT'}
        </Button>
      )}
    </Box>
  )
};

export default TravelPackage;
