import { Typography } from "@mui/material";
import React from "react";

const NotificationDescription = ({
  title,
  invitationName,
  userName,
  description,
  sx,
}) => {
  switch (title) {
    case "New Application":
      return (
        <Typography sx={{ ...sx }} component={"span"}>
          <Typography sx={{ fontWeight: 600, display: "inline", ...sx }}>
            {userName}{" "}
          </Typography>
          has applied to attend your event
          <Typography sx={{ fontWeight: 600, display: "inline", ...sx }}>
            {" "}
            {invitationName}.{" "}
          </Typography>
          Check their application and respond.
        </Typography>
      );
    case "Application status changed":
      return (
        <Typography sx={{ ...sx }} component={"span"}>
          Good news! You've been approved to attend
          <Typography sx={{ fontWeight: 600, display: "inline", ...sx }}>
            {" "}
            {invitationName}.{" "}
          </Typography>
          Check the event details now!
        </Typography>
      );
    case "New Event Chat is Now Active!":
      return (
        <Typography sx={{ ...sx }} component={"span"}>
          You have been invited to the group chat for
          <Typography sx={{ fontWeight: 600, display: "inline", ...sx }}>
            {" "}
            {invitationName}.{" "}
          </Typography>
          Start chatting now!
        </Typography>
      );
    default:
      return (
        <Typography sx={{ ...sx }} component={"span"}>
          {description}
        </Typography>
      );
  }
};

export default NotificationDescription;
