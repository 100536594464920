import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./services/Theme";
import store, { persistor } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import StyledMaterialDesignContent from "./components/NotiStackComponent";
import { ThemeProvider as ThemeProviderV5 } from "@mui/material/styles";
import { StylesProvider } from "@material-ui/core/styles";
import { createGenerateClassName } from "@material-ui/core/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PersistGate } from "redux-persist/integration/react";

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: "mui-jss",
});

ReactDOM.render(
  <GoogleOAuthProvider
    clientId={
      "74108380794-e62j09qn79675jugeiirvpoihg9j9lnc.apps.googleusercontent.com"
    }
  >
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProviderV5 theme={theme}>
              <SnackbarProvider
                Components={{
                  success: StyledMaterialDesignContent,
                  error: StyledMaterialDesignContent,
                  info: StyledMaterialDesignContent,
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </SnackbarProvider>
            </ThemeProviderV5>
          </StylesProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root"),
);

reportWebVitals();
