import { useParams } from 'react-router-dom';
import { useInvitationQuery } from '../api';
import EditTripInvitation from './EditTripInvitation';
import EditEventInvitation from './EditEventInvitation';

const EditInvitation = () => {
  const {id} = useParams();
  const {data: invitation, error, isLoading} = useInvitationQuery(id);

  return (
    <>
      {invitation?.experienceType === 'trip' && <EditTripInvitation invitation={invitation} isLoading={isLoading} error={error} />}
      {invitation?.experienceType === 'event' && <EditEventInvitation invitation={invitation} isLoading={isLoading} error={error} />}
    </>
  );
};

export default EditInvitation;
