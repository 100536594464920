import PageWrapper from '../../components/PageWrapper';
import ChatBox from './ChatBox';
import { useConversationQuery } from './api';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import useFullPageHeight from '../../util/useFullPageHeight';
import Loader from '../../components/Loader';

const Conversation = () => {
  const {id} = useParams();
  const height = useFullPageHeight();
  const {data, isLoading} = useConversationQuery(id, {skip: !id});

  return (
    <PageWrapper sx={{height: {xs: `calc(${height} - 75px)`, sm: height}, minHeight: 'auto'}}>
      {!isLoading && data && (
        <Box sx={{height: '100%',}}>
          <ChatBox showPartyName />
        </Box>
      )}
      {!isLoading && !data && (
        <Box sx={{height: '100%',}}>
          <Typography>Conversation was not found</Typography>
        </Box>
      )}
      {isLoading && <Loader />}
    </PageWrapper>
  );
};

export default Conversation;
