import React, { Component } from "react";
import AccordionComp from "../../components/StaticPages/AccordionCompTerms";
import icon1 from "./../../assets/imgs/dummy/faq/icon-1.JPG";
import icon2 from "./../../assets/imgs/dummy/faq/icon-2.JPG";
import icon3 from "./../../assets/imgs/dummy/faq/icon-3.JPG";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import StaticPageWrapper from '../../components/StaticPageWrapper';

const styles = {
  popularQuestionsTitle: {
    color: "#373632",
    fontSize: "20px",
    lineHeight: "14px",
    fontWeight: "bold",
    display: "block",
    marginTop: "60px",
    marginBottom: "25px",
  },
  faqBoxes: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginTop: "50px",
    "@media (max-width:550px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  faqBox: {
    width: "31%",
    backgroundColor: "#F9F5EF",
    borderRadius: "2px",
    "& a": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      padding: "60px 55px",
    },
    "& a:hover span": {
      color: "#0E1D31",
    },
    "& span": {
      color: "#373632",
      fontSize: "15px",
      fontWeight: "600",
      display: "block",
      marginTop: "15px",
      transition: "color 0.3s ease-in-out",
    },
    "@media (max-width:550px)": {
      width: "80%",
      margin: "auto",
      backgroundColor: "white",
      "& a": {
        marginTop: "10px",
        backgroundColor: "#F9F5EF",
      },
    },
  },
};
class FAQ extends Component {
  constructor() {
    super();
    this.state = {
      showButtons: true,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <StaticPageWrapper>
        <div className="static-wrapper">
          <span className="static-title">Frequently Asked Questions</span>
          <span className="static-subtitle"></span>
          <div className={classes.faqBoxes}>
            <div className={classes.faqBox}>
              <a href="#1">
                <img src={icon1} alt={'card'} />
                <span>Payment and Billing</span>
              </a>
            </div>
            <div className={classes.faqBox}>
              <a href="#2">
                <img src={icon2} alt={'manage-accounts'} />
                <span>Manage Accounts</span>
              </a>
            </div>
            <div className={classes.faqBox}>
              <a href="#3">
                <img src={icon3} alt={'cogwheel'} />
                <span>Technical Issues</span>
              </a>
            </div>
          </div>
          <div className={classes.popularQuestions}>
            <span className={classes.popularQuestionsTitle}>
              Popular Questions
            </span>
            <AccordionComp />
          </div>
        </div>
      </StaticPageWrapper>
    );
  }
}

FAQ.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(withStyles(styles)(FAQ));
